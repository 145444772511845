import styled from 'styled-components';
import {
  DEFAULT_FROZEN_FOOTER_HEIGHT,
  DEFAULT_FROZEN_HEADER_HEIGHT,
} from './constants';

export const zIndex = {
  activeCell: 1,
  frozenHeaderWrapper: 2,
  columnResizeHandle: 3,
  frozenLeftWrapper: 5,
  frozenFooterWrapper: 7,
  topLeftCellWrapper: 7,
  bottomLeftCellWrapper: 7,
  leftColumnResizeHandle: 8,
};

export const DefaultComparisonGridStyles = styled.div<{
  noBodyPadding?: boolean;
  frozenHeaderHeight?: number;
  frozenFooterHeight?: number;
  cellPadding?: string;
  headerCellPadding?: string;
  subHeaderCellPadding?: string;
}>`
  --frozen-header-height: ${props => props.frozenHeaderHeight || DEFAULT_FROZEN_HEADER_HEIGHT}px;
  --frozen-footer-height: ${props => props.frozenFooterHeight || DEFAULT_FROZEN_FOOTER_HEIGHT}px;

  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 1.25;
  color: ${props => props.theme.colors.text};

  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: 4px;
  background-color: white;

  [role=treegrid] {
    width: 100%;
    height: 100%;
  }

  .frozen-left-column-wrapper {
    margin-top: -var(--frozen-header-height);
    position: sticky;
    left: 0;
    height: calc(100% - var(--frozen-header-height));
    overflow: hidden;
    background-color: white;
    z-index: ${zIndex.frozenLeftWrapper};
    border-right: 1px solid ${props => props.theme.colors.lightGray};
  }

  .horizontal-scroll .frozen-left-column-wrapper {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }

  .horizontal-scroll .bottom-left-cell {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -8px -8px -8px);
  }

  .frozen-header-wrapper {
    position: sticky;
    top: 0;
    z-index: ${zIndex.frozenHeaderWrapper};
    height: var(--frozen-header-height);
    background-color: ${props => props.theme.colors.lightGray3};
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    overflow: hidden;
  }

  .frozen-header-first-line {
    position: relative;
    height: var(--frozen-header-height);
  }

  .frozen-footer-wrapper {
    position: sticky;
    bottom: 0;
    z-index: ${zIndex.frozenFooterWrapper};
    height: 0;
    background-color: ${props => props.theme.colors.lightGray3};
  }

  .vertical-scroll .frozen-header-wrapper {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    clip-path: inset(-8px 0px -8px -8px);
  }

  .top-left-cell,
  .bottom-left-cell,
  .header-cell,
  .footer-cell,
  .right-footer-spacer,
  [role=gridcell] {
    background-color: white;
    padding: ${props => props.cellPadding || '8px'};
    border-right: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    cursor: default;
    overflow: hidden;
    user-select: none;
  }

  .header-cell {
    padding: ${props => props.headerCellPadding || '8px'};;
  }

  [role=gridcell]:hover {
    background-color: rgb(245,250,253);
  }

  .top-left-cell-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    z-index: ${zIndex.topLeftCellWrapper};
    height: 0;
    width: 0;
  }

  .top-left-cell {
    position: relative;
    top: 0;
    height: var(--frozen-header-height);
    background-color: ${props => props.theme.colors.lightGray3};
  }

  .bottom-left-cell-wrapper {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.bottomLeftCellWrapper};
    height: 0;
    width: 0;
  }

  .bottom-left-cell {
    position: relative;
    top: calc(-1 * (var(--frozen-footer-height) + 1px));
    height: calc(var(--frozen-footer-height) + 1px);
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: 0;
    background-color: ${props => props.theme.colors.lightGray3};
  }

  .header-cell {
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.lightGray3};
    font-size: 14px;
  }

  .footer-cell {
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: 0;
    background-color: ${props => props.theme.colors.lightGray3};
    font-size: 14px;
  }

  .right-footer-spacer {
    background-color: ${props => props.theme.colors.lightGray3};
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: 0;
    border-right: 0;
  }

  .subheader {
    background-color: ${props => props.theme.colors.lightGray3};
  }

  .no-padding {
    padding: 0;
  }

  .subheader.column-first {
    color: ${props => props.theme.colors.text}80;
    font-size: 9px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.0833em;
    padding: ${props => props.subHeaderCellPadding || '10px 8px'};

    &:hover {
      background-color: ${props => props.theme.colors.lightGray3};
    }
  }

  .column-first {
    ${props => props.noBodyPadding ? '' : `border-left: 1px solid ${props.theme.colors.lightGray};`}
    font-weight: 500;

    &:hover {
      background-color: inherit;
    }
  }

  .rowgroup-first {
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    background-color: ${props => props.theme.colors.lightGray3};
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .rowgroup-first.stats {
    background-color: white;
    padding: 0;
  }

  .rowgroup-first.column-first {
    border-top-left-radius: 4px;

    &:hover {
      background-color: ${props => props.theme.colors.lightGray3};
    }
  }

  .rowgroup-last.column-first {
    border-bottom-left-radius: 4px;
  }

  .rowgroup-first.column-last {
    border-top-right-radius: 4px;
  }

  .rowgroup-last.column-last {
    border-bottom-right-radius: 4px;
  }

  [role=treegrid]:focus {
    outline: none;
  }

  .active {
    z-index: ${zIndex.activeCell};
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.lightPrimary};
  }

  [role=treegrid]:focus .active {
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.primary},
                0 0 8px 0 rgba(0, 0, 0, 0.15);
  }

  .focus-descendant-primary {
    color: ${props => props.theme.colors.lightPrimary};
  }

  [role=treegrid]:focus .focus-descendant-primary {
    color: ${props => props.theme.colors.primary};
  }
`;
