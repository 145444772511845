import * as React from 'react';
import { ThemeProvider } from '@deepstream/ui-kit/theme/ThemeProvider';
import { BorderVariant, ButtonVariant, Color, FontVariant, RadiusVariant, ShadowVariant, Theme } from '@deepstream/ui-kit/theme/types';
import { darken, lighten, transparentize, mix } from 'polished';
import { Color as ActionsConfigColor } from '@deepstream/common/actionsConfig';
import { GlobalStyles, ModalStyles } from './GlobalStyles';

const fonts = {
  heading: 'aktiv-grotesk, sans-serif',
  primary: 'aktiv-grotesk, sans-serif',
  secondary: 'din-2014, sans-serif',
} as const satisfies Record<FontVariant, string>;

const colors = {
  // Primary color
  primary: '#3498DB', // rgb(52, 152, 219)
  darkPrimary: '#217dbb',
  lightPrimary: '#8bc4ea',
  lightPrimary36: lighten(0.36, '#3498DB'),
  lightPrimary38: lighten(0.38, '#3498DB'),
  lightPrimary4: lighten(0.4, '#3498DB'),
  lightPrimary4Disabled: darken(0.05, mix(0.375, lighten(0.4, '#3498DB'), '#F2F4F7')),
  lightPrimary4Highlighted: darken(0.05, mix(0.375, lighten(0.4, '#3498DB'), '#fdf2df')),
  secondary: '#e2e8ef',
  primaryBackground: 'rgba(52, 152, 219, 0.1)',
  successBackground: 'rgba(24, 188, 156, 0.1)',

  // Text colors
  text: '#333c4e',
  subtext: '#7e8890',
  disabledText: '#90A2B4',
  subtextLight: lighten(0.2, '#7e8890'),
  subtextLightest: '#e5eaf1',
  subtext50: '#bec3c7',

  // Status colors
  success: '#18BC9C',
  warning: '#F39C12',
  danger: '#C86464',
  info: '#3498DB',

  // Grays
  lightGray: '#D4D9E2',
  lightGray2: '#E2E8EF',
  lightGray3: '#F7F9FB',
  lightGray3Hover: darken(0.03, '#F7F9FB'),
  lightGray3HoverDarker: darken(0.06, '#F7F9FB'),
  lightGray4: '#D9D9D9',
  lightGray5: '#808080',
  lightGray6: '#F5FAFD',
  lightGrayTransparent: 'rgba(212, 217, 226, 0.2)',
  gray: '#7E8890',
  darkGray: '#333C4E',
  darkGray2: ActionsConfigColor.DARK_GRAY,

  // Misc
  navy: '#1F2532',
  blueDark: '#1AA4FF',
  blue: '#3498DB',
  blueLight: '#ebf5fb',
  lightNavy: '#596377',
  white: '#ffffff',
  whiteHover: darken(0.1, '#ffffff'),
  background: '#ffffff',
  disabledBackground: '#F2F4F7',
  errorBackground: '#c864641a',
  warningBackground: 'rgb(253,242,225)',
  highlight: '#fdf2df',
} as const satisfies Record<Color, string>;

const borders = {
  secondary: `1px solid ${colors.secondary}`,
  gray: `1px solid ${colors.gray}`,
  lightGray: `1px solid ${colors.lightGray}`,
  lightGray2: `1px solid ${colors.lightGray2}`,
  primary: `1px solid ${colors.primary}`,
  placeholder: `1px dashed ${colors.lightGray2}`,
  success: `1px solid ${colors.success}`,
  warning: `1px solid ${colors.warning}`,
  white: `1px solid ${colors.white}`,
  none: '0',
} as const satisfies Record<BorderVariant, string>;

const shadows = {
  small: `
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.2)
  `,
  large: `
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 10px rgba(0, 0, 0, 0.2)
  `,
} as const satisfies Record<ShadowVariant, string>;

const radii = {
  small: 4,
  large: 10,
  badge: 12,
  circle: 9999,
} as const satisfies Record<RadiusVariant, number>;

export const commonButtonProps = {
  fontFamily: fonts.primary,
  ':not(:disabled):not(.disabled)': {
    cursor: 'pointer',
  },
  transition: `
    color 300ms,
    background-color 300ms,
    border-color 300ms,
    box-shadow 300ms;
  `,
};

export const createSolidButton = (backgroundColor: string) => ({
  ...commonButtonProps,
  backgroundColor,
  color: colors.white,
  '&:hover:not(:disabled):not(.disabled)': {
    backgroundColor: darken(0.1, backgroundColor),
  },
  '&:disabled, &.disabled': {
    backgroundColor: lighten(0.2, backgroundColor),
  },
  '&:focus-visible': {
    boxShadow: `0 0 0 4px ${transparentize(0.5, backgroundColor)}`,
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
});

const createOutlineButton = (color: string) => ({
  ...commonButtonProps,
  color,
  backgroundColor: colors.white,
  border: borders.lightGray,
  '&:hover:not(:disabled):not(.disabled)': {
    backgroundColor: color,
    color: colors.white,
    border: `1px solid ${color}`,
  },
  '&:disabled, &.disabled': {
    color: lighten(0.25, color),
  },
  '&:focus-visible': {
    boxShadow: `0 0 0 4px ${transparentize(0.5, color)}`,
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
});

const theme: Theme = {
  buttons: {
    'wrapper': {
      backgroundColor: 'transparent',
      border: 0,
    },
    'tab': {
      ...commonButtonProps,
      color: colors.subtext,
      backgroundColor: 'transparent',
      border: 0,
    },
    'tooltip': {
      backgroundColor: colors.navy,
      border: 0,
      '&:hover:not(:disabled)': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
      '&:disabled': {
        backgroundColor: lighten(0.05, colors.navy),
      },
    },
    'primary': createSolidButton(colors.primary),
    'success': {
      ...createSolidButton(colors.success),
      '&:disabled, &.disabled': {
        backgroundColor: colors.success,
        opacity: 0.25,
      },
    },
    'warning': createSolidButton(colors.warning),
    'danger': createSolidButton(colors.danger),
    'secondary': {
      ...commonButtonProps,
      backgroundColor: colors.secondary,
      color: colors.text,
      '&:hover:not(:disabled):not(.disabled)': {
        backgroundColor: darken(0.1, colors.secondary),
      },
      '&:disabled, &.disabled': {
        backgroundColor: lighten(0.05, colors.secondary),
        color: lighten(0.5, colors.text),
      },
      '&:focus-visible': {
        boxShadow: `0 0 0 4px ${transparentize(0.5, colors.primary)}`,
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    'primary-outline': createOutlineButton(colors.primary),
    'success-outline': {
      ...createOutlineButton(colors.success),
      '&:disabled, &.disabled': {
        borderColor: colors.success,
        color: colors.success,
        opacity: 0.25,
      },
    },
    'warning-outline': createOutlineButton(colors.warning),
    'danger-outline': createOutlineButton(colors.danger),
    'primary-transparent-outline': {
      ...createOutlineButton(colors.primary),
      backgroundColor: 'transparent',
    },
    'secondary-transparent-outline': {
      ...commonButtonProps,
      color: colors.text,
      backgroundColor: 'transparent',
      border: borders.lightGray,
      '&:hover:not(:disabled):not(.disabled)': {
        backgroundColor: colors.gray,
        color: colors.white,
        border: borders.gray,
      },
      '&:disabled, &.disabled': {
        color: lighten(0.25, colors.gray),
      },
      '&:focus-visible': {
        boxShadow: `0 0 0 4px ${transparentize(0.5, colors.primary)}`,
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    'secondary-outline': {
      ...commonButtonProps,
      color: colors.text,
      backgroundColor: colors.white,
      border: borders.lightGray,
      '&:hover:not(:disabled):not(.disabled)': {
        backgroundColor: colors.gray,
        color: colors.white,
        border: borders.gray,
      },
      '&:disabled, &.disabled': {
        color: lighten(0.25, colors.gray),
      },
      '&:focus-visible': {
        boxShadow: `0 0 0 4px ${transparentize(0.5, colors.primary)}`,
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    'secondary-subtle': {
      ...commonButtonProps,
      color: colors.text,
      backgroundColor: colors.white,
      '&:hover:not(:disabled):not(.disabled)': {
        backgroundColor: colors.secondary,
      },
      '&:disabled, &.disabled': {
        color: lighten(0.5, colors.text),
      },
      '&:focus-visible': {
        boxShadow: `0 0 0 4px ${transparentize(0.5, colors.primary)}`,
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    'transparent-subtle': {
      ...commonButtonProps,
      color: colors.subtext,
      backgroundColor: 'transparent',
      '&:focus-visible': {
        boxShadow: `0 0 0 4px ${transparentize(0.5, colors.primary)}`,
        outline: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
  } as Record<ButtonVariant, React.CSSProperties>,
  variants: {
    blue: {
      fontFamily: fonts.primary,
      color: colors.white,
      backgroundColor: colors.primary,
      borderRadius: radii.small,
      boxShadow: shadows.small,
    },
    white: {
      fontFamily: fonts.primary,
      color: colors.darkGray,
      backgroundColor: colors.white,
      border: borders.lightGray,
      borderRadius: radii.small,
    },
  },
  breakpoints: [
    '576px', '768px', '1100px', '1200px',
  ],
  fontSizes: [
    11, 12, 14, 16, 18, 20, 24, 32, 36, 48, 64,
  ],
  borders,
  colors,
  space: [
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  fonts,
  radii,
  shadows,
};

export const ClientThemeProvider = ({
  children,
}: {
  children?: React.ReactElement;
}) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <ModalStyles />
      {children}
    </>
  </ThemeProvider>
);
