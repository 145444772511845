import { BidIntentionStatus, BidOutcomeStatus, CombinedBidStatus } from './types';

// TODO later possibly rename
export const getCombinedBidStatus = (
  bid: { intentionStatus: BidIntentionStatus; outcomeStatus: BidOutcomeStatus },
): CombinedBidStatus => {
  if (bid.outcomeStatus === BidOutcomeStatus.AWARDED) {
    return CombinedBidStatus.AWARDED;
  }
  if (bid.outcomeStatus === BidOutcomeStatus.UNSUCCESSFUL) {
    return CombinedBidStatus.UNSUCCESSFUL;
  }

  switch (bid.intentionStatus) {
    case BidIntentionStatus.NO_RESPONSE:
      return CombinedBidStatus.NO_RESPONSE;
      case BidIntentionStatus.DECLINED_TO_BID:
      return CombinedBidStatus.NO_INTEND_TO_BID;
      case BidIntentionStatus.BID_WITHDRAWN:
      return CombinedBidStatus.WITHDRAWN;
    case BidIntentionStatus.BIDDING:
      return CombinedBidStatus.ACTIVE;
  }
};
