import { ReactElement, useMemo } from 'react';
import { fromPairs, pick } from 'lodash';
import { IconValue } from '@deepstream/common';
import { FilterDropdownPage, FilterDropdownPages, FilterDropdownPanelConfig } from '../FilterDropdownPages/FilterDropdownPages';
import { MultiSelectDropdownPage } from '../FilterDropdownPages/MultiSelectDropdownPage';
import { OperatorAndDateValueFilter, OperatorAndNumberValueFilter, OperatorAndNumberValueProps } from '../ComparisonFilter';

export type GridFilter<TItem> = {
  groupId?: string;
  pageId: string;
  config: GridFilterConfig<TItem>;
  filters: {
    data: Record<string, unknown[]>;
    update: (filterId: string, newFilters: unknown[]) => void;
  }
  items: any[];
};

export type GridFilterConfig<TItem> = {
  label: string;
  iconProps: { icon: IconValue; isIconRegular?: boolean };
  FilterPageComponent: ({ gridFilter }: { gridFilter: GridFilter<TItem> }) => ReactElement | null;
  getFilterItems: (items: TItem[], groupId?: string) => unknown[];
  /**
   * Returns the label for an item on the filter page.
   */
  renderFilterItem: ((item: any) => string | JSX.Element) | undefined;
  matchesFilter: (item: TItem, gridFilter: GridFilter<TItem>, groupId?: string) => boolean;
  /**
   * When defined, the return value overrides the `label`.
   * This is used to provide individual labels for multiple
   * grid filters that are based on the same grid filter config.
   */
  getFilterLabel?: (groupId: string) => string;
  renderPreItemContent?: (item: TItem, index: number) => React.ReactNode;
};

export const MultiSelectFilterPage = ({ gridFilter }: { gridFilter: GridFilter<any> }) => {
  const {
    onChange,
    selectedItems,
  } = useMemo(() => {
    return {
      onChange: (items) => {
        gridFilter.filters.update(gridFilter.pageId, items.map(item => item.value));
      },
      selectedItems: gridFilter.items.filter(item => (gridFilter.filters.data[gridFilter.pageId] || []).includes(item.value)),
    };
  }, [gridFilter.pageId, gridFilter.filters, gridFilter.items]);

  return (
    <FilterDropdownPage
      pageId={gridFilter.pageId}
      onChange={onChange}
      selectedItems={selectedItems}
    >
      <MultiSelectDropdownPage
        items={gridFilter.items}
        onChange={onChange}
        renderItem={gridFilter.config.renderFilterItem}
        renderPreItemContent={gridFilter.config.renderPreItemContent}
        selectedItems={selectedItems}
        idProp="value"
      />
    </FilterDropdownPage>
  );
};

export const OperatorAndNumberFilterPage = ({
  gridFilter,
  ...props
}: OperatorAndNumberValueProps & { gridFilter: GridFilter<any> }) => {
  const {
    onChange,
    selectedItems,
  } = useMemo(() => {
    return {
      onChange: (items) => {
        gridFilter.filters.update(gridFilter.pageId, items.map(item => pick(item, ['operator', 'value'])));
      },
      selectedItems: (gridFilter.filters.data[gridFilter.pageId] || []).map((item: any) => ({
        ...item,
        label: gridFilter.items.find(selectableItem => selectableItem.operator === item.operator)?.label,
      })),
    };
  }, [gridFilter.pageId, gridFilter.filters, gridFilter.items]);

  return (
    <FilterDropdownPage
      pageId={gridFilter.pageId}
      onChange={onChange}
      selectedItems={selectedItems}
    >
      <OperatorAndNumberValueFilter
        items={gridFilter.items}
        onChange={onChange}
        renderItem={gridFilter.config.renderFilterItem}
        selectedItems={selectedItems}
        {...props}
      />
    </FilterDropdownPage>
  );
};

export const OperatorAndDateFilterPage = ({ gridFilter }: { gridFilter: GridFilter<any> }) => {
  const {
    onChange,
    selectedItems,
  } = useMemo(() => {
    return {
      onChange: (items) => {
        gridFilter.filters.update(gridFilter.pageId, items.map(item => pick(item, ['operator', 'value'])));
      },
      selectedItems: (gridFilter.filters.data[gridFilter.pageId] || []).map((item: any) => ({
        ...item,
        label: gridFilter.items.find(selectableItem => selectableItem.operator === item.operator)?.label,
      })),
    };
  }, [gridFilter.pageId, gridFilter.filters, gridFilter.items]);

  return (
    <FilterDropdownPage
      pageId={gridFilter.pageId}
      onChange={onChange}
      selectedItems={selectedItems}
    >
      <OperatorAndDateValueFilter
        items={gridFilter.items}
        onChange={onChange}
        renderItem={gridFilter.config.renderFilterItem}
        selectedItems={selectedItems}
      />
    </FilterDropdownPage>
  );
};

export const GridFilterDropdown = ({ gridFilters, id }: { gridFilters: GridFilter<any>[], id?: string }) => {
  const filterPagePropsById: Record<string, FilterDropdownPanelConfig> = useMemo(() => {
    return fromPairs(
      gridFilters.map(item => [
        item.pageId,
        {
          pageId: item.pageId,
          title: item.groupId && item.config.getFilterLabel
            ? item.config.getFilterLabel(item.groupId)
            : item.config.label,
          ...item.config.iconProps,
        },
      ]),
    );
  }, [gridFilters]);

  return (
    <FilterDropdownPages
      config={filterPagePropsById}
      keepItemsCountInSyncWithConfig
      menuWidth={450}
      maxMenuHeight="360px"
      id={id}
    >
      {gridFilters.map((gridFilter) => (
        <gridFilter.config.FilterPageComponent
          key={gridFilter.pageId}
          gridFilter={gridFilter}
        />
      ))}
    </FilterDropdownPages>
  );
};
