import * as React from 'react';
import { useField } from 'formik';
import { Box, Flex, Text } from 'rebass/styled-components';
import { noop } from 'lodash';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useUniqueId } from '@deepstream/ui-kit/hooks/useUniqueId';
import { Select, SelectProps } from '../ui/Select';
import { ErrorMessage, HelperText } from './Field';
import { FieldContainer } from './FieldContainer';

export type SelectFieldBaseProps = {
  disabled?: boolean;
  error?: string;
  helperText?: string | React.ReactNode;
  hideLabel?: boolean;
  hideError?: boolean;
  label?: string;
  description?: string;
  name?: string;
  onBlurWithoutSelectedItem?: () => void;
  onChange?: any;
  required?: boolean;
  value?: any | undefined;
  items: any[];
  placeholder?: string;
  small?: boolean;
  buttonStyle?: React.CSSProperties;
  menuWidth?: React.CSSProperties['width'];
  maxHeight?: React.CSSProperties['maxHeight'];
  placement?: 'bottom' | 'top' | 'bottom-start' | 'bottom-end' | 'top-start';
  ToggleButtonComponent?: any;
  menuZIndex?: number;
  getButtonLabel?: SelectProps['getButtonLabel'];
  getItemLabel?: SelectProps['getItemLabel'];
  selectedBackgroundColor?: React.CSSProperties['backgroundColor'];
  footer?: React.ReactNode;
  canDeselect?: boolean;
  infoTooltip?: string;
  internalOnly?: boolean;
  showSelectedIcon?: boolean;
  truncateLineItem?: boolean;
  renderPreItemContent?: (item: any, index: number) => React.ReactNode;
};

export const SelectFieldBase = ({
  disabled,
  error,
  hideError,
  helperText,
  hideLabel,
  label,
  description,
  name,
  onChange = noop,
  required,
  items,
  value,
  infoTooltip,
  internalOnly,
  showSelectedIcon,
  ...props
}: SelectFieldBaseProps) => {
  const id = useUniqueId();

  return (
    <FieldContainer
      name={name}
      htmlFor={id}
      label={label}
      description={description}
      hideLabel={hideLabel}
      showAsterisk={required}
      infoTooltip={infoTooltip}
      internalOnly={internalOnly}
    >
      <Select
        key={id}
        name={name}
        onChange={({ selectedItem }: any) => selectedItem ? onChange(selectedItem.value) : onChange(null)}
        itemToString={getItemValue}
        getItemLabel={getItemLabel}
        items={items}
        selectedItem={value}
        disabled={disabled}
        showSelectedIcon={showSelectedIcon}
        {...props}
      />
      {error && !hideError ? (
        <Box sx={{ wordBreak: 'break-all', textAlign: 'left' }}>
          <ErrorMessage error={error} fontWeight="normal" />
        </Box>
      ) : helperText ? (
        <HelperText text={helperText} />
      ) : (
        null
      )}
    </FieldContainer>
  );
};

export interface SelectFieldProps extends SelectFieldBaseProps {
  name?: string;
  fieldName?: string;
  shouldHandleBlur?: boolean;
  onChange?: any;
  items: any[];
}

/**
 * Formik-aware select field component.
 *
 * If you pass a `fieldName` without a `name`, changing the value
 * won't update the corresponding value (this is useful for displaying
 * read-only derived values)
 */
export const SelectField = ({
  name,
  fieldName,
  shouldHandleBlur,
  ...props
}: SelectFieldProps) => {
  const resolvedFieldName = fieldName || name;

  if (!fieldName && !name) {
    throw new Error('`fieldName` is required if no `name` prop included');
  }

  const [field, meta, formik] = useField({ name: resolvedFieldName! });

  return (
    <SelectFieldBase
      error={meta.touched && meta.error ? meta.error : undefined}
      name={name}
      onBlurWithoutSelectedItem={shouldHandleBlur ? (
        () => { formik.setTouched(true); }
      ) : (
        undefined
      )}
      onChange={value => { formik.setValue(value); }}
      value={field.value}
      {...props}
    />
  );
};

export const getItemValue = (item: { value: string } | undefined) => item ? item.value : '';

export const getItemLabel = (item: { label: string } | undefined) => item ? item.label : '';

export const getItemLabelWithDescription = (item: { label: string; description: string } | undefined) =>
  item ? (
    <Box>
      <Text>
        {item.label}
      </Text>
      <Text color="subtext" mt={1} fontSize={0} sx={{ whiteSpace: 'break-spaces' }}>
        {item.description}
      </Text>
    </Box>
  ) : (
    ''
  );

export const getItemLabelWithIconAndDescription = (item: any) =>
  item ? (
    <Flex>
      <Box mr={2} flex="0 0 18px">
        <Icon
          icon={item.icon}
          regular={item.isIconRegular}
          fixedWidth
          fontSize="inherit"
        />
      </Box>
      {item.isObsolete && (
        <Box mr={1} flex="0 0 18px">
          <Icon icon="ban" fixedWidth fontSize="inherit" />
        </Box>
      )}
      <Box>
        <Text>
          {item.label}
        </Text>
        <Text color="subtext" mt={1} fontSize={0} sx={{ whiteSpace: 'break-spaces' }}>
          {item.description}
        </Text>
      </Box>
    </Flex>
  ) : (
    ''
  );

export const getItemLabelWithIcon = (item: any) =>
  item ? (
    <Flex>
      <Box mr={2} flex="0 0 18px">
        <Icon
          icon={item.icon}
          regular={item.isIconRegular}
          fixedWidth
          fontSize="inherit"
        />
      </Box>
      {item.isObsolete && (
        <Box mr={1} flex="0 0 18px">
          <Icon icon="ban" fixedWidth fontSize="inherit" />
        </Box>
      )}
      <Text>
        {item.label}
      </Text>
    </Flex>
  ) : (
    ''
  );
