import { useQuery } from 'react-query';
import { Interval, RequestsReportingConfig } from '@deepstream/common/reporting';
import { subDays, subMonths, subQuarters, subWeeks, subYears } from 'date-fns';
import { useAdminApi, wrap } from '@deepstream/ui/api';
import { useCurrentCompanyId } from '@deepstream/ui/currentCompanyId';
import { useSelectedCompanyIds } from '@deepstream/ui/selectedCompanyIds';

export const getStartDate = (date: Date, interval: Interval, amount = 13) => {
  switch (interval) {
    case Interval.DAY:
      return subDays(date, amount);
    case Interval.WEEK:
      return subWeeks(date, amount);
    case Interval.MONTH:
      return subMonths(date, amount);
    case Interval.QUARTER_YEAR:
      return subQuarters(date, amount);
    case Interval.YEAR:
      return subYears(date, amount);
  }
};

export const useAdminRequestsReportingCardsData = <TData,>(
  config: RequestsReportingConfig,
  section: 'budget' | 'value' | 'savings' | 'allRequests',
) => {
  const companyId = useCurrentCompanyId({ required: true });
  const adminApi = useAdminApi();
  const selectedCompanyIds = useSelectedCompanyIds();

  return useQuery<TData>(
    ['adminRequestsReportingCardsData', {
      companyId,
      section,
      config,
      selectedCompanyIds,
    }],
    wrap(async ({ companyId, section, config, selectedCompanyIds }) => {
      const endDate = new Date();
      const startDate = getStartDate(endDate, config.interval);

      return adminApi.getRequestsReportingCardsData({
        companyId,
        section,
        config,
        startDate,
        endDate,
        selectedCompanyIds,
      });
    }),
  );
};

export const useAdminRequestsReportingTableData = (
  section: 'budget' | 'value' | 'savings' | 'allRequests',
) => {
  const companyId = useCurrentCompanyId({ required: true });
  const adminApi = useAdminApi();
  const selectedCompanyIds = useSelectedCompanyIds();

  return useQuery(
    ['adminRequestsReportingTableData', { companyId, section, selectedCompanyIds }],
    wrap(adminApi.getRequestsReportingTableData),
  );
};

export const useAdminRequestsReporting = (config: RequestsReportingConfig) => {
  const companyId = useCurrentCompanyId({ required: true });
  const adminApi = useAdminApi();
  const selectedCompanyIds = useSelectedCompanyIds();

  return useQuery(
    ['adminRequestsReportingOther', { companyId, currency: config.currency, selectedCompanyIds }],
    wrap(adminApi.getRequestsReportingOtherData),
  );
};
