import { RfqStatus } from '@deepstream/common/rfq-utils';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { ButtonGroup } from '@deepstream/ui-kit/elements/button/ButtonGroup';
import { PanelPadding } from '@deepstream/ui-kit/elements/Panel';
import {
  BORDER_ADJUSTMENT,
  DEFAULT_FROZEN_HEADER_HEIGHT,
} from '@deepstream/ui-kit/grid/core/constants';
import { DefaultEditableGridStyles } from '@deepstream/ui-kit/grid/EditableGrid/EditableGridStyles';
import { GridIdPrefixProvider } from '@deepstream/ui-kit/grid/EditableGrid/gridIdPrefix';
import { ReadOnlyGrid } from '@deepstream/ui-kit/grid/EditableGrid/ReadOnlyGrid';
import { RenderHoverableGridDataCell } from '@deepstream/ui-kit/grid/EditableGrid/RenderHoverableGridDataCell';
import { SortConfigProvider } from '@deepstream/ui-kit/grid/EditableGrid/sortConfig';
import { useMultiSortGridRows } from '@deepstream/ui-kit/grid/EditableGrid/useMultiSortGridRows';
import { DEFAULT_ROW_HEIGHT } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'rebass/styled-components';
import { stopPropagation } from '@deepstream/ui-utils/domEvent';
import { Bold } from '../../Bold';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { PageInfo, PageSizeSelect } from '../../Pagination';
import { useRequestsNavigation } from '../../RequestsNavigationContext';
import { GridFilterDropdown } from '../../ui/ExchangeDefsGrid/GridFilterDropdown';
import { HorizontalDivider } from '../../ui/HorizontalDivider';
import { HideColumnsSelectDropdownMenu } from '../../ui/MultiSelect';
import { MultiSortDropdown } from '../../ui/SortDropdown';
import { useCurrentUser } from '../../useCurrentUser';
import { useLocalStorageState } from '../../useLocalStorageState';
import { ExpandViewButton } from '../Request/Comparison/ExpandViewButton';

import { allRequestsGridAvailableColumnIds } from './allRequestsGridViews';
import { useAllRequestsReportingColumnConfigById } from './useAllRequestsGridColumnConfigById';
import { ALL_REQUESTS_GRID_FILTER_DROPDOWN_ID, ALL_REQUESTS_GRID_SORT_DROPDOWN_ID, useAllRequestsGridColumns } from './useAllRequestsGridColumns';
import { useAllRequestsGridConfig } from './useAllRequestsGridConfig';
import { useAllRequestsGridFilters } from './useAllRequestsGridFilters';

const pageSizes = [5, 10, 20, 30, 40, 50];

export const FROZEN_LEFT_COLUMN_WIDTH = 400;
const frozenLeftColumnIds = ['subject'];
const viewportHeightDelta = 170;

type TableConfig = {
  startIndex: number;
  pageSize: number;
};

const defaultCollapsedTableConfig = {
  startIndex: 0,
  pageSize: 5,
};

const expandedTableConfig = {
  startIndex: 0,
  pageSize: Number.MAX_SAFE_INTEGER / 2,
};

export const AllRequestsDataReportingGrid = ({ data }) => {
  const { t } = useTranslation('translation');
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const { navigateToSenderRequest } = useRequestsNavigation();
  const [isExpandedView, setIsExpandedView] = useState(false);

  const navigateToRequest = useCallback(
    (request) =>
      navigateToSenderRequest({
        requestId: request._id,
        isDraft: request.extendedStatus === RfqStatus.DRAFT,
      }),
    [navigateToSenderRequest],
  );

  const columnConfigById = useAllRequestsReportingColumnConfigById();
  const gridConfig = useAllRequestsGridConfig({
    storageKey: `${currentCompanyId}.${currentUser._id}.allRequestsData.config`,
    availableColumnIds: allRequestsGridAvailableColumnIds,
    columnConfigById,
  });

  const columns = useAllRequestsGridColumns({
    gridConfig,
    columnConfigById,
  });

  const gridFilters = useAllRequestsGridFilters(
    gridConfig,
    data,
    columnConfigById,
  );

  const filterRequests = useCallback(
    (requests) => {
      return requests.filter((request) => {
        return gridFilters.every((gridFilter) =>
          gridFilter.config.matchesFilter(request, gridFilter),
        );
      });
    },
    [gridFilters],
  );

  const sortRows = useMultiSortGridRows(
    columns,
    gridConfig.sorting.selectedSorting,
  );

  const {
    totalRowCount,
    visibleRowCount,
    rows,
  }: {
    totalRowCount: number;
    visibleRowCount: number;
    rows: any[];
  } = useMemo(() => {
    const filteredRequests = filterRequests(data);

    const rows = filteredRequests.map((request) => ({
      _id: request._id,
      request,
    }));

    return {
      totalRowCount: data.length,
      visibleRowCount: rows.length,
      rows: sortRows(rows),
    };
  }, [data, sortRows, filterRequests]);

  const [collapsedTableConfig, setCollapsedTableConfig] =
    useLocalStorageState<TableConfig>({
      key: `${currentCompanyId}.${currentUser._id}.reporting.requests.allRequests.table`,
      defaultValue: defaultCollapsedTableConfig,
    });

  const [sortConfig, setSortConfig] = useState({
    columnId: null,
    direction: null,
  });

  const currentPageIndex = Math.floor(
    collapsedTableConfig.startIndex / collapsedTableConfig.pageSize,
  );
  const maxPageIndex = Math.floor(
    Math.max(0, rows.length - 1) / collapsedTableConfig.pageSize,
  );

  // Prevent case where the start index in the cached table config would cause an empty
  // page to render because the number of requests has descreased (which can be the case,
  // for example, when a user gets downgraded from a super user to a full user) by
  // updating the table config to show the last page for the current data.
  useEffect(() => {
    if (currentPageIndex > maxPageIndex) {
      setCollapsedTableConfig({
        startIndex: maxPageIndex * collapsedTableConfig.pageSize,
        pageSize: collapsedTableConfig.pageSize,
      });
    }
  }, [
    collapsedTableConfig.pageSize,
    currentPageIndex,
    maxPageIndex,
    setCollapsedTableConfig,
  ]);

  const tableConfig = isExpandedView
    ? expandedTableConfig
    : collapsedTableConfig;

  const dataToShow = useMemo(() => {
    return rows.slice(
      tableConfig.startIndex,
      tableConfig.startIndex + tableConfig.pageSize,
    );
  }, [rows, tableConfig]);

  const hiddenRowCount = totalRowCount - visibleRowCount;
  const showFooter = hiddenRowCount && totalRowCount;

  const collapsedGridHeight =
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * Math.min(collapsedTableConfig.pageSize, visibleRowCount) +
    getScrollbarSize() +
    (showFooter ? 48 : 0);

  const expandedGridHeight =
    DEFAULT_FROZEN_HEADER_HEIGHT +
    BORDER_ADJUSTMENT +
    DEFAULT_ROW_HEIGHT * visibleRowCount +
    getScrollbarSize() +
    (showFooter ? 48 : 0);

const FooterContent = useMemo(() => {
    if (showFooter) {
      return ({ height, width }) => {
        return (
          <Flex
            color="lightNavy"
            alignItems="center"
            sx={{
              position: 'absolute',
              left: 10,
              top: height - 48,
              height: '48px',
              width,
              zIndex: 6000,
              backgroundColor: 'rgb(247, 249, 251)',
            }}
          >
            <Box mb="3px">
              <Trans
                t={t}
                i18nKey="requests.filtering.rowHiddenByFilter"
                ns="translation"
                count={hiddenRowCount}
                components={{ b: <Bold mr="3px" /> }}
              />
            </Box>
            <Button
              ml={3}
              iconLeft="xmark"
              small
              variant="secondary-outline"
              type="button"
              onKeyDown={stopPropagation}
              onClick={gridConfig.filters.clear}
            >
              {t('requests.filtering.clearFilters')}
            </Button>
          </Flex>
        );
      };
    } else {
      return null;
    }
  }, [hiddenRowCount, showFooter, gridConfig.filters.clear, t]);

  return (
    <Box
      sx={
        isExpandedView
          ? {
              py: '20px',
              inset: 0,
              zIndex: 150,
              position: 'fixed',
              backgroundColor: 'lightGray3',
            }
          : {
              height: collapsedGridHeight + viewportHeightDelta,
            }
      }
    >
      <Flex
        p="20px 20px 16px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex sx={{ gap: 2 }}>
          <GridFilterDropdown
            id={ALL_REQUESTS_GRID_FILTER_DROPDOWN_ID}
            gridFilters={gridFilters}
          />

          <MultiSortDropdown
            id={ALL_REQUESTS_GRID_SORT_DROPDOWN_ID}
            popoverPlacement="bottom-start"
            {...gridConfig.sorting}
          />
          <HideColumnsSelectDropdownMenu
            buttonText={t('request.suppliersTable.hideColumn_other')}
            menuWidth={330}
            menuZIndex={151}
            onResetClick={noop}
            isResetButtonDisabled={false}
            {...gridConfig.columns}
          />
        </Flex>
        <ExpandViewButton
          isExpandedView={isExpandedView}
          setIsExpandedView={setIsExpandedView}
        />
      </Flex>
      <HorizontalDivider />
      <PanelPadding>
        <SortConfigProvider
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        >
          <GridIdPrefixProvider>
            <DefaultEditableGridStyles
              style={{
                width: '100%',
                height: isExpandedView
                  ? `min(${expandedGridHeight}px, calc(100vh - ${viewportHeightDelta}px))`
                  : collapsedGridHeight,
              }}
              isReadOnly
              highlightOnHover
            >
              <ReadOnlyGrid
                frozenLeftColumnIds={frozenLeftColumnIds}
                columns={columns}
                rowData={dataToShow as any}
                RenderDataCell={RenderHoverableGridDataCell}
                onRowClick={navigateToRequest}
                FooterContent={FooterContent}
              />
            </DefaultEditableGridStyles>
          </GridIdPrefixProvider>
        </SortConfigProvider>
      </PanelPadding>
      <Flex px="20px" justifyContent="space-between" alignItems="center">
        <Text fontSize={1}>
          {t('request.spendAndSavings.showingFromToOfTotal', {
            from: tableConfig.startIndex + 1,
            to: Math.min(
              tableConfig.startIndex + tableConfig.pageSize,
              rows.length,
            ),
            total: rows.length,
          })}
        </Text>
        <Flex sx={{ gap: 3 }}>
          {!isExpandedView && (
            <>
              <PageSizeSelect
                small
                pageSize={collapsedTableConfig.pageSize}
                pageSizes={pageSizes}
                setPageSize={(pageSize) =>
                  setCollapsedTableConfig({
                    startIndex:
                      Math.floor(collapsedTableConfig.startIndex / pageSize) *
                      pageSize,
                    pageSize,
                  })
                }
                getButtonLabel={(item) => <Bold as="span">{item.label}</Bold>}
                menuWidth={150}
                translationKey="table.pagination.perPage"
              />
              <PageInfo
                pageIndex={currentPageIndex}
                maxPageIndex={maxPageIndex}
                setPageIndex={(pageIndex) => {
                  setCollapsedTableConfig({
                    startIndex: pageIndex * collapsedTableConfig.pageSize,
                    pageSize: collapsedTableConfig.pageSize,
                  });
                }}
              />
              <ButtonGroup marginBetween="-1px">
                <Button
                  variant="secondary-outline"
                  small
                  iconLeft="chevron-left"
                  disabled={collapsedTableConfig.startIndex === 0}
                  onClick={() =>
                    setCollapsedTableConfig({
                      startIndex:
                        collapsedTableConfig.startIndex -
                        collapsedTableConfig.pageSize,
                      pageSize: collapsedTableConfig.pageSize,
                    })
                  }
                  sx={{ width: '28px' }}
                />
                <Button
                  variant="secondary-outline"
                  small
                  iconLeft="chevron-right"
                  disabled={
                    collapsedTableConfig.startIndex +
                      collapsedTableConfig.pageSize >
                    data.length
                  }
                  onClick={() =>
                    setCollapsedTableConfig({
                      startIndex:
                        collapsedTableConfig.startIndex +
                        collapsedTableConfig.pageSize,
                      pageSize: collapsedTableConfig.pageSize,
                    })
                  }
                  sx={{ width: '28px' }}
                />
              </ButtonGroup>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
