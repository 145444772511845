import { memo, forwardRef, useState } from 'react';
import { isNil } from 'lodash';
import {
  DEFAULT_FROZEN_FOOTER_HEIGHT,
} from './constants';
import { FrozenPanelsContext, FrozenPanelsContextValue } from './FrozenPanels';
import { InnerElementTypeProps, TExpandableRowDataBase, TOriginalColumnDataBase, TOriginalSubRowDataBase } from './utils';
import { ColumnResizer } from './ColumnResizer';
import { zIndex } from './ComparisonGridStyles';

export const GridInnerElementType = memo(forwardRef<
  HTMLDivElement,
  InnerElementTypeProps & { leftColumnItems: any; headerItems: any; headerLeftColumnItems: any }
>(({ style, children, leftColumnItems, headerItems, headerLeftColumnItems, ...rest }, ref) => {
  const [columnResizeActiveIndex, setColumnResizeActiveIndex] = useState<number | null>();

  return (
    <FrozenPanelsContext.Consumer>
      {<
        TOriginalColumnData extends TOriginalColumnDataBase,
        TOriginalSubRowData extends TOriginalSubRowDataBase,
        TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
      >(frozenPanelsContextValue: FrozenPanelsContextValue<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>) => {
        const {
          FrozenFooter,
          idPrefix,
          bodyPaddingRight,
          bodyPaddingBottom,
          AdditionalHeaderContent,
          FooterContent,
          frozenLeftColumnWidth,
          onColumnResize,
        } = frozenPanelsContextValue;

        const footerHeight = FrozenFooter ? DEFAULT_FROZEN_FOOTER_HEIGHT : 0;

        const scrollAreaHeight = parseFloat(style.height) + bodyPaddingBottom + footerHeight;

        return (
          <div
            ref={ref}
            role="row"
            style={{
              ...style,
              height: `${scrollAreaHeight}px`,
              minHeight: '100%',
              width: `${parseFloat(style.width) + bodyPaddingRight}px`,
              minWidth: '100%',
            }}
            {...rest}
          >
            <div className="top-left-cell-wrapper">
              <div
                className="top-left-cell"
                style={{
                  width: `${frozenLeftColumnWidth}px`,
                }}
              >
                {headerLeftColumnItems}
              </div>
            </div>
            <div className="frozen-header-wrapper">
              <div className="frozen-header-first-line">
                {headerItems}
              </div>
              {AdditionalHeaderContent && (
                <AdditionalHeaderContent
                  height={parseFloat(style.height)}
                  width={parseFloat(style.width)}
                />
              )}
            </div>

            <div className="frozen-left-column-wrapper" style={{ width: `${frozenLeftColumnWidth}px` }}>
              {leftColumnItems}
              {FooterContent && (
                <FooterContent
                  height={parseFloat(style.height)}
                  width={parseFloat(style.width)}
                  idPrefix={idPrefix}
                />
              )}
            </div>
            {FrozenFooter ? (
              <FrozenFooter />
            ) : (
              null
            )}
            {children}
            {onColumnResize ? (
              headerLeftColumnItems.map(headerItem => {
                const column = headerItem.props.data.columns[headerItem.key];

                const hasActiveColumnResize = !isNil(columnResizeActiveIndex);

                // When column resizing is active, we only want to render the current column
                // to avoid problems when the current handle moves to the area of a different
                // resize handle.
                return column && !column.original.fixedWidth && (!hasActiveColumnResize || columnResizeActiveIndex === column.index) ? (
                  <ColumnResizer
                    key={headerItem.key}
                    column={column}
                    zIndex={zIndex.leftColumnResizeHandle}
                    scrollAreaHeight={scrollAreaHeight}
                    hasActiveColumnResize={hasActiveColumnResize}
                    setColumnResizeActiveIndex={setColumnResizeActiveIndex}
                    onColumnResize={onColumnResize}
                  />
                ) : (
                  null
                );
              })
            ) : (
              null
            )}
            {onColumnResize ? (
              headerItems.map(headerItem => {
                const column = headerItem.props.data.columns[headerItem.key];

                const hasActiveColumnResize = !isNil(columnResizeActiveIndex);

                // When column resizing is active, we only want to render the current column
                // to avoid problems when the current handle moves to the area of a different
                // resize handle.
                return column && !column.original.fixedWidth && (!hasActiveColumnResize || columnResizeActiveIndex === column.index) ? (
                  <ColumnResizer
                    key={headerItem.key}
                    column={column}
                    zIndex={zIndex.columnResizeHandle}
                    scrollAreaHeight={scrollAreaHeight}
                    hasActiveColumnResize={hasActiveColumnResize}
                    setColumnResizeActiveIndex={setColumnResizeActiveIndex}
                    onColumnResize={onColumnResize}
                  />
                ) : (
                  null
                );
              })
            ) : (
              null
            )}
          </div>
        );
      }}
    </FrozenPanelsContext.Consumer>
  );
}));
