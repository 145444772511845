import * as React from 'react';
import { useQuery } from 'react-query';
import { Text, TextProps } from 'rebass/styled-components';
import { useApi, wrap } from '../api';

export const CompanyNameText : React.FC<{ id: string } & TextProps> = ({ id: companyId, ...props }) => {
  const api = useApi();

  const { data: company } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany),
  );

  return <Text {...props}>{company?.name}</Text>;
};
