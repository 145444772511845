import {
  requestStatusesConfig, RfqStatus,
} from '@deepstream/common/rfq-utils';
import { IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type RequestStatusFilter = {
  value: RfqStatus;
  label: string;
  iconProps: {
    icon: IconProps['icon'];
    color: string;
  };
};

export const useRequestStatusFilterItems = (): RequestStatusFilter[] => {
  const { t } = useTranslation('translation');

  return useMemo(() => {
    return Object.keys(RfqStatus)
      .map((key) => RfqStatus[key])
      .map((rfqStatus) => ({
        value: rfqStatus,
        label: t(`request.status.${rfqStatus}`),
        iconProps: {
          icon: requestStatusesConfig[rfqStatus].icon.value,
          color: requestStatusesConfig[rfqStatus].icon.color,
        },
      }));
  }, [t]);
};
