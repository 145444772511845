import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { cloneDeep, every, findLast, first, identity, isNil, omit } from 'lodash';
import { Form, Formik } from 'formik';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { ActionType, ExchangeType, ExtendedDateTimeFactory, QuestionExchangeDefinition, QuestionResponse, QuestionType, SerializedDate, isEmptyResponse, isLineItemExchangeDef } from '@deepstream/common/rfq-utils';
import { useQuery } from 'react-query';
import { usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { isMac } from '@deepstream/ui-utils/isMac';
import { Button, CancelButton } from '@deepstream/ui-kit/elements/button/Button';
import { ConfirmationPopover } from '@deepstream/ui-kit/elements/popup/ConfirmationPopover';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { ConfirmActionDialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { QuestionnaireStatus } from '@deepstream/common/preQual';
import { useExchange } from '../../useExchange';
import { useSendExchangeReplyContext } from '../../ExchangeModal/useSendExchangeReply';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { getExchangeReplyFormConfig } from '../../ExchangeModal/exchangeReplyFormConfig';
import { LabelConfig, LabelConfigProvider } from '../../LabelConfigProvider';
import { ExchangeHistoryAction, ExchangeSnapshot, LineItemsExchangeSnapshot } from '../../types';
import { RadioFieldBase } from '../../form/RadioField';
import { useApi, wrap } from '../../api';
import { FieldContainer } from '../../form/FieldContainer';
import { useSearch } from '../../tanstackRouter';
import { ContextType, useHooks } from '../../useHooks';
import { useIsIncompleteSenderSigner } from '../Contracts/contract';
import { GridQuestionExpandedViewContainer } from '../../ui/ExchangeDefsGrid/QuestionResponseGrid';
import { useConfirmDialog } from '../../ui/useModalState';

const INITIAL_ACTION_INDEX = -1;

const documentExchangeSubmitTypes = [
  // Accept the document or deviate
  ExchangeType.ACCEPT,
  // Accept the document
  ExchangeType.ACCEPT_CLOSED,
  // Upload completed document
  ExchangeType.COMPLETE_OR_SIGN_CLOSED,
  // Upload completed document or deviate
  ExchangeType.COMPLETE_OR_SIGN,
  // Upload requested document
  ExchangeType.DOCUMENT_REQUEST,
];

// Hack for angular side
export const ResetLabelStyle = styled(Box)`
  label {
    font-weight: normal;
    color: ${props => props.theme.colors.text};
  }
`;

const KeyboardShortcutItem = styled(Box)`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.lightGray2};
  background: ${props => props.theme.colors.lightGray3};
  font-size: 10px;
  padding: 1px 4px;
`;

const isEvaluationCriterionSubmitAction = (exchangeType: ExchangeType, actionType?: ActionType) => {
  return exchangeType === ExchangeType.EVALUATION_CRITERION && actionType === ActionType.SUBMIT;
};

const isDocumentExchangeSubmitAction = (exchangeType: ExchangeType) => {
  return documentExchangeSubmitTypes.includes(exchangeType);
};

const ClearResponseDropdown = ({
  onConfirm,
  disabled,
}: {
  onConfirm: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const popover = usePopover({
    placement: 'top-start',
    strategy: 'fixed',
    overReferenceElement: true,
  });

  return (
    <>
      <Button
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        small
        disabled={disabled}
        type="button"
        variant="danger-outline"
        onClick={() => {
          if (popover.update) {
            popover.update();
          }
          popover.toggle();
        }}
      >
        {t('request.exchange.clearResponse')}
      </Button>
      <ConfirmationPopover
        width="100%"
        maxWidth="265px"
        popover={popover}
        heading={t('request.dialog.clearResponse.heading')}
        body={t('request.dialog.clearResponse.body')}
        okButtonText={t('request.exchange.clearResponse')}
        okButtonVariant="danger-outline"
        okButtonSmall
        okButtonDisabled={disabled}
        onOk={async () => {
          onConfirm();
          popover.close();
        }}
        onCancel={popover.close}
        cancelButtonSmall
      />
    </>
  );
};

const ReusePreviousResponseDropdown = ({
  onConfirm,
}: {
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const popover = usePopover({
    placement: 'top-start',
    strategy: 'fixed',
    overReferenceElement: true,
  });
  const exchange = useExchange();

  const lastActionType = findLast(
    exchange.history,
    action => action.type !== ActionType.NONE,
  )?.type;
  const popoverBodyText = lastActionType === ActionType.SUBMIT
    ? t('request.dialog.reusePreviousResponse.body.responseCleared')
    : t('request.dialog.reusePreviousResponse.body.moreInfoRequired');

  return (
    <>
      <Button
        // @ts-expect-error ts(2322) FIXME: Type 'Dispatch<SetStateAction<HTMLElement | undefined>>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={popover.setReferenceElement}
        small
        type="button"
        variant="primary-outline"
        onClick={() => {
          if (popover.update) {
            popover.update();
          }
          popover.toggle();
        }}
      >
        {t('request.exchange.reusePreviousResponse')}
      </Button>
      <ConfirmationPopover
        width="100%"
        maxWidth="300px"
        popover={popover}
        heading={t('request.dialog.reusePreviousResponse.heading')}
        body={popoverBodyText}
        okButtonText={t('request.exchange.reusePreviousResponse')}
        okButtonVariant="primary-outline"
        okButtonSmall
        onOk={async () => {
          onConfirm();
          popover.close();
        }}
        onCancel={popover.close}
        cancelButtonSmall
      />
    </>
  );
};

const SubmitButtonLabelText = ({
  exchangeType,
  action,
  initialValues,
}: {
  exchangeType: ExchangeType,
  action: ExchangeHistoryAction,
  initialValues: { points: number | null },
}) => {
  const { t } = useTranslation('translation');

  // TODO use same pattern also for other exchanges, like line items
  const label = isEvaluationCriterionSubmitAction(exchangeType, action?.type)
    ? isNil(initialValues.points)
      ? t('request.exchange.actionLabel.evaluation.submit')
      : t('request.exchange.actionLabel.evaluation.update')
    : isDocumentExchangeSubmitAction(exchangeType)
      ? t('common.submitResponse', { ns: 'exchangeActionLabel' })
      : t(getActionSubmitLabel(action), { ns: 'exchangeActionLabel' });

  return (
    <>{label}</>
  );
};

const canOnlySubmitWhenDirty = (exchange: ExchangeSnapshot) => [
  ExchangeType.QUESTION,
  ExchangeType.LINE_ITEM,
  ExchangeType.EVALUATION_CRITERION,
].includes(exchange.def.type);

const getActionLabel = (action: { submitLabelTranslationKey?: string; actionLabelTranslationKey?: string }) => {
  if (action?.actionLabelTranslationKey) {
    return action.actionLabelTranslationKey;
  }

  return 'common.submit';
};

const getActionSubmitLabel = (action: { submitLabelTranslationKey?: string; actionLabelTranslationKey?: string }) => {
  if (action?.submitLabelTranslationKey) {
    return action.submitLabelTranslationKey;
  }

  return 'common.submit';
};

const SelectLevel2Action = ({
  actions,
  actionIndex,
  hideLabel,
  setActionIndex,
}: {
  actions: ExchangeHistoryAction[];
  actionIndex: number;
  hideLabel?: boolean;
  setActionIndex: (index: number) => void;
}) => {
  const { t } = useTranslation(['translation', 'general']);

  const options: any = React.useMemo(
    () => actions.map((action, index) => {
      return {
        value: String(index),
        label: t(getActionLabel(action), { ns: 'exchangeActionLabel' }),
        disabled: action.disabled,
      };
    }),
    [actions, t],
  );

  return (
    <ResetLabelStyle mb={2}>
      <RadioFieldBase
        variant="stacked"
        label={hideLabel ? ' ' : t('response', { ns: 'general' })}
        options={options}
        value={String(actionIndex)}
        onChange={setActionIndex}
      />
    </ResetLabelStyle>
  );
};

const extendedDateTimeFactory = new ExtendedDateTimeFactory();

interface InlineExchangeReplyProps {
  actions: ExchangeHistoryAction[];
  onClose: () => void;
  clearResponse?: (selectedAction: ExchangeHistoryAction) => void;
  canESign?: boolean;
  isESignDisabled?: boolean;
  canReusePreviousResponse?: boolean;
  isBulletinExchange?: boolean;
  columns: any[];
}

export const InlineExchangeReply = ({
  actions,
  canESign,
  isESignDisabled,
  canReusePreviousResponse,
  onClose,
  clearResponse,
  isBulletinExchange,
  columns,
}: InlineExchangeReplyProps) => {
  const { t } = useTranslation(['translation', 'general', 'contracts']);
  const exchange = useExchange();
  const [sendExchangeReply, { isLoading }] = useSendExchangeReplyContext();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const api = useApi();
  const { useESign, contextType, useStatus, useUpdateBulletin } = useHooks();
  const eSignMutation = useESign();
  const bulletinMutation = useUpdateBulletin();
  const search = useSearch({ strict: false }) as { from?: 'verified' };
  const isIncompleteSenderSigner = useIsIncompleteSenderSigner();
  const [isLoadingApproveAndESign, setIsLoadingApproveAndESign] = React.useState(false);
  // Expanded view is used for grid questions
  const [isExpandedView, setIsExpandedView] = React.useState(false);
  // Used for resetting the question response grid when the user reuses the previous response.
  const [gridResponseKey, setGridResponseKey] = React.useState(0);
  const { confirm, ...confirmDialogProps } = useConfirmDialog();
  const status = useStatus();

  let eSign;
  let isLoadingESign;

  if (eSignMutation) {
    [eSign, { isLoading: isLoadingESign }] = eSignMutation;
  }

  // Used for updating bulletin exchanges
  let updateBulletin;
  if (bulletinMutation) {
    [updateBulletin] = bulletinMutation;
  }

  const { data: companyUsers = [] } = useQuery(
    ['usersForCompany', { companyId: currentCompanyId }],
    wrap(api.getUsersForCompany),
  );

  const approveAction = React.useMemo(
    () => actions.find(action => action.type === ActionType.APPROVE),
    [actions],
  );

  const canApproveAndESign = (
    exchange.isAwaitingApproval &&
    Boolean(approveAction) &&
    isIncompleteSenderSigner
  );

  const canRequireMoreInfo = Boolean(actions.find(action => action.type === ActionType.REQUIRE_MORE_INFO));

  const [selectedActionIndex, setSelectedActionIndex] = React.useState(INITIAL_ACTION_INDEX);

  // If the current user can Approve and E-sign, we merge the Approve and E-sign actions into one
  // using a separate "Approve and e-sign" button.
  const filteredActions = React.useMemo(
    () => canApproveAndESign
      ? actions.filter(action => action.type !== ActionType.APPROVE)
      : actions,
    [actions, canApproveAndESign],
  );

  // Threshold for the minimum number of actions for which we show the action select
  const minNumActionsForSelect = canESign || canApproveAndESign || canRequireMoreInfo ? 1 : 2;
  const selectedAction = filteredActions.length >= minNumActionsForSelect
    ? filteredActions[selectedActionIndex]
    : first(filteredActions);

  const { initialValues, validationSchema, sanitize, Fields } = React.useMemo(
    () => {
      const formConfig = getExchangeReplyFormConfig(exchange, selectedAction);

      return {
        initialValues: formConfig.getInitialValues(exchange, { companyId: currentCompanyId, userId: currentUser._id }),
        validationSchema: formConfig.getValidationSchema(t, exchange),
        sanitize: formConfig.sanitize || identity,
        Fields: formConfig.Fields,
      };
    },
    [exchange, selectedAction, currentCompanyId, currentUser._id, t],
  );

  const handleFormKeyDown = (submitForm, reusePreviousResponse) => (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (submitForm && event.key === 'Enter' && ((isMac && event.metaKey) || (!isMac && event.ctrlKey))) {
      stopEvent(event);

      submitForm();
    } else if (event.key === 'Enter' && (event.target as HTMLElement).tagName !== 'TEXTAREA') {
      stopEvent(event);
    } else if (event.key === 'x' && ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) && event.shiftKey) {
      stopEvent(event);

      if (clearResponse) {
        // @ts-expect-error ts(2345) FIXME: Argument of type 'ExchangeHistoryAction | undefined' is not assignable to parameter of type 'ExchangeHistoryAction'.
        clearResponse(selectedAction);
      } else if (reusePreviousResponse) {
        reusePreviousResponse();
      }
    } else if (event.key === 'Escape') {
      stopEvent(event);
    }
  };

  const approveAndESign = React.useCallback(
    async () => {
      setIsLoadingApproveAndESign(true);

      await sendExchangeReply({
        // @ts-expect-error ts(18048) FIXME: 'approveAction' is possibly 'undefined'.
        value: approveAction.type,
        ...sanitize(approveAction, exchange, { companyUsers }),
        // @ts-expect-error ts(18048) FIXME: 'approveAction' is possibly 'undefined'.
        ...approveAction.payload,
      });

      await eSign({
        onError: () => setIsLoadingApproveAndESign(false),
      });
    },
    [sendExchangeReply, exchange, companyUsers, approveAction, sanitize, eSign],
  );

  if (!filteredActions.length && !canESign && !canApproveAndESign) {
    return null;
  }

  const isFromVerified = search?.from === 'verified';

  const Container = isExpandedView ? GridQuestionExpandedViewContainer : React.Fragment;

  return (
    <LabelConfigProvider
      // This key property is required to keep the states of the components
      // distinct when switching between exchanges; we're setting the key
      // here instead of higher up the hierarchy because this prevents
      // form errors in the current form from being shown when navigating
      // to another exchange
      key={exchange._id}
      variant={isBulletinExchange ? LabelConfig.FLEX : LabelConfig.LEFT}
      gap={isBulletinExchange ? 0 : undefined}
      style={{ default: { fontSize: '14px' } }}
    >
      {canESign && !isLoadingApproveAndESign && (
        <ResetLabelStyle>
          <FieldContainer
            label={t('action', { ns: 'general' })}
            showAsterisk
            mb={2}
          >
            <Button
              small
              variant="primary-outline"
              iconLeft="pencil"
              onClick={eSign}
              disabled={isLoadingESign || isFromVerified || isESignDisabled}
            >
              {t('signature.eSign', { ns: 'contracts' })}
            </Button>
          </FieldContainer>
        </ResetLabelStyle>
      )}
      {(canApproveAndESign || isLoadingApproveAndESign) && (
        <ResetLabelStyle>
          <FieldContainer
            label={t('action', { ns: 'general' })}
            showAsterisk
            mb={2}
          >
            <Button
              small
              variant="primary-outline"
              iconLeft="pencil"
              onClick={approveAndESign}
              disabled={approveAction?.disabled || isLoadingApproveAndESign}
            >
              {t('signature.approveAndESign', { ns: 'contracts' })}
            </Button>
          </FieldContainer>
        </ResetLabelStyle>
      )}
      {filteredActions.length >= minNumActionsForSelect ? (
        <SelectLevel2Action
          actions={filteredActions}
          setActionIndex={setSelectedActionIndex}
          actionIndex={selectedActionIndex}
          hideLabel={canESign || canApproveAndESign}
        />
      ) : (
        null
      )}
      <Formik
        validateOnBlur
        enableReinitialize
        initialValues={initialValues}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        validationSchema={validationSchema}
        onSubmit={async action => {
          const isPendingReviewQuestionnaire = (
            contextType === ContextType.QUESTIONNAIRE && status === QuestionnaireStatus.PENDING_REVIEW
          );

          const onSubmit = async () => {
            /**
             * We're extending the exchange modal with this (not so pretty) exception because we have a different use-case for bulletins
             * On the buyer-side, we are using the modal to display a bulletin exchange instance.
             * However, the buyer can edit the bulletin from here, which in fact means editing the exchange definition,
             * not just the current instance.
             * This is why we don't want to use the `exchange-reply-sent` on submitting the form.
             */
            if (isBulletinExchange) {
              await updateBulletin(action, {
                onSuccess: onClose,
              });
            } else {
              await sendExchangeReply({
                // @ts-expect-error ts(18048) FIXME: 'selectedAction' is possibly 'undefined'.
                value: selectedAction.type,
                ...sanitize(action, exchange, { companyUsers }),
                // @ts-expect-error ts(18048) FIXME: 'selectedAction' is possibly 'undefined'.
                ...selectedAction.payload,
              });
              onClose();
            }
          };

          // @ts-expect-error ts(18048) FIXME: 'selectedAction' is possibly 'undefined'.
          if (isPendingReviewQuestionnaire && selectedAction.type === ActionType.REQUIRE_MORE_INFO) {
            confirm(onSubmit);
          } else {
            await onSubmit();
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, submitForm, setFieldValue, values, errors }) => {
          const canSubmitForm = (
            !isLoading &&
            !isSubmitting &&
            isValid &&
            selectedAction &&
            !(canOnlySubmitWhenDirty(exchange) && !dirty)
          );

          const showReuseResponseButton = canReusePreviousResponse
            ? isLineItemExchangeDef(exchange.def)
              ? every(values, value => isNil(value) || value === '')
              : isEmptyResponse(
                omit(cloneDeep(values.response), ['value.currencyCode']) as QuestionResponse,
                exchange.def as QuestionExchangeDefinition,
              )
            : false;

          const reusePreviousResponse = () => {
            if (exchange.def.type === ExchangeType.QUESTION) {
              const previousResponse = {
                ...exchange.latestNonEmptyResponse,
                value: (exchange.def as QuestionExchangeDefinition).questionType === QuestionType.DATE_TIME
                  // @ts-expect-error ts(18048) FIXME: 'exchange.latestNonEmptyResponse' is possibly 'undefined'.
                  ? extendedDateTimeFactory.fromRepresentation(exchange.latestNonEmptyResponse.value as SerializedDate)
                  // @ts-expect-error ts(18048) FIXME: 'exchange.latestNonEmptyResponse' is possibly 'undefined'.
                  : exchange.latestNonEmptyResponse.value,
              };
              setFieldValue('response', previousResponse);
              setGridResponseKey(prev => prev + 1);
            } else if (exchange.def.type === ExchangeType.LINE_ITEM) {
              const previousReply = (exchange as LineItemsExchangeSnapshot).latestNonEmptyReply;
              Object.entries(previousReply).map(([fieldName, value]) => setFieldValue(fieldName, value));
            }
          };

          return (
            <Container>
              <Form
                onKeyDown={handleFormKeyDown(
                  canSubmitForm ? submitForm : null,
                  canReusePreviousResponse ? reusePreviousResponse : null,
                )}
              >
                <Stack gap="16px">
                  {/* eslint-disable jsx-a11y/no-autofocus */}
                  <Fields
                    autoFocus
                    exchange={exchange}
                    labelStyle={{ default: { fontSize: '14px' } }}
                    isExpandedView={isExpandedView}
                    setIsExpandedView={setIsExpandedView}
                    gridResponseKey={gridResponseKey}
                    columns={columns}
                  />
                  <Flex justifyContent="space-between">
                    <Flex sx={{ position: 'relative', gap: 1, minWidth: 1 }} flexDirection="column">
                      {clearResponse && (
                        <>
                          <ClearResponseDropdown
                            // @ts-expect-error ts(2345) FIXME: Argument of type 'ExchangeHistoryAction | undefined' is not assignable to parameter of type 'ExchangeHistoryAction'.
                            onConfirm={() => clearResponse(selectedAction)}
                            disabled={isLoading || isSubmitting}
                          />
                          <Flex sx={{ gap: 1 }}>
                            <KeyboardShortcutItem>{isMac ? '⌘' : 'Ctrl'}</KeyboardShortcutItem>
                            +
                            <KeyboardShortcutItem>Shift</KeyboardShortcutItem>
                            +
                            <KeyboardShortcutItem>X</KeyboardShortcutItem>
                          </Flex>
                        </>
                      )}
                      {showReuseResponseButton && (
                        <>
                          <ReusePreviousResponseDropdown
                            onConfirm={reusePreviousResponse}
                          />
                          <Flex sx={{ gap: 1 }}>
                            <KeyboardShortcutItem>{isMac ? '⌘' : 'Ctrl'}</KeyboardShortcutItem>
                            +
                            <KeyboardShortcutItem>Shift</KeyboardShortcutItem>
                            +
                            <KeyboardShortcutItem>X</KeyboardShortcutItem>
                          </Flex>
                        </>
                      )}
                    </Flex>
                    <Flex sx={{ gap: 1 }}>
                      <CancelButton
                        small
                        onClick={onClose}
                        mr={2}
                      />
                      {filteredActions.length > 0 && (
                        <Flex flexDirection="column" sx={{ gap: 1 }}>
                          <Button
                            small
                            type="submit"
                            disabled={!canSubmitForm}
                          >
                            <SubmitButtonLabelText
                              exchangeType={exchange.def.type}
                              // @ts-expect-error ts(2322) FIXME: Type 'ExchangeHistoryAction | undefined' is not assignable to type 'ExchangeHistoryAction'.
                              action={selectedAction}
                              initialValues={initialValues}
                            />
                          </Button>

                          <Flex sx={{ gap: 1 }} alignSelf="flex-end">
                            <KeyboardShortcutItem>{isMac ? '⌘' : 'Ctrl'}</KeyboardShortcutItem>
                            +
                            <KeyboardShortcutItem>Enter</KeyboardShortcutItem>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Stack>
              </Form>
            </Container>
          );
        }}
      </Formik>
      <ConfirmActionDialog
        heading={t('request.dialog.confirmResponse.heading')}
        variant="info"
        message={t('request.dialog.confirmResponse.body')}
        okButtonText={t('request.dialog.confirmResponse.submitResponse')}
        okButtonVariant="primary"
        {...confirmDialogProps}
      />
    </LabelConfigProvider>
  );
};
