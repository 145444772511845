import { documentExchangeTypes } from '../exchangesConfig';
import { ProductTag } from '../products';
import { BidProgress } from '../bidProgress';
import {
  ApproximateTotal,
  Attachment,
  CollaboratorInviteStatus,
  CompanyId,
  CompanyMinimized,
  CurrencyExchangeDefinition,
  DocumentExchangeDefinition,
  ExchangeDefinitionBase,
  ExchangeType,
  LineItemExchangeDefinition,
  Page,
  Section,
  TimeUnit,
  User,
  UserId,
  UserMinimized,
  PageRole,
  ExchangeId,
  ExchangeDefinition,
  SectionType,
  AuditRole,
  DraftApproximateTotal,
} from '../rfq-utils';
import { OffsetConfig } from './contractDates';

// Mocked IDs for simulating the summary and reminders pages in order to implement permissions
export const SUMMARY_PAGE_ID = 'summary';
export const REMINDERS_PAGE_ID = 'reminders';

export type ContractSender = CompanyMinimized & { inviteStatus: CollaboratorInviteStatus };

export type ContractTemplateSender = {
  company: ContractSender,
  users?: User[],
};

export type ContractRecipient = CompanyMinimized & {
  _id: CompanyId | null;
  isExternal?: boolean;
};

export type Contract = {
  meta: {
    creationTimestamp: Date;
    creatorUsername: string;
  };
  _id: string;
  requestId?: string;
  requestSubject?: string;
  fromTemplateId: string | null;
  status: ContractStatus;
  isDeleted: boolean;
  isLegacy?: boolean;
  isAmended?: boolean;
  hasImportedProductsAndServices: boolean;
  senders: ContractSender[];
  recipients: ContractRecipient[];
  calculatedStartDate: string | null;
  calculatedExpiryDate: string | null;
  summary: ContractSummary;
  reminders: Reminder[];
  hasStartingDateReminderBeenSet: boolean;
  hasExpiringDateReminderBeenSet: boolean;
  customMilestones: Milestone[];
  teamById: Record<CompanyId, Team>;
  autoReferenceNumber: number;
  pages: ContractPage[];
  pageById: Record<ContractPage['_id'], ContractPage>;
  sectionById: Record<ContractSection['_id'], ContractSection>;
  exchangeDefById: Record<ContractExchangeDefinition['_id'], ContractExchangeDefinition>;
  exchangeById: Record<string, unknown> // ToDo Actually Record<ExchangeSnapshot['_id'], ExchangeSnapshot>. But circular dependency hell.
  hasImportedExchanges: boolean;
  importedExchangesIdsMap: Record<string, string>; // Request ExchangeId <-> Contract ExchangeId
  recipientRequirementsResolved: boolean;
  allRequirementsResolved: boolean;
  awaitingRecipientSignature: boolean;
  awaitingSenderSignature: boolean;
  approvalByPage: Record<ContractPage['_id'], string>;
  version: number;
  /**
   * Client-only property
   */
  bidProgress?: BidProgress | null;
  /**
   * Client-only property
   */
  bidProgressByPageId?: Record<string, BidProgress> | null;
};

export type ContractPage = Omit<Page, 'isHiddenWhileEditing' | 'isHiddenInSupplierTable'>;

export type ContractSection = Omit<Section, 'docXDefs'> & {
  pageId: string;
  exchangeDefIds: string[];
};

export type Team = {
  users: Record<User['_id'], User & { dateAdded: Date }>;
  owners: User['_id'][];
};

export enum StartDateType {
  EXACT_DATE = 'exactDate',
  TIME_BASED = 'timeBased',
  ON_SIGNATURE = 'onSignature',
}

export enum ExpiryDateType {
  EXACT_DATE = 'exactDate',
  TIME_BASED = 'timeBased',
  NO_EXPIRY_DATE = 'noExpiryDate',
}

export type Milestone = {
  _id: string;
  name: string;
  date: Date;
  isHidden: boolean;
};

export enum ReminderType {
  MILESTONE = 'milestone',
  EXACT_DATE = 'exactDate',
}

export enum OffsetType {
  BEFORE = 'before',
  ON = 'on',
  AFTER = 'after',
}

export enum ReminderRecipientCategory {
  COUNTER_PARTY = 'counterParty',
  INTERNAL = 'internal',
}

type ReminderBase = {
  _id: string;
  name: string;
  note: string;
  recipientCategories: ReminderRecipientCategory[];
  recipientUserIds?: UserId[]; // Array of internal (ie sender) user IDs
  allSenderUsers?: boolean; // Means when the reminder was added/updated, the selected recipients were all the sender users
};

export type MilestoneReminder = ReminderBase & {
  type: ReminderType.MILESTONE;
  milestoneId: string;
  offsetType: OffsetType;
  amount?: number; // positive
  unit?: TimeUnit;
};

export type ExactDateReminder = ReminderBase & {
  type: ReminderType.EXACT_DATE;
  date: string;
  isRecurring: boolean;
  amount?: number;
  unit?: TimeUnit;
};

export type Reminder = MilestoneReminder | ExactDateReminder;

export const isMilestoneReminder = (reminder: Reminder): reminder is MilestoneReminder =>
  reminder.type === ReminderType.MILESTONE;

export const isExactDateReminder = (reminder: Reminder): reminder is ExactDateReminder =>
  reminder.type === ReminderType.EXACT_DATE;

export type DraftContractSummary = Omit<ContractSummary, 'spendData'> & {
  spendData: DraftApproximateTotal;
};

export type ContractSummary = {
  name: string;
  description: string;
  startDateType: StartDateType;
  // a config is defined only when startDateType is TIME_BASED
  startDateOffsetConfig: OffsetConfig | null;
  // time zone of the user who sets the time based start date config
  // we need to store this in order to accurately calculate the start date as the start of day for that specific time zone
  startDateTz: string | null;
  startDate: string | null;
  expiryDateType: ExpiryDateType;
  // a config is defined only when expiryDateType is TIME_BASED
  expiryDateOffsetConfig: OffsetConfig | null;
  // time zone of the user who sets the time based expiry date config
  // we need to store this in order to accurately calculate the expiry date as the end of day for that specific time zone
  expiryDateTz: string | null;
  expiryDate: string | null;
  /**
   * @deprecated
   * Replaced by setting `expiryDateType` to `noExpiryDate`
   */
  noExpiryDate?: boolean;
  spendData: ApproximateTotal;
  reference: string;
  /**
   * The product and service tags added to the contract by a sender.
   */
  productsAndServices: ProductTag[];
};

export enum ContractProvidedBy {
  BUYER = 'buyer',
  SUPPLIER = 'supplier',
}

export enum ContractSignatureType {
  MANUAL = 'manual',
  VERIFIED = 'verified', // E-signature flow using Verified.eu
}

// naming kind of sucks
export type ContractDocumentExchangeDefinition = {
  type: ExchangeType.CONTRACT;
  description: string;
  attachments: Attachment[];
  isAddendum?: boolean;
  // only present if `isAddendum` is true; refers to the contract that the addendum is attached to
  primaryContractId?: ExchangeId;
  providedBy: ContractProvidedBy;
  signatureType: ContractSignatureType;
  senderSigners?: UserMinimized[]; // For e-signature
  requireApprovalBeforeCountersigning?: boolean; // For e-signature
} & ExchangeDefinitionBase;

export type LegacyContractExchangeDefinition = {
  type: ExchangeType.LEGACY_CONTRACT;
  description: string;
  attachments: Attachment[];
} & ExchangeDefinitionBase;

export type ApprovalExchangeDefinition = {
  type: ExchangeType.APPROVAL;
} & ExchangeDefinitionBase;

export type ContractExchangeDefinition =
  | ContractDocumentExchangeDefinition
  | DocumentExchangeDefinition
  | LineItemExchangeDefinition
  | CurrencyExchangeDefinition
  | ApprovalExchangeDefinition
  | LegacyContractExchangeDefinition;

export const contractExchangeDefinitionTypes = [
  ExchangeType.CONTRACT,

  ExchangeType.COMPLETE_OR_SIGN,
  ExchangeType.COMPLETE_OR_SIGN_CLOSED,
  ExchangeType.COMPLETE_OR_SIGN_LOCKED,
  ExchangeType.ACCEPT_CLOSED,
  ExchangeType.ACCEPT,
  ExchangeType.DOCUMENT_REQUEST,
  ExchangeType.DOCUMENT_REQUEST_CLOSED,
  ExchangeType.DOCUMENT_REQUEST_LOCKED,
  ExchangeType.INFORMATION,

  ExchangeType.LINE_ITEM,
  ExchangeType.CURRENCY,
] as const satisfies readonly ContractExchangeDefinition['type'][];

export function isDocumentExchangeDefinition(definition: ExchangeDefinitionBase): definition is DocumentExchangeDefinition {
  return documentExchangeTypes.includes(definition.type);
}

export const isCurrencyExchangeDef = (exchangeDef: ContractExchangeDefinition): exchangeDef is CurrencyExchangeDefinition =>
  exchangeDef.type === ExchangeType.CURRENCY;

export const isApprovalExchangeDef = (exchangeDef: ContractExchangeDefinition): exchangeDef is ApprovalExchangeDefinition =>
  exchangeDef.type === ExchangeType.APPROVAL;

export enum ContractStatus {
  DRAFT = 'draft',
  NEGOTIATION = 'negotiation',
  AGREED = 'agreed',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  FAILED = 'failed',
  TERMINATED = 'terminated',
  DELETED = 'deleted',
}

export const LIVE_SENT_CONTRACT_STATUSES = [
  ContractStatus.NEGOTIATION,
  ContractStatus.AGREED,
  ContractStatus.ACTIVE,
  ContractStatus.EXPIRED,
  ContractStatus.FAILED,
  ContractStatus.TERMINATED,
];

interface ContractOverviewMeta {
  numRevisions: number;
  createdBy: UserMinimized;
  createdAt: Date;
  issueDate: Date | null;
  issuedBy: UserMinimized | null;
  lastEdited: {
    date: Date;
    user: UserMinimized;
  };
}

export interface ContractOverviewDetails {
  name: string;
  status: ContractStatus;
  expiryDateType: ExpiryDateType;
  expiryDateOffsetConfig: OffsetConfig | null;
  expiryDate: Date;
  isLegacy?: boolean;
  startDateType: StartDateType;
  startDateOffsetConfig: OffsetConfig | null;
  startDate: Date;
  reference: string;
  autoReferenceNumber: number;
  requestId?: string;
  requestSubject?: string;
  hasBeenIssued: boolean;
  sendersNames: string[];
  spendData: ApproximateTotal;
}

export type ContractOverviewRecipient = {
  _id: string;
  name: string;
  userIds: string[];
  ownerIds: string[];
  isPending: boolean;
  isExternal?: boolean;
};

export type UpcomingReminder = {
  reminderId: string;
  date: Date;
};

export type DashboardRole = 'owner' | 'teamMember' | 'none' | 'requestCreator';

export interface ContractOverview extends ContractOverviewDetails {
  meta: ContractOverviewMeta;
  triggeredSystemEvents: ContractTriggeredSystemEvents;
  recipient: ContractOverviewRecipient;

  senders: {
    _id: string;
    name: string;
    userIds: string[];
    ownerIds: string[];
    inviteStatus: CollaboratorInviteStatus;
  }[];

  upcomingReminders: UpcomingReminder[];
  senderIds: string[];
  pendingESignatureEnvelopeId: string | null;
  firstContractSignDate: Date | null;
}

export type SentContractOverview = ContractOverview & {
  hasAccess: boolean;
  isOwner: boolean;
  role: DashboardRole;
};

/**
 * A {@link SentContractOverview} that also includes the names of the
 * contract owners, as required on the Contracts page.
 */
export type ExtendedSentContractOverview = SentContractOverview & {
  ownerNamesOrEmails: string[];
};

export type SentContractOverviews = {
  totalItems: number;
  companyHasNoLiveContracts?: boolean;
  companyHasNoDraftContracts?: boolean;
  pageIndex: number;
  overviews: ExtendedSentContractOverview[];
};

export type ReceivedContractOverview = ContractOverviewDetails & {
  _id?: string;
  hasAccess: boolean;
  role: DashboardRole;
};

/**
 * A {@link ReceivedContractOverview} that also includes the names of the
 * request owners / team members, as required on the Contracts page.
 */
export type ExtendedReceivedContractOverview = ReceivedContractOverview & {
  ownerNamesOrEmails?: string[];
  teamMemberNamesOrEmails?: string[];
};

export type ReceivedContractOverviews = {
  totalItems: number;
  pageIndex: number;
  overviews: ExtendedReceivedContractOverview[];
};

export interface ContractTriggeredSystemEvents {
  contractStarted?: Date;
  contractExpired?: Date;
  approvalRequiredForEnvelopeIds?: string[]; // Verified envelope IDs
  counterSignatureRequiredForEnvelopeIds?: string[]; // Verified envelope IDs
  supplierSigningCompleteForEnvelopeIds?: string[]; // Verified envelope IDs
}

export type ImportLegacyContractPayload = {
  name: string;
  description?: string;
  startDate: Date;
  expiryDate?: Date;
  spendData: ApproximateTotal;
  reference?: string;
  // External counterparty
  counterpartyName?: string | null;
  // Internal (DS) counterparty id
  counterpartyId?: string | null;
  contractOwnerEmail: string;
};

export type ContractRoles = Record<string, PageRole>;

// Only fields that needs BE validation
export type ImportLegacyContractValidationPayload = Pick<ImportLegacyContractPayload, 'counterpartyId' | 'contractOwnerEmail'>;

export type ImportLegacyContractError = Partial<{ [K in keyof ImportLegacyContractPayload]: string }>;

// Placeholder ID used for the "Start date" milestone
export const START_DATE_MILESTONE = '___start_date_milestone___';
// Placeholder ID used for the "Expiry date" milestone
export const EXPIRY_DATE_MILESTONE = '___expiry_date_milestone___';

// Placeholder ID used for automatically generated "Contract starting" reminder
export const CONTRACT_STARTING_REMINDER_ID = '___contract_starting_reminder___';
// Placeholder ID used for automatically generated "Contract expiring" reminder
export const CONTRACT_EXPIRING_REMINDER_ID = '___contract_expiring_reminder___';

type ReportingContractStatus = ContractStatus.ACTIVE | ContractStatus.AGREED | ContractStatus.NEGOTIATION;

export type ContractReporting = {
  currency: string;
  numContractsByProductAndServiceCode: Record<string, number>;
  numContractsByStatus: Record<ReportingContractStatus, number>;
  productsAndServices: Record<string, ProductTag>;
  contractValueByStatus: Record<ReportingContractStatus, number>;
  contractValueByProductAndServiceCode: Record<string, number>;
  numContractsWithProductsAndServices: number;
  contractsValueWithProductsAndServices: number;
  counterpartiesStats: {
    _id: string;
    name: string;
    numAgreedContracts: number;
    numActiveContracts: number;
    numNegotiatingContracts: number;
    numTotalContracts: number;
    agreedContractsValue: number;
    activeContractsValue: number;
    negotiatingContractsValue: number;
    totalContractsValue: number;
  }[];
  usersStats: {
    _id: string;
    name: string;
    jobTitle: string;
    numAgreedContracts: number;
    numActiveContracts: number;
    numNegotiatingContracts: number;
    numTotalContracts: number;
    agreedContractsValue: number;
    activeContractsValue: number;
    negotiatingContractsValue: number;
    totalContractsValue: number;
  }[];
  expiringContracts: {
    _id: string;
    name: string;
    expiryDate: Date;
    counterpartyId: string;
    counterpartyName: string;
    owners: {
      _id: string;
      email: string;
      name: string;
    }[];
  }[];
};

type ContractHistoryEventBase = {
  meta: {
    user?: {
      _id: string;
      name: string;
    }
    company?: {
      _id: string;
      name: string;
    }
    role?: AuditRole;
    timestamp: Date;
  },
  hiddenFields?: string[];
};

export type WithRequired<T, K extends keyof T> = Partial<T> & { [P in K]-?: T[P] };

export type ReminderAuditFields = Omit<
  Reminder, 'type' | 'allSenderUsers'
> & {
  recipientUserNames: string[];
} & ({
  reminderType: ReminderType.EXACT_DATE;
  occurrence?: {
    amount?: Reminder['amount'];
    date?: Date;
    isRecurring?: boolean;
    unit?: Reminder['unit'];
  }
} | {
  reminderType: ReminderType.MILESTONE;
  occurrence?: {
    unit?: Reminder['unit'];
    amount?: Reminder['amount'];
    offsetType?: MilestoneReminder['offsetType'];
    milestoneName?: string;
    milestoneDate?: Date;
  }
});

export type ReminderEditedAuditFields = {
  _id: Reminder['_id'];
  name?: Reminder['name'];
  reminderType?: ReminderType.MILESTONE | ReminderType.EXACT_DATE;
  previousReminderFields: Partial<Omit<ReminderAuditFields, '_id'>>;
  updatedReminderFields: Partial<Omit<ReminderAuditFields, '_id'>>;
};

export type ContractHistoryEvent = ContractHistoryEventBase & (
  | {
    type: 'cannot-view' // Placeholder event for when a user doesn't have permission to read an event
  }
  | {
    type: 'contract.published';
    fields: {
      userName: string;
      userId: string;
      counterpartyName: string;
      counterpartyId: string;
    }
  }
  | {
    type: 'legacy-contract.published'; // Not actual machine event type (branched from `contract.published` based on `isLegacy` payload)
  }
  | {
    type: 'contract.amended'; // Not actual machine event type (branched from `contract.published` based on `isAmendment` payload)
    fields: {
      message: string;
    }
  }
  | {
    type: 'contract.revised'; // Not actual machine event type (branched from `contract.published` based on `isRevision` payload)
    fields: {
      message: string;
    }
  }
  | {
    type: 'contract.terminated';
    fields: {
      message: string;
      previousStatus: ContractStatus;
    }
  }
  | {
    type: 'contract.failed';
    fields: {
      message: string;
    }
  }
  | {
    type: 'start-date.calculated';
    fields: {
      startDate: Date;
      startDateType: StartDateType;
      startDateOffsetConfig: OffsetConfig;
    }
  }
  | {
    type: 'expiry-date.calculated';
    fields: {
      expiryDate: Date;
      expiryDateType: ExpiryDateType;
      expiryDateOffsetConfig: OffsetConfig;
    }
  }
  | {
    type: 'exchange-reply.sent';
    fields: {
      exchangeType: ExchangeType;
      sectionName: string;
      sectionType: SectionType;
      description?: string;
      exchangeId: string;
      comment?: string;
      action: string;
      previousAction?: string;
      filename?: string;
      currency?: string;
      previousFieldResponse?: string;
      updatedFieldResponse?: string;
    }
  }
  | {
    type: 'reminder.created'; // Not actual machine event type (branched from `reminders.updated` based on state changes)
    fields: WithRequired<ReminderAuditFields, '_id' | 'name' | 'reminderType'>;
  }
  | {
    type: 'reminder.deleted'; // Not actual machine event type (branched from `reminders.updated` based on state changes)
    fields: WithRequired<ReminderAuditFields, '_id' | 'name' | 'reminderType'>;
  }
  | {
    type: 'reminder.edited'; // Not actual machine event type (branched from `reminders.updated` based on state changes)
    fields: ReminderEditedAuditFields;
  }
  | {
    type: 'verified.recipient.signed';
    fields: {
      exchangeType: ExchangeType;
      description: string;
      action: string;
    }
  }
  | {
    type: 'verified.recipient.rejected';
    fields: {
      exchangeType: ExchangeType;
      description: string;
      action: string;
      comment: string;
    }
  }
);

export const isContractExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is ContractDocumentExchangeDefinition =>
  exchangeDef.type === ExchangeType.CONTRACT;

export const isLegacyContractExchangeDef = (exchangeDef: ExchangeDefinition): exchangeDef is LegacyContractExchangeDefinition =>
  exchangeDef.type === ExchangeType.LEGACY_CONTRACT;

export type ContractVerifiedSigner = {
  _id: string;
  name: string;
  hasSigned: boolean;
};

export type ContractExchangeVerifiedData = {
  envelopeId?: string;
  senderSigners?: ContractVerifiedSigner[];
  recipientSigners?: ContractVerifiedSigner[];
} | null;
