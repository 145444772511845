import { IconValue } from '@deepstream/common';
import { Button, ButtonProps } from './Button';

export const RoundIconButton = ({
  icon,
  ...props
}: Omit<ButtonProps, 'iconLeft' | 'iconRight'> & {
  icon: IconValue;
}) => (
  <Button
    type="button"
    borderRadius={15}
    variant="secondary-outline"
    fontSize="10px"
    sx={{ height: '30px', width: '30px' }}
    iconLeft={icon}
    {...props}
  />
);
