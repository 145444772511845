import * as React from 'react';
import ReactDOM from 'react-dom';
import { isValid } from 'date-fns';
import { isEmpty, isNil, isNumber } from 'lodash';
import { Box, Flex, Text } from 'rebass/styled-components';
import {
  ActiveStageFilterOperator,
  FinalDeadlineFilterOperator,
} from '@deepstream/common';
import { RoundIconButton } from '@deepstream/ui-kit/elements/button/RoundIconButton';
import { DropdownMenu, DropdownMenuItem } from '@deepstream/ui-kit/elements/menu/DropdownMenu';
import {
  ActiveStageLabeledFilter,
  ACTIVE_STAGE_DEFAULT_OPERATOR,
  ACTIVE_STAGE_DEFAULT_STAGE_NUMBER,
  FilterProps,
  FinalDeadlineLabeledFilter,
  FINAL_DEADLINE_DEFAULT_OPERATOR,
} from '../filtering';
import { Input, InputPrefix, InputProps, InputSuffix } from './Input';
import { DatePicker, GlobalDatePickerStyles } from './DatePicker';
import { FormGroup } from './FormGroup';

const ComparisonValueContext = React.createContext(null);

const FilterNumberInput = ({
  minValue,
  maxValue,
  prefix,
  suffix,
  showStepper,
  format = 'integer.positive',
}: {
  minValue: number;
  maxValue?: number;
  prefix?: string;
  suffix?: string;
  showStepper?: boolean;
  format?: InputProps['format'];
}) => {
  // @ts-expect-error ts(2339) FIXME: Property 'initialValue' does not exist on type 'null'.
  const { initialValue, onValueChange } = React.useContext(
    ComparisonValueContext,
  );
  const [value, setValue] = React.useState<number | null>(initialValue);

  const handleValueChange = React.useCallback(
    (newValue) => {
      if (isNumber(newValue) && newValue >= minValue && (!maxValue || newValue <= maxValue)) {
        onValueChange(newValue);
        setValue(newValue);
      } else {
        setValue(null);
      }
    },
    [onValueChange, minValue, maxValue],
  );

  const onBlur = React.useCallback(() => {
    if (isNil(value)) {
      // On blur, reset the value to the initial value if the input is empty
      setValue(initialValue);
    }
  }, [value, initialValue]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{ columnGap: 2, width: '100%' }}
    >
      {showStepper && (
        <RoundIconButton
          icon="minus"
          disabled={value === minValue}
          // @ts-expect-error ts(18047) FIXME: 'value' is possibly 'null'.
          onClick={() => handleValueChange(value - 1)}
        />
      )}
      <FormGroup>
        {prefix && (
          <InputPrefix>{prefix}</InputPrefix>
        )}
        <Input
          type="text"
          format={format}
          maxValue={maxValue}
          value={isNil(value) ? undefined : value}
          onBlur={onBlur}
          onValueChange={handleValueChange}
          style={showStepper ? { textAlign: 'center', width: 52 } : undefined}
        />
        {suffix && (
          <InputSuffix>{suffix}</InputSuffix>
        )}
      </FormGroup>
      {showStepper && (
        <RoundIconButton
          icon="plus"
          disabled={Boolean(maxValue) && value === maxValue}
          // @ts-expect-error ts(18047) FIXME: 'value' is possibly 'null'.
          onClick={() => handleValueChange(value + 1)}
        />
      )}
    </Flex>
  );
};

const DATEPICKER_PORTAL_ID = 'datepicker-portal';
const FilterDatepicker = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'initialValue' does not exist on type 'null'.
  const { initialValue, onValueChange } = React.useContext(
    ComparisonValueContext,
  );

  const [value, setValue] = React.useState<Date>(initialValue);

  const handleValueChange = React.useCallback(
    (newValue) => {
      if (isValid(newValue)) {
        setValue(newValue);
        onValueChange(newValue);
      }
    },
    [onValueChange],
  );

  const onBlur = React.useCallback(
    (event) => {
      const newValue = new Date(event?.target?.value);
      if (!isValid(newValue)) {
        setValue(initialValue);
      }
    },
    [initialValue],
  );

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      sx={{ columnGap: 2, width: '100%' }}
    >
      {ReactDOM.createPortal(
        <Box
          bg="transparent"
          sx={{
            maxHeight: 'calc(100% - 96px)',
            boxShadow: 'large',
            borderRadius: 'small',
            zIndex: 20,
          }}
          id={DATEPICKER_PORTAL_ID}
          // The following two props are required to prevent
          // the parent filter popover to close the date picker
          // inside the onClickOutside handler
          data-popper-placement="true"
          role="dialog"
        />,
        document.body!,
        'datepicker-popover',
      )}
      <Box sx={{ zIndex: 20 }}>
        <DatePicker
          portalId={DATEPICKER_PORTAL_ID}
          value={value}
          popperPlacement="bottom-left"
          dateFormat="dd MMM yyyy"
          onChange={handleValueChange}
          onBlur={onBlur}
        />
      </Box>
    </Flex>
  );
};

type ComparisonFilterProps<
  T extends ActiveStageLabeledFilter | FinalDeadlineLabeledFilter,
> = Pick<
  FilterProps<T>,
  'items' | 'selectedItems' | 'onChange' | 'renderItem'
> & {
  filteredValueKeyName: string;
  children: React.ReactElement | React.ReactElement[];
  selectOperatorMinWidth?: string;
};

const ComparisonFilter = <
  T extends ActiveStageLabeledFilter | FinalDeadlineLabeledFilter,
>({
  items,
  selectedItems,
  onChange,
  renderItem,
  filteredValueKeyName,
  children,
  selectOperatorMinWidth = '150px',
}: ComparisonFilterProps<T>) => {
  const currentSelection = selectedItems[0];

  return (
    <Flex sx={{ columnGap: 3, padding: '8px 12px' }}>
      <DropdownMenu
        buttonText={
          <Text
            sx={{
              flex: 1,
              textAlign: 'start',
              fontWeight: 400,
              minWidth: selectOperatorMinWidth,
            }}
          >
            {/*
             // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'. */}
            {renderItem(currentSelection)}
          </Text>
        }
        variant="secondary-outline"
        iconRight="caret-down"
        wrapperStyle={{
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          flex: 1,
        }}
        menuZIndex={161}
        stopClickEvents
        sx={{ flex: 1 }}
      >
        {items.map((item, i) => (
          <React.Fragment key={item.label}>
            <DropdownMenuItem
              onSelect={() => {
                onChange([
                  {
                    ...item,
                    [filteredValueKeyName]:
                      currentSelection[filteredValueKeyName],
                  },
                ]);
              }}
            >
              {item.label}
            </DropdownMenuItem>
          </React.Fragment>
        ))}
      </DropdownMenu>
      <Flex sx={{ flex: 1 }}>{children}</Flex>
    </Flex>
  );
};

// TODO possible to replace with OperatorAndNumberValueFilter
export const ActiveStageFilter = ({
  items,
  selectedItems,
  onChange,
  renderItem,
}: Omit<
  ComparisonFilterProps<ActiveStageLabeledFilter>,
  'children' | 'filteredValueKeyName'
>) => {
  React.useEffect(() => {
    if (isEmpty(selectedItems)) {
      // Auto-select the default value every time the user opens the filter
      const defaultOperator = items.find(
        (item) => item.operator === ACTIVE_STAGE_DEFAULT_OPERATOR,
      );
      onChange([
        // @ts-expect-error ts(2322) FIXME: Type '{ stageNumber: number; operator?: "lt" | "lte" | "eq" | "gte" | "gt" | "ne" | "final_stage" | undefined; label?: string | undefined; }' is not assignable to type 'ActiveStageLabeledFilter'.
        { ...defaultOperator, stageNumber: ACTIVE_STAGE_DEFAULT_STAGE_NUMBER },
      ]);
    }
  }, [selectedItems, onChange, items]);

  const currentSelection = selectedItems[0];
  const contextValue = React.useMemo(() => {
    return {
      initialValue:
        currentSelection?.stageNumber ?? ACTIVE_STAGE_DEFAULT_STAGE_NUMBER,
      onValueChange: (newValue) => {
        if (currentSelection) {
          onChange([{ ...currentSelection, stageNumber: newValue }]);
        }
      },
    };
  }, [onChange, currentSelection]);

  if (isEmpty(selectedItems)) {
    return null;
  }

  return (
    <ComparisonFilter
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      renderItem={renderItem}
      filteredValueKeyName="stageNumber"
    >
      {/*
       // @ts-expect-error ts(2322) FIXME: Type 'undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'. */}
      <ComparisonValueContext.Provider value={contextValue}>
        {currentSelection?.operator !==
          ActiveStageFilterOperator.FINAL_STAGE && <FilterNumberInput minValue={1} />}
      </ComparisonValueContext.Provider>
    </ComparisonFilter>
  );
};

// TODO possible to replace with OperatorAndDateValueFilter
export const FinalDeadlineFilter = ({
  items,
  selectedItems,
  onChange,
  renderItem,
}: Omit<
  ComparisonFilterProps<FinalDeadlineLabeledFilter>,
  'children' | 'filteredValueKeyName'
>) => {
  React.useEffect(() => {
    if (isEmpty(selectedItems)) {
      // Auto-select the default value (default operator + current date) every time the user opens the filter
      const defaultOperator = items.find(
        (item) => item.operator === FINAL_DEADLINE_DEFAULT_OPERATOR,
      );
      // @ts-expect-error ts(2322) FIXME: Type '{ deadline: Date; operator?: "lt" | "lte" | "eq" | "gte" | "gt" | "ne" | "has_passed" | "has_not_passed" | undefined; label?: string | undefined; }' is not assignable to type 'FinalDeadlineLabeledFilter'.
      onChange([{ ...defaultOperator, deadline: new Date() }]);
    }
  }, [selectedItems, onChange, items]);

  const currentSelection = selectedItems[0];
  const contextValue = React.useMemo(() => {
    return {
      // @ts-expect-error ts(2769) FIXME: No overload matches this call.
      initialValue: isValid(new Date(currentSelection?.deadline))
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        ? new Date(currentSelection?.deadline)
        : new Date(),
      onValueChange: (newValue) => {
        if (currentSelection) {
          onChange([{ ...currentSelection, deadline: newValue }]);
        }
      },
    };
  }, [onChange, currentSelection]);

  if (isEmpty(selectedItems)) {
    return null;
  }

  const requiresDateValue =
    currentSelection?.operator !== FinalDeadlineFilterOperator.HAS_PASSED &&
    currentSelection?.operator !== FinalDeadlineFilterOperator.HAS_NOT_PASSED;

  return (
    <ComparisonFilter
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      renderItem={renderItem}
      filteredValueKeyName="deadline"
    >
      {/*
       // @ts-expect-error ts(2322) FIXME: Type 'undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'. */}
      <ComparisonValueContext.Provider value={contextValue}>
        {requiresDateValue && (
          <>
            <GlobalDatePickerStyles />
            <FilterDatepicker />
          </>
        )}
      </ComparisonValueContext.Provider>
    </ComparisonFilter>
  );
};

export type OperatorAndNumberValueProps = {
  defaultValue: number;
  minValue: number;
  maxValue?: number;
  valueLessOperators?: string[];
  selectOperatorMinWidth?: string;
  valueFormat?: InputProps['format'];
  valuePrefix?: string;
  valueSuffix?: string;
  showStepper?: boolean;
};

export const OperatorAndNumberValueFilter = ({
  items,
  selectedItems,
  onChange,
  renderItem,
  defaultValue,
  minValue,
  maxValue,
  valueLessOperators,
  selectOperatorMinWidth,
  valueFormat,
  valuePrefix,
  valueSuffix,
  showStepper = true,
}: Omit<
  ComparisonFilterProps<{ label: string; value: number | null; operator: any }>,
  'children' | 'filteredValueKeyName'
> & OperatorAndNumberValueProps) => {
  React.useEffect(() => {
    if (isEmpty(selectedItems)) {
      // Auto-select the default value every time the user opens the filter
      const defaultOperator = items[0];

      onChange([
        { ...defaultOperator, value: defaultValue },
      ]);
    }
  }, [selectedItems, onChange, items, defaultValue]);

  const currentSelection = selectedItems[0];
  const contextValue = React.useMemo(() => {
    return {
      initialValue: isNil(currentSelection?.value)
        ? defaultValue
        : currentSelection.value,
      onValueChange: (newValue) => {
        if (currentSelection) {
          onChange([{ ...currentSelection, value: newValue }]);
        }
      },
    };
  }, [currentSelection, defaultValue, onChange]);

  if (isEmpty(selectedItems)) {
    return null;
  }

  return (
    <ComparisonFilter
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      renderItem={renderItem}
      filteredValueKeyName="value"
      selectOperatorMinWidth={selectOperatorMinWidth}
    >
      {/*
       // @ts-expect-error ts(2322) FIXME: Type 'undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'. */}
      <ComparisonValueContext.Provider value={contextValue}>
        {!valueLessOperators || !currentSelection?.operator || !valueLessOperators.includes(currentSelection.operator) ? (
          <FilterNumberInput
            minValue={minValue}
            maxValue={maxValue}
            prefix={valuePrefix}
            suffix={valueSuffix}
            format={valueFormat}
            showStepper={showStepper}
          />
        ) : (
          null
        )}
      </ComparisonValueContext.Provider>
    </ComparisonFilter>
  );
};

export const OperatorAndDateValueFilter = ({
  items,
  selectedItems,
  onChange,
  renderItem,
}: Omit<
  ComparisonFilterProps<{ label: string; value: number | null; operator: any }>,
  'children' | 'filteredValueKeyName'
>) => {
  React.useEffect(() => {
    if (isEmpty(selectedItems)) {
      // Auto-select the default value (default operator + current date) every time the user opens the filter
      const defaultOperator = items[0];
      // @ts-expect-error ts(2322) FIXME: Type 'Date' is not assignable to type 'number'.
      onChange([{ ...defaultOperator, value: new Date() }]);
    }
  }, [selectedItems, onChange, items]);

  const currentSelection = selectedItems[0];
  const contextValue = React.useMemo(() => {
    return {
      // @ts-expect-error ts(2769) FIXME: No overload matches this call.
      initialValue: isValid(new Date(currentSelection?.value))
        // @ts-expect-error ts(2769) FIXME: No overload matches this call.
        ? new Date(currentSelection?.value)
        : new Date(),
      onValueChange: (newValue) => {
        if (currentSelection) {
          onChange([{ ...currentSelection, value: newValue }]);
        }
      },
    };
  }, [onChange, currentSelection]);

  if (isEmpty(selectedItems)) {
    return null;
  }

  const requiresDateValue =
    currentSelection?.operator !== FinalDeadlineFilterOperator.HAS_PASSED &&
    currentSelection?.operator !== FinalDeadlineFilterOperator.HAS_NOT_PASSED;

  return (
    <ComparisonFilter
      items={items}
      selectedItems={selectedItems}
      onChange={onChange}
      renderItem={renderItem}
      filteredValueKeyName="value"
    >
      {/*
       // @ts-expect-error ts(2322) FIXME: Type 'undefined' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'. */}
      <ComparisonValueContext.Provider value={contextValue}>
        {requiresDateValue && (
          <>
            <GlobalDatePickerStyles />
            <FilterDatepicker />
          </>
        )}
      </ComparisonValueContext.Provider>
    </ComparisonFilter>
  );
};
