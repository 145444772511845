import { filter, findLast, first, flatMap, get, last, reject } from 'lodash';
import { ExchangeConfig, exchangesConfig, Field } from '../exchangesConfig';
import { RfqQuery } from './rfq-query';
import { RfqRecipient } from './rfq-recipient';
import {
  Action,
  ActionType,
  Attachment,
  CurrencyExchangeDefinition,
  ExchangeDefinition,
  ExchangeType,
  FeesExchangeDefinition,
  HirePeriodExchangeDefinition,
  History,
  InclusionsExchangeDefinition,
  LockType,
  TermsExchangeDefinition,
} from './types';

const { NONE, UNLOCK, SUBMIT, REVISE, OBSOLETE_ACTION, OBSOLETE_EXCHANGE } = ActionType;

export type Exchange<T extends ExchangeType> =
  T extends ExchangeType.INCLUSIONS ? RfqExchange<InclusionsExchangeDefinition> :
  T extends ExchangeType.HIRE_PERIOD ? RfqExchange<HirePeriodExchangeDefinition> :
  T extends ExchangeType.FEES ? RfqExchange<FeesExchangeDefinition> :
  T extends ExchangeType.CURRENCY ? RfqExchange<CurrencyExchangeDefinition> :
  T extends ExchangeType.TERMS ? RfqExchange<TermsExchangeDefinition> :
  never;

export class RfqExchange<T extends ExchangeDefinition = ExchangeDefinition> {
  def: T;
  rfq: RfqQuery;
  recipient: RfqRecipient;
  history: History;
  pendingAction: Action;
  sectionId: string;

  constructor(rfqQuery: RfqQuery, recipient: RfqRecipient, sectionId: string, def: T, history: Action[], pendingAction: Action) {
    this.rfq = rfqQuery;
    this.recipient = recipient;

    this.def = def;
    this.history = this.sanitizeHistory(history);
    this.pendingAction = pendingAction;
    this.sectionId = sectionId;
  }

  get _id(): string { return this.def._id; }
  get type(): ExchangeType { return this.def.type; }

  get originalExchangeDef(): ExchangeDefinition {
    let def = this.def as any;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (def.linkedExchangeDefId) {
        def = this.rfq.getExchangeDef(def.linkedExchangeDefId);
      } else {
        return def;
      }
    }
  }

  get providedName(): string {
    const { originalExchangeDef } = this;
    const config = exchangesConfig[originalExchangeDef.type];
    const nameField = config?.fields.find(field => field.isDisplayName);

    return nameField ? originalExchangeDef[nameField?.key] : undefined;
  }

  get name(): string {
    const nameField = this.fields.find(field => field.isDisplayName);

    return nameField
      ? this.def[nameField.key]
      : this.config.label;
  }

  private get config(): ExchangeConfig {
    return exchangesConfig[this.type];
  }

  get fields(): Field[] {
    return this.config.fields;
  }

  get bidSection() {
    return this.recipient.getBidSection(this.sectionId);
  }

  get label() { return this.config.label; }
  get hasLock() { return this.config.hasLock; }

  get buyerId() { return this.rfq.buyerId; }
  get supplierId() { return this.recipient._id; }

  get actions(): Array<Action> {
    return reject(this.history, action => [NONE, UNLOCK].includes(action.value));
  }

  get comments(): Array<Action> {
    return filter(this.history, 'comment');
  }

  get latestAction(): Action {
    const lastAction = last(this.actions);

    return lastAction as Action;
  }

  get isObsolete(): boolean { return this.latestAction.value === OBSOLETE_EXCHANGE; }

  get lock(): ExchangeDefinition['locking'] | null {
    return get(this.def, 'locking', null);
  }

  get lockType(): LockType | null {
    return get(this.lock, 'type', null);
  }

  get hasBeenUnlocked() {
    return Boolean(findLast(this.history, { value: UNLOCK }));
  }

  get isLocked() {
    if (!this.hasLock) return false;

    switch (this.lockType) {
      case LockType.BID_DEADLINE:
        return !this.rfq.isPastBidDeadline;
      case LockType.STAGE_DEADLINE:
        return !this.rfq.isPastCompletionDeadline(first(this.def.stages)!);
      case LockType.TEAM_MEMBER_AFTER_BID_DEADLINE:
        return !this.rfq.isPastBidDeadline || !this.hasBeenUnlocked;
      case LockType.TEAM_MEMBER_AFTER_STAGE_DEADLINE:
        return !this.rfq.isPastCompletionDeadline(first(this.def.stages)!) || !this.hasBeenUnlocked;
      case LockType.TEAM_MEMBER:
        return !this.hasBeenUnlocked;
    }
  }

  private get initiatorId(): string {
    return this.history
      ? this.actions[0].companyId
      : this.pendingAction.companyId;
  }

  get wasInitiatedBySenders(): boolean {
    return this.rfq.isSender(this.initiatorId);
  }

  get attachments(): Attachment[] {
    const history = this.history.concat(this.pendingAction || []);

    return flatMap(history, action => this.getAttachments(action));
  }

  get initialAttachment() {
    return first(this.attachments);
  }

  get latestAttachment() {
    return last(this.attachments);
  }

  /* End - Field Based Exchange Functionality  ------- */

  get isChat() {
    return this.type === ExchangeType.CHAT || this.type === ExchangeType.CHAT_NO_RECEIVER_UPLOAD ||
      this.type === ExchangeType.CHAT_ONLY_RECEIVER_UPLOAD;
  }

  get isClarification() {
    return this.type === ExchangeType.CLARIFICATION;
  }

  private getAttachments(action) {
    return action.value === REVISE
      ? (action.currentValue.attachments || [])
      : (action.attachments || []);
  }

  private sanitizeHistory(history) {
    if (this._id === 'f72aab80-8904-45b4-919f-8f873665dfee') {
      // HACK:
      // You might be wondering "what the fuck is this!?" -- and that would be a very
      // good question. We have an exchange on prod where we saw consecutive `submit`
      // actions followed by consecutive `obsolete-action` actions. This shouldn't be
      // possible, and the rfx machine will ignore the 2nd of each of these events,
      // however since we're in a half-state of our migration to the rfx machine, we
      // need to ensure that output between the old/new systems are consistent.
      //
      // So instead of introducing changes that apply to all exchanges and introducing a
      // lot of risk, we'll just single out this exchange and ensure its output is
      // consistent with the rfx machine when it processes the same exchange.
      return history.filter((action, index, actions) => {
        // Drop any consecutive `submit` or `obsolete-action` actions
        if (
          [SUBMIT, OBSOLETE_ACTION].includes(action.value) &&
          actions[index - 1].value === action.value
        ) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      return history;
    }
  }
}
