import * as React from 'react';
import { useQuery } from 'react-query';
import { Interval, RequestsReportingConfig } from '@deepstream/common/reporting';
import { subDays, subMonths, subQuarters, subWeeks, subYears } from 'date-fns';
import { useApi, wrap } from '../../api';
import { useCompanyFeatureFlags } from '../../companyFeatureFlags';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useSystemFeatureFlags } from '../../systemFeatureFlags';
import { useCurrentUser } from '../../useCurrentUser';
import dummyRequestsCardsData from './dummyRequestsCardsData.json';
import dummyRequestsTableData from './dummyRequestsTableData.json';
import { useRequestsReportingConfig } from './RequestsReportingConfig';
import { useSelectedCompanyIds } from '../../selectedCompanyIds';

export const getStartDate = (date: Date, interval: Interval, amount = 13) => {
  switch (interval) {
    case Interval.DAY:
      return subDays(date, amount);
    case Interval.WEEK:
      return subWeeks(date, amount);
    case Interval.MONTH:
      return subMonths(date, amount);
    case Interval.QUARTER_YEAR:
      return subQuarters(date, amount);
    case Interval.YEAR:
      return subYears(date, amount);
  }
};

export const useRequestsReportingCardsData = <TData,>(
  config: RequestsReportingConfig,
  section: 'budget' | 'value' | 'savings' | 'allRequests',
) => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const selectedCompanyIds = useSelectedCompanyIds();
  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;
  const hasAccessToData = (
    systemManagementReportingEnabled &&
    companyManagementReportingEnabled &&
    userHasRequestsReportingAccess
  );
  const api = useApi();

  const options = hasAccessToData
    ? { initialData: undefined, enabled: true }
    : { initialData: dummyRequestsCardsData as any, enabled: false };
  return useQuery<TData>(
    ['requestsReportingCardsData', {
      currentCompanyId,
      section,
      config,
      selectedCompanyIds,
    }],
    wrap(async ({ currentCompanyId, section, config, selectedCompanyIds }) => {
      const endDate = new Date();
      const startDate = getStartDate(endDate, config.interval);

      return api.getRequestsReportingCardsData({
        currentCompanyId,
        section,
        config,
        startDate,
        endDate,
        selectedCompanyIds,
      });
    }),
    options,
  );
};

export const useRequestsReportingTableData = (
  section: 'budget' | 'value' | 'savings' | 'allRequests',
  config?: RequestsReportingConfig,
) => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const selectedCompanyIds = useSelectedCompanyIds();
  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;
  const hasAccessToData = (
    systemManagementReportingEnabled &&
    companyManagementReportingEnabled &&
    userHasRequestsReportingAccess
  );
  const api = useApi();

  const options = hasAccessToData
    ? { initialData: undefined, enabled: true }
    : { initialData: dummyRequestsTableData, enabled: false };

  return useQuery(
    ['requestsReportingTableData', { currentCompanyId, section, selectedCompanyIds, config }],
    wrap(api.getRequestsReportingTableData),
    options,
  );
};

export const useRequestsReporting = (config: RequestsReportingConfig) => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const selectedCompanyIds = useSelectedCompanyIds();
  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;
  const hasAccessToData = (
    systemManagementReportingEnabled &&
    companyManagementReportingEnabled &&
    userHasRequestsReportingAccess
  );
  const api = useApi();

  const options = hasAccessToData
    ? { initialData: undefined, enabled: true }
    : { initialData: undefined, enabled: false };

  return useQuery(
    ['requestsReportingOther', { currentCompanyId, currency: config.currency, selectedCompanyIds }],
    wrap(api.getRequestsReportingOtherData),
    options,
  );
};

export const useRequestsReportingDashboardData = <TData,>(
  config: RequestsReportingConfig,
) => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyFeatureFlags = useCompanyFeatureFlags();
  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;
  const hasAccessToData = (
    systemManagementReportingEnabled &&
    companyManagementReportingEnabled &&
    userHasRequestsReportingAccess
  );
  const api = useApi();

  const options = hasAccessToData
    ? { initialData: undefined, enabled: true }
    : { initialData: undefined, enabled: false };
  return useQuery<TData>(
    ['requestsReportingDashboardData', {
      currentCompanyId,
      config,
    }],
    wrap(async ({ currentCompanyId, config }) => {
      const endDate = new Date();
      const startDate = getStartDate(endDate, config.interval);

      return api.getRequestsReportingDashboardData({
        currentCompanyId,
        config,
        startDate,
        endDate,
      });
    }),
    options,
  );
};

export const RequestsReportingDataContext = React.createContext(null);

export const RequestsReportingDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentUser = useCurrentUser();
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const companyFeatureFlags = useCompanyFeatureFlags();
  const systemFeatureFlags = useSystemFeatureFlags({ required: true });
  const { config } = useRequestsReportingConfig();

  const userHasRequestsReportingAccess = currentUser.roles[currentCompanyId].accessReportingForRequests;
  const companyManagementReportingEnabled = companyFeatureFlags?.managementReportingEnabled;
  const systemManagementReportingEnabled = systemFeatureFlags?.managementReportingEnabled;

  const { data, isLoading, isError, isSuccess } = useRequestsReporting(config);

  const value = React.useMemo(() => ({
    data,
    isLoading,
    isError,
    isSuccess,
    companyManagementReportingEnabled,
    systemManagementReportingEnabled,
    userHasRequestsReportingAccess,
    currency: config.currency,
  }), [
    data,
    isLoading,
    isError,
    isSuccess,
    companyManagementReportingEnabled,
    systemManagementReportingEnabled,
    userHasRequestsReportingAccess,
    config.currency,
  ]);

  return (
    // @ts-expect-error ts(2322) FIXME: Type '{ data: any; isLoading: boolean; isError: boolean; isSuccess: boolean; companyManagementReportingEnabled: boolean | undefined; systemManagementReportingEnabled: boolean | undefined; userHasRequestsReportingAccess: boolean; currency: string; }' is not assignable to type 'null'.
    <RequestsReportingDataContext.Provider value={value}>
      {children}
    </RequestsReportingDataContext.Provider>
  );
};

export const useRequestsReportingData = () => {
  const reportingData = React.useContext(RequestsReportingDataContext);

  if (!reportingData) {
    throw new Error('Requests reporting data is falsy');
  }

  return reportingData;
};
