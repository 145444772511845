import { keyBy } from 'lodash';
import { RequestTag } from '../../ui/types';
import { useCurrentCompanyId } from '../../currentCompanyId';
import { useCurrentUser } from '../../useCurrentUser';
import { useIsAppAdmin } from '../../useIsAppAdmin';

export interface TreeItem {
  index: string;
  children?: Array<string>;
  isFolder?: boolean;
  data: RequestTag;
}

export const getRequestTagAncestorsNames = (requestTag: RequestTag, tagsById: { [id: string]: RequestTag }): string[] => {
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const parentTag = tagsById[requestTag.parentTagId];
  if (!parentTag) {
    return [];
  }
  return [...getRequestTagAncestorsNames(parentTag, tagsById), parentTag.name];
};

export const getRequestTagAncestorsIds = (requestTag: RequestTag, tagsById: { [id: string]: RequestTag }): string[] => {
  // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const parentTag = tagsById[requestTag.parentTagId];
  if (!parentTag) {
    return [];
  }
  return [...getRequestTagAncestorsIds(parentTag, tagsById), parentTag._id];
};

const requestTagToTreeItem = (requestTag: RequestTag): TreeItem => {
  if (!requestTag) {
    // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'TreeItem'.
    return null;
  }
  return {
    // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
    index: requestTag._id,
    data: requestTag,
    children: [],
    isFolder: false,
  };
};

export const getRequestTagsTreeItems = (requestTags: RequestTag[], locale: string) => {
  const requestTagsById = keyBy(requestTags, '_id');
  const tree: Record<string, TreeItem> = {
    root: {
      index: 'root',
      // @ts-expect-error ts(2322) FIXME: Type 'null' is not assignable to type 'RequestTag'.
      data: null,
      children: [],
      isFolder: true,
    },
  };

  for (const tag of requestTags) {
    const treeItem = requestTagToTreeItem(tag);
    if (tag.parentTagId && !tree[tag.parentTagId]) {
      tree[tag.parentTagId] = requestTagToTreeItem(requestTagsById[tag.parentTagId]);
    }
    const parent = tag.parentTagId && tree[tag.parentTagId];

    if (parent) {
      parent.children = [...parent.children ?? [], treeItem.index];
      parent.isFolder = true;
    }

    if (!tree[treeItem.index]) {
      tree[treeItem.index] = treeItem;
    }

    if (!tag.parentTagId) {
      tree.root.children = [...tree.root.children ?? [], treeItem.index];
    }
  }

  Object.values(tree).forEach(item => {
    // @ts-expect-error ts(18048) FIXME: 'item.children' is possibly 'undefined'.
    item.children.sort(
      // @ts-expect-error ts(2532) FIXME: Object is possibly 'undefined'.
      (itemAId, itemBId) => tree[itemAId].data.name.localeCompare(tree[itemBId].data.name, locale),
    );
  });

  return tree;
};

export const useCanEditRequestTags = () => {
  const currentCompanyId = useCurrentCompanyId({ required: true });
  const currentUser = useCurrentUser();
  const userIsAdmin = currentUser.roles[currentCompanyId]?.admin;
  const isAppAdmin = useIsAppAdmin();

  return userIsAdmin || isAppAdmin;
};

// Needed because reach tabs only support number as index and we need to
// differentiate between the tags and the sent/received/template tabs
// id of the tag is a number
// TODO: find a better way to do this
export const getTreeIndexFromId = (id: string) => 1000 + +id;

export const isTagIndex = (index: number) => index >= 1000;

export const getTagIdFromIndex = (index: number) => (index - 1000).toString();
