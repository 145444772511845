import * as React from 'react';
import clsx from 'clsx';
import { TOriginalColumnDataBase, TOriginalSubRowDataBase, TExpandableRowDataBase, FrozenHeaderCellProps, GridDataAndCommands, DataCellProps } from './utils';

type RenderFrozenHeaderCellProps<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> = {
  data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData> & {
    DataCell: React.FunctionComponent<DataCellProps<any, any, any>>;
    FirstColumnCell: React.FunctionComponent<DataCellProps<any, any, any>>;
    bodyPaddingLeft: number;
    frozenLeftColumnCount: number;
  };
  FrozenHeaderCell: React.FunctionComponent<FrozenHeaderCellProps<TOriginalColumnData>>;
  index: number;
};

const RenderFrozenHeaderCellBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
    data,
    FrozenHeaderCell,
    index: columnIndex,
  }: RenderFrozenHeaderCellProps<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>) => {
  const column = data.columns[columnIndex];

  const { activeCellIndices, selectedRange, highlightSelectionInHeaders } = data;

  const columnHasActiveOrSelectedContent = (
    highlightSelectionInHeaders &&
    (
      (
        column.original._id !== 'rowNumber' &&
        activeCellIndices &&
        activeCellIndices.columnIndex === columnIndex
      ) ||
      (
        selectedRange &&
        columnIndex >= selectedRange.start.columnIndex &&
        columnIndex < selectedRange.end.columnIndex
      )
    )
  );

  return (
    <div
      className={clsx(
        'header-cell',
        columnHasActiveOrSelectedContent && 'selected-header',
      )}
      style={{
        position: 'absolute',
        top: 0,
        left: `${column.left}px`,
        height: 'var(--frozen-header-height)',
        width: `${column.width}px`,
      }}
    >
      <FrozenHeaderCell column={column} />
    </div>
  );
};

export const RenderFrozenHeaderCell =
  React.memo(RenderFrozenHeaderCellBase) as typeof RenderFrozenHeaderCellBase;
