import { mapValues, pickBy } from 'lodash';
import { TFunction } from 'i18next';
import { requestStatusesConfig, combinedBidStatusesConfig } from './statusConfigs';
import { RfqStatus, CombinedBidStatus } from './types';

export const getCombinedBidStatusLabels = (t: TFunction) => {
  const filteredConfig = pickBy(combinedBidStatusesConfig, config => !config.isDeprecated);

  return mapValues(
    filteredConfig,
    config => t(`request.combinedBidStatus.${config.status}`),
  ) as Record<CombinedBidStatus, string>;
};

export const getRfqStatusLabels = (t: TFunction) => {
  return mapValues(
    requestStatusesConfig,
    config => t(`request.status.${config.status}`),
  ) as Record<RfqStatus, string>;
};
