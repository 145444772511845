import { Combobox, ComboboxState } from 'ariakit/combobox';
import { throttle } from 'lodash';
import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce/lib';

import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { centeredTextAdjustmentCss } from '@deepstream/ui-kit/elements/text/textAdjustment';

export const SEARCH_INPUT_HEIGHT = 28;
const ON_CHANGE_DEBOUNCE_MILISECONDS = 400;
const SET_IS_LOADING_THROTTLE_MILISECOND = 400;

const SearchTextCombobox = styled(Combobox as any)`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border: 0;
  border-radius: 4px;

  box-sizing: border-box;
  height: ${SEARCH_INPUT_HEIGHT}px;
  padding-left: 8px;

  line-height: 1.5;

  &:focus {
    outline: 0;
  }

  color: ${(props) =>
    props.disabled ? props.theme.colors.subtext : props.theme.colors.text};
  transition: all 300ms;

  &::placeholder {
    color: ${(props) =>
      props.disabled
        ? props.theme.colors.lightGray
        : props.theme.colors.subtext};
  }

  ${centeredTextAdjustmentCss}
`;

const VerticalDivider = styled(Box)`
  min-height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.lightGray2};
`;

export type SearchInputComboboxProps = {
  initialValue?: string;
  textPlaceholder: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  isLoading: boolean;
  disabled?: boolean;
  comboboxState: ComboboxState;
  onBlur?: (event: any) => void;
  hideOnSubmit?: boolean;
  showClearButton?: 'always' | 'whenClosed';
};

const EMPTY_STRING = '';

export const SearchInputCombobox = ({
  initialValue = EMPTY_STRING,
  textPlaceholder,
  onSubmit,
  onChange,
  isLoading: isSearchLoading,
  disabled,
  comboboxState,
  onBlur,
  hideOnSubmit = false,
  showClearButton = 'whenClosed',
}: SearchInputComboboxProps) => {
  const theme = useTheme();

  const { value, setValue, open, hide } = comboboxState;

  React.useEffect(() => {
    if (typeof initialValue === 'string') {
      setValue(initialValue);
    }
  }, [initialValue, setValue]);

  const [debouncedOnChange] = useDebouncedCallback(
    (text: string) => onChange?.(text),
    ON_CHANGE_DEBOUNCE_MILISECONDS,
  );
  React.useEffect(() => {
    debouncedOnChange(value);
  }, [debouncedOnChange, value]);

  const [isLoading, setIsLoading] = React.useState<boolean>();
  const setIsLoadingThrottled = React.useMemo(
    () => throttle((isLoading) => setIsLoading(isLoading), SET_IS_LOADING_THROTTLE_MILISECOND),
    [setIsLoading],
  );
  React.useEffect(() => {
    setIsLoadingThrottled(isSearchLoading);
  }, [isSearchLoading, setIsLoadingThrottled]);

  const clearSearch = React.useCallback(() => {
    if (!disabled) {
      setValue(EMPTY_STRING);
      onSubmit?.(EMPTY_STRING);
    }
  }, [setValue, onSubmit, disabled]);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === KeyCode.ENTER) {
        event.preventDefault();
        onSubmit?.(value);
        if (hideOnSubmit) {
          hide();
        }
      }
    },
    [onSubmit, value, hideOnSubmit, hide],
  );

  const shouldShowClearButton =
    showClearButton === 'always' || (showClearButton === 'whenClosed' && !open);

  return (
    <Flex
      flexDirection="row"
      sx={{
        borderRadius: '4px',
        border: theme.borders.lightGray,
      }}
    >
      <SearchTextCombobox
        state={comboboxState}
        autoComplete="none"
        placeholder={textPlaceholder}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        disabled={disabled}
      />
      {value && shouldShowClearButton && (
        <Flex
          alignItems="center"
          justifyContent="center"
          sx={{
            width: `${SEARCH_INPUT_HEIGHT}px`,
            cursor: disabled ? undefined : 'pointer',
          }}
          onClick={clearSearch}
        >
          <Icon
            icon="xmark"
            color={disabled ? 'lightGray' : 'subtext'}
            aria-hidden="true"
            sx={{ width: '14px', height: '14px' }}
            fixedWidth
          />
        </Flex>
      )}
      <VerticalDivider />
      <Flex
        alignItems="center"
        justifyContent="center"
        sx={{
          minWidth: `${SEARCH_INPUT_HEIGHT}px`,
          backgroundColor: disabled
            ? theme.colors.lightGrayTransparent
            : theme.colors.lightGray3,
          transition: 'background-color 300ms',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        <Icon
          icon={isLoading ? 'spinner' : 'search'}
          color={disabled ? 'lightGray' : 'subtext'}
          aria-hidden="true"
          sx={{ width: '14px', height: '14px', transition: 'color 300ms' }}
          fixedWidth
          spin={isLoading}
        />
      </Flex>
    </Flex>
  );
};
