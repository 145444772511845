import * as React from 'react';
import { Image, Text, Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { getRegionName } from '@deepstream/common/region';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { CompanyMinimized } from '@deepstream/common/rfq-utils';
import { useEnv } from './env';
import { Company } from './ui/types';
import { useCurrentUserLocale } from './useCurrentUser';
import { useApi, wrap } from './api';
import { CompanyNamesTooltip } from './ui/TooltipList';

const sizeToPx = {
  xxs: '14px',
  xs: '25px',
  sm: '34px',
  md: '40px',
};

const sizeToPadding = {
  xxs: '1px',
  xs: '1px',
  sm: '2px',
  md: '3px',
};

const Container = styled(Flex)<{
  size?: 'xxs' | 'xs' | 'sm' | 'md' ;
}>`
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  ${({ size }) => size ? `width: ${sizeToPx[size]};height: ${sizeToPx[size]};padding ${sizeToPadding[size]};` : ''}
  border-radius: 4px;
  border: 1px solid #E2E8EF;
  overflow: hidden;
`;

type CompanyLogoProps = {
  companyId: string;
  size?: 'xxs' | 'xs' | 'sm' | 'md' ;
  backgroundColor?: string;
  mr?: number;
};

export const CompanyLogo = ({ companyId, size = 'sm', backgroundColor, mr = 2 } : CompanyLogoProps) => {
  const { API_URL } = useEnv();

  return companyId ? (
    <Container mr={mr} size={size} bg={backgroundColor}>
      <Image src={`${API_URL}/download/logo/${companyId}`} />
    </Container>
  ) : (
    null
  );
};

type CompanyLogoAndNameLayoutProps = Omit<CompanyLogoProps, 'companyId' | 'mr'> & {
  company: Company | CompanyMinimized;
  address?: {
    country?: string;
  };
  sx?: React.CSSProperties;
  textStyle?: React.CSSProperties;
};

const CompanyLogoAndNameLayout = ({
  company,
  address,
  sx = {},
  textStyle = {},
  ...props
}: CompanyLogoAndNameLayoutProps) => {
  const locale = useCurrentUserLocale();

  return (
    <Flex sx={{ columnGap: '4px', ...sx }} alignItems="center">
      <CompanyLogo companyId={company._id} mr={0} {...props} />
      <Stack gap={0} flex={1}>
        <Truncate sx={textStyle}>{company.name}</Truncate>
        {address?.country && (
          <Text fontSize={1} color="gray">
            {getRegionName(address?.country, locale)}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};

export const CompanyLogoAndName = ({ company, ...props } : CompanyLogoAndNameLayoutProps) => {
  return (
    <CompanyLogoAndNameLayout company={company} {...props} />
  );
};

export const CompanyLogoWithCountry = ({ company, address, ...props } : CompanyLogoAndNameLayoutProps) => {
  return (
    <CompanyLogoAndNameLayout
      company={company}
      address={address}
      {...props}
    />
  );
};

const CompanyLogoAndNameContainer = ({ companyId }: { companyId: string }) => {
  const api = useApi();

  const { data: company } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany),
  );

  return company ? <CompanyLogoAndName company={company} size="xs" /> : null;
};

const CompaniesWithLogoGroup = ({
  companyIds,
}: { companyIds: string[] }) => {
  const theme = useTheme();
  const { t } = useTranslation('translation');

  return (
    <Flex sx={{ columnGap: '4px' }} alignItems="center">
      <Box display="grid" sx={{ gridTemplateColumns: 'repeat(3, 18px) 1fr' }}>
        {companyIds.slice(0, 4).map((value, index) => (
          <Box
            key={index}
            sx={{ background: theme.colors.white, borderRadius: '4px' }}
          >
            <CompanyLogo key={index} size="xs" companyId={value} mr={0} />
          </Box>
        ))}
      </Box>
      {companyIds.length > 4 && (
        <Text>
          +
          {t('request.stageApprovals.otherSupplierCount', {
            count: companyIds.length - 4,
          })}
        </Text>
      )}
    </Flex>
  );
};

export const CompaniesWithLogo = ({
  companyIds,
  companies,
  tooltipTitle,
}: { companyIds?: string[], companies?: CompanyMinimized[], tooltipTitle?: string }) => {
  if (!companyIds && !companies) {
    throw new Error('One of companyIds or companies should be provided');
  }
  if (companies && companyIds) {
    throw new Error('Only one of companyIds or companies should be provided');
  }

  const ids = companyIds || companies!.map((company) => company._id);

  return ids.length === 1 ? (
    companyIds ? (
      <CompanyLogoAndNameContainer companyId={ids[0]} />
    ) : (
      <CompanyLogoAndName company={companies![0]} size="xs" />
    )
  ) : (
    <Tooltip
      content={<CompanyNamesTooltip title={tooltipTitle} companyIds={ids} />}
    >
      <Box>
        <CompaniesWithLogoGroup companyIds={ids} />
      </Box>
    </Tooltip>
  );
};
