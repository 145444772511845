import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, keyBy, mapValues } from 'lodash';

import { diffObject } from '@deepstream/utils';
import { ChangeType, PageRole, PageType } from '@deepstream/common/rfq-utils';

import { getSelectPageRoleConfig } from './selectPageRoleConfig';

export const getPageRoleChanges = (
  initialRoles: Record<string, PageRole>,
  submittedRoles: Record<string, PageRole>,
  companyId: string,
  userId: string,
) => {
  const permissionChanges = diffObject(submittedRoles, initialRoles);

  const rfqRoles = mapValues(keyBy(permissionChanges.updated, 'key'), 'value');

  return isEmpty(rfqRoles)
    ? []
    : [{
      type: ChangeType.TEAM_MEMBER_ROLES_UPDATED,
      userId,
      companyId,
      rfqRoles,
    }];
};

export const usePagePermissionOptions = ({
  pageType,
  isSender,
  isOwner,
  isAuthorizedStakeholderSender,
}: {
  pageType?: PageType,
  isSender: boolean;
  isOwner: boolean;
  isAuthorizedStakeholderSender: boolean;
}) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const {
      labelKeyPrefix,
      descriptionKeyPrefix,
      items,
    } = getSelectPageRoleConfig(pageType, isSender, isOwner);

    return items.map(item => ({
      value: item.pageRole,
      icon: item.icon,
      description: t(`${descriptionKeyPrefix}.${item.pageRole}`),
      // prevent setting EDITOR role for authorized stakeholders, except for the Messages page
      disabled: isAuthorizedStakeholderSender && item.pageRole === PageRole.EDITOR && pageType !== PageType.CHAT,
      label: t(`${labelKeyPrefix}.${item.pageRole}`),
    }));
  }, [pageType, isSender, isOwner, t, isAuthorizedStakeholderSender]);
};
