import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'rebass/styled-components';
import { IconText, IconTextProps } from '@deepstream/ui-kit/elements/text/IconText';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';

export type ErrorMessageProps = {
  error?: React.ReactNode | string | null | undefined;
  hide?: boolean;
  style?: any;
  fontSize?: IconTextProps['fontSize'];
  lineHeight?: IconTextProps['lineHeight'];
  gap?: IconTextProps['gap'];
  truncate?: IconTextProps['truncate'];
};

export const ErrorMessage = ({ hide, error, fontSize = 0, lineHeight, gap, style, truncate = false }: ErrorMessageProps) => (
  (!hide && error) ? (
    <IconText
      color="danger"
      icon="exclamation-circle"
      text={error}
      name={error?.toString()}
      truncate={truncate}
      fontSize={fontSize}
      style={style}
      fontWeight="normal"
      lineHeight={lineHeight}
      gap={gap}
    />
  ) : (
    null
  )
);

export type ErrorInfoMessageProps = {
  error?: React.ReactNode | string | null | undefined;
  style?: any;
  fontSize?: IconTextProps['fontSize'];
  gap?: IconTextProps['gap'];
};

export const ErrorInfoMessage = ({ error, fontSize = 0, gap = 1, style }: ErrorInfoMessageProps) => {
  const { t } = useTranslation();

  return error ? (
    <Flex fontSize={fontSize} color="danger" alignItems="center" sx={{ gap }} style={style}>
      <Icon icon="exclamation-circle" />
      <Text >{t('errors.uploadFailed')}</Text>
      <Tooltip content={error} allowedPlacements={['top']}>
        <Icon icon="info-circle" regular />
      </Tooltip>
    </Flex>
  ) : (
    null
  );
};

type ErrorPanelProps = {
  heading?: string;
  error: ErrorMessageProps['error'];
  style?: any;
};

export const ErrorPanel = ({ heading, error, style }: ErrorPanelProps) => (
  <Panel heading={heading} padded style={style}>
    <ErrorMessage error={error} fontSize={2} />
  </Panel>
);
