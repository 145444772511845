import { EditableGridColumn } from '@deepstream/ui-kit/grid/EditableGrid/utils';
import { withProps } from '@deepstream/ui-utils';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { GridDisplayConfig } from '../../ui/ExchangeDefsGrid/gridViews';
import { SuppliersGridColumnHeader } from '../Request/Sent/SuppliersGridColumnHeader';
import { } from '../Request/Sent/useSuppliersGridConfig';
import { AllRequestsGridViewId } from './allRequestsGridTypes';

export const defaultColumnWidth = 150;
export const ALL_REQUESTS_GRID_FILTER_DROPDOWN_ID = 'all-requests-grid-filter-dropdown';
export const ALL_REQUESTS_GRID_SORT_DROPDOWN_ID = 'all-requests-grid-sort-dropdown';

// TODO refactor this and useSuppliersGridColumns() into a single reusable hook
export const useAllRequestsGridColumns = ({
  gridConfig,
  columnConfigById,
}: {
  gridConfig: GridDisplayConfig<AllRequestsGridViewId>;
  columnConfigById: Record<
    string,
    Omit<EditableGridColumn<any>, 'Header'> | null
  >;
}): EditableGridColumn<any>[] => {
  return useMemo(() => {
    const selectedColumns = gridConfig.columns.selectedItems;

    const SupplierColumnHeader = withProps(SuppliersGridColumnHeader, {
      suppliersGridConfig: gridConfig,
      canHideColumn: false,
      filterDropdownId: ALL_REQUESTS_GRID_FILTER_DROPDOWN_ID,
      sortDropdownId: ALL_REQUESTS_GRID_SORT_DROPDOWN_ID,
    });

    const DynamicColumnHeader = withProps(SuppliersGridColumnHeader, {
      suppliersGridConfig: gridConfig,
      canHideColumn: true,
      filterDropdownId: ALL_REQUESTS_GRID_FILTER_DROPDOWN_ID,
      sortDropdownId: ALL_REQUESTS_GRID_SORT_DROPDOWN_ID,
    });

    const dynamicColumns = compact(
      selectedColumns.map((selectedColumn) => {
        const column = columnConfigById[selectedColumn._id];

        return column
          ? {
              ...column,
              width: gridConfig.columnWidths?.[selectedColumn._id] || column.width,
              Header: DynamicColumnHeader,
            }
          : null;
      }),
    ) as EditableGridColumn<any>[];

    const requestNameColumnWidth = gridConfig.columnWidths?.subject || (columnConfigById as any).subject.width;

    return [
      {
        ...columnConfigById.subject,
        width: requestNameColumnWidth,
        Header: SupplierColumnHeader,
      } as EditableGridColumn<any>,
      ...dynamicColumns,
    ];
  }, [gridConfig, columnConfigById]);
};
