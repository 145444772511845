/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Box, BoxProps } from 'rebass/styled-components';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { transparentize } from 'polished';
import useOnClickOutside from 'use-onclickoutside';
import clsx from 'clsx';
import { findIndex, findLastIndex } from 'lodash';
import { KeyCode } from '@deepstream/ui-utils/KeyCode';
import { stopEvent } from '@deepstream/ui-utils/domEvent';
import { Icon, IconProps } from '../../elements/icon/Icon';
import { mergeRefs } from '../../elements/popup/usePopover';
import { DropdownMenuDivider, menuWrapperCss } from '../../elements/menu/DropdownMenu';
import { focusGrid, getGridContainerId } from '../core/utils';
import { useGridMenuState } from './gridMenuState';
import { useGridIdPrefix } from './gridIdPrefix';
import { useSameWidthModifier } from '../../elements/popup/useSameWidthModifier';

export const GridMenuWrapper = styled(Box)`
  ${menuWrapperCss}

  margin-top: 1px;
  margin-bottom: 2px;
  outline: none !important;
  padding-top: 8px;
  padding-bottom: 8px;

  > div {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    cursor: pointer;

    &.disabled {
      color: ${props => props.theme.colors.lightGray};
    }
  }

  > div.active {
    color: ${props => props.theme.colors.text};
    background-color: ${props => transparentize(0.90, props.theme.colors.primary)};

    &.disabled {
      color: ${props => props.theme.colors.lightGray};
      cursor: default;
      background-color: transparent;
    }
  }
`;

export const GridFilesMenuWrapper = styled(Box)`
  ${menuWrapperCss}
  overflow: auto;
  border-top: none;

  margin: 0;

  padding: 10px 6px 6px 6px;
  outline: none !important;
`;

export interface GridMenuItemProps extends BoxProps {
  disabled?: boolean;
  icon?: IconProps['icon'];
  iconColor?: string;
  iconRegular?: boolean;
  onSelect?: (item?: any) => void;
  preventCloseOnSelect?: boolean;
  hasSeparatorAbove?: boolean
  children: React.ReactNode;
}

export const GridMenuItem = ({ color, icon, iconColor, iconRegular, children, ...props }: GridMenuItemProps) => (
  <Box color={color} {...props}>
    {icon && (
      <Icon
        icon={icon}
        color={iconColor}
        regular={iconRegular}
        mr={2}
        aria-hidden="true"
        fixedWidth
      />
    )}
    {children}
  </Box>
);

export interface PopoverProps {
  'data-test'?: string;
}

export interface GridPopupProps {
  cellId: string;
  close: () => void;
  popoverProps?: PopoverProps;
  closeOnOtherCellClick?: boolean;
  sameWidth?: boolean;
  children: React.ReactNode;
}

export const GridPopup = ({ popoverProps, cellId, close, closeOnOtherCellClick = false, sameWidth = false, children }: GridPopupProps) => {
  const idPrefix = useGridIdPrefix();
  const popperRef = React.useRef<HTMLElement>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement>();

  useOnClickOutside(popperRef, event => {
    const gridContainerId = getGridContainerId(idPrefix);

    if (
      !(event.target instanceof Element) ||
      (closeOnOtherCellClick && !event.target.closest(`div[role="gridcell"][id="${cellId}"]`)) ||
      (!event.target.closest('div[role="gridcell"]') && event.target.closest(`#${gridContainerId}`)) ||
      !event.target.closest(`#${gridContainerId}`)
    ) {
      close();
    }
  });

  React.useEffect(() => {
    popperElement?.focus({ preventScroll: true });
  }, [popperElement]);

  const referenceElement = document.getElementById(cellId);

  const sameWidthModifier = useSameWidthModifier({ enabled: sameWidth });
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [sameWidthModifier],
  });

  return ReactDOM.createPortal(
    <Box
      style={{ ...styles.popper, outline: 0 }}
      sx={{ zIndex: 202 }}
      ref={mergeRefs([setPopperElement, popperRef])}
      {...attributes.popper}
      tabIndex={-1}
      onFocus={(event) => {
        const firstChild = event.target.firstElementChild;
        if (firstChild) {
          stopEvent(event);
          (firstChild as any).focus({ preventScroll: true });
        }
      }}
      {...popoverProps}
    >
      {children}
    </Box>,
    document.body,
  );
};

export const GridMenuContent = ({ children, close }) => {
  const wrapperRef = React.useRef<HTMLDivElement>();
  const childrenArray = React.useMemo(() => React.Children.toArray(children), [children]);

  React.useLayoutEffect(() => {
    wrapperRef.current?.focus({ preventScroll: true });
  }, []);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleKeyDown = React.useCallback((event) => {
    stopEvent(event);

    switch (event.code) {
      case KeyCode.ESCAPE:
        close();
        break;
      case KeyCode.SPACE:
      case KeyCode.ENTER: {
        const child = childrenArray[activeIndex];

        if (
          React.isValidElement<GridMenuItemProps>(child) &&
          !child.props.disabled &&
          child.props.onSelect
        ) {
          child.props.onSelect();
          if (!child.props.preventCloseOnSelect) {
            close();
          }
        }

        break;
      }
      case KeyCode.ARROW_UP: {
        const precedingChildren = childrenArray.slice(0, activeIndex);

        const nextIndex = findLastIndex(
          precedingChildren,
          child => React.isValidElement(child) && !child.props.disabled,
        );

        if (nextIndex !== -1) {
          setActiveIndex(nextIndex);
        }

        break;
      }
      case KeyCode.ARROW_DOWN: {
        const followingChildren = childrenArray.slice(activeIndex + 1);

        const nextIndex = findIndex(
          followingChildren,
          child => React.isValidElement(child) && !child.props.disabled,
        );

        if (nextIndex !== -1) {
          setActiveIndex(activeIndex + 1 + nextIndex);
        }

        break;
      }
      default:
        break;
    }
  }, [close, activeIndex, setActiveIndex, childrenArray]);

  return (
    <GridMenuWrapper tabIndex={0} onKeyDown={handleKeyDown} ref={wrapperRef}>
      {childrenArray.map((child, index) => (
        <React.Fragment key={index}>
          {React.isValidElement(child) && child.props.hasSeparatorAbove && (
            <DropdownMenuDivider style={{ padding: 0 }} />
          )}
          <div
            className={clsx({
              active: index === activeIndex,
              disabled: React.isValidElement(child) && (child as any).props.disabled,
            })}
            onMouseEnter={() => setActiveIndex(index)}
            onClick={() => {
              if (
                React.isValidElement<GridMenuItemProps>(child) &&
                !child.props.disabled &&
                child.props.onSelect
              ) {
                child.props.onSelect();
                if (!child.props.preventCloseOnSelect) {
                  close();
                }
              }
            }}
          >
            {child}
          </div>
        </React.Fragment>
      ))}
    </GridMenuWrapper>
  );
};

export const GridMenu = ({ popoverProps, children }: { popoverProps?: PopoverProps; children: React.ReactNode }) => {
  const idPrefix = useGridIdPrefix();
  const {
    menuReferenceId,
    setMenuReferenceId,
  } = useGridMenuState();

  const closeGridMenu = React.useCallback(() => {
    setMenuReferenceId(null);
    focusGrid(idPrefix);
  }, [setMenuReferenceId, idPrefix]);

  return menuReferenceId ? (
    <GridPopup
      key={menuReferenceId}
      cellId={menuReferenceId}
      close={closeGridMenu}
      popoverProps={popoverProps}
    >
      <GridMenuContent close={closeGridMenu}>
        {children}
      </GridMenuContent>
    </GridPopup>
  ) : (
    null
  );
};

export const GridFilesMenu = ({
  children,
  close,
}: {
  children: React.ReactNode;
  close: () => void;
}) => {
  const handleKeyDown = React.useCallback((event) => {
    stopEvent(event);
    if (event.code === KeyCode.ESCAPE) {
      close();
    }
  }, [close]);

  return (
    <GridFilesMenuWrapper onKeyDown={handleKeyDown}>
      {children}
    </GridFilesMenuWrapper>
  );
};
