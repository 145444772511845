import * as React from 'react';
import { IconValue } from '@deepstream/common';
import { transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import { Box, Flex, FlexProps, Text } from 'rebass/styled-components';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { mergeRefs, Popover, usePopover } from '@deepstream/ui-kit/elements/popup/usePopover';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { pick } from 'lodash';
import { assertDefined } from '@deepstream/utils';
import { FilterProps } from '../../filtering';
import { useDistanceFromWindow } from '../../pre-q/useDistanceFromWindow';
import { Counter } from '../Badge';
import { HorizontalDivider } from '../HorizontalDivider';
import { SlidingBox } from '../SlidingBox';

const FilterNavContext = React.createContext<{
  pageConfigs,
  resetSelectedPageId,
  selectedPageId,
  setSelectedPageId,
  itemsCountByPageId,
  closeMenu,
  setPageIdItemsCount,
} | null>(null);

const DEFAULT_MENU_WIDTH = 400;
const DEFAULT_MAX_MENU_HEIGHT = '800px';

export type FilterPageProps<T extends Record<string, unknown>> = Pick<
  FilterProps<T>,
  'selectedItems' | 'onChange'
>;

export type FilterDropdownPanelConfig = {
  pageId: string;
  title: string;
  icon: IconValue;
  isIconRegular?: boolean;
};

export type FilterDropdownPanelProps = {
  config: Record<string, FilterDropdownPanelConfig>;
  children: React.ReactElement | (React.ReactElement | boolean)[] | boolean;
  // When `true`, we make sure that the item counts are correct
  // when items in the config get removed. This is required on
  // the suppliers page, where config items can get removed when
  // changing which columns are hidden.
  // It might be fine to remove this flag and always run the
  // corresponding code but I haven't checked other contexts.
  keepItemsCountInSyncWithConfig?: boolean;
  menuWidth?: number;
  maxMenuHeight?: string;
  id?: string;
};

export const FilterDropdownPages = ({
  config,
  children,
  keepItemsCountInSyncWithConfig,
  menuWidth = DEFAULT_MENU_WIDTH,
  maxMenuHeight = DEFAULT_MAX_MENU_HEIGHT,
  id,
}: FilterDropdownPanelProps) => {
  const { t } = useTranslation('general');
  const theme = useTheme();

  const [selectedPageId, setSelectedPageId] = React.useState<string | null>(null);
  const resetSelectedPageId = React.useCallback(
    () => setSelectedPageId(null),
    [setSelectedPageId],
  );

  const [itemsCountByPageId, setItemsCountByPageId] = React.useState<Record<string, number>>(() => {
    const newItemsCountByPageId = {};
    Object.keys(config).forEach((pageId) => {
      newItemsCountByPageId[pageId] = 0;
    });
    return newItemsCountByPageId;
  });

  useWatchValue(
    config,
    (config) => {
      if (keepItemsCountInSyncWithConfig) {
        setItemsCountByPageId(previousItemsCountByPageId => {
          return pick(
            previousItemsCountByPageId,
            Object.keys(config),
          );
        });
      }
    },
  );

  const totalItemsCount = React.useMemo(() => {
    let totalCount = 0;
    Object.values(itemsCountByPageId).forEach((count) => {
      totalCount += count;
    });
    return totalCount;
  }, [itemsCountByPageId]);
  const hasItems = totalItemsCount > 0;

  const setPageIdItemsCount = React.useCallback((pageId, count) => {
    setItemsCountByPageId((prev) => {
      return {
        ...prev,
        [pageId]: count,
      };
    });
  }, []);

  const [popoverRightDistance, buttonRef] = useDistanceFromWindow({
    edge: 'right',
    when: true,
  });

  const popover = usePopover({
    placement: popoverRightDistance < menuWidth ? 'bottom-end' : 'bottom-start',
  });
  const closePopover = React.useCallback(
    () => {
      popover.close();
    },
    [popover],
  );

  const closeMenu = React.useCallback(() => {
    popover.close();
  }, [popover]);

  React.useEffect(() => {
    const handleOpenPageEvent = event => {
      if (event.detail) {
        setSelectedPageId(event.detail);
        popover.open();
      }
    };

    const element = buttonRef.current;

    element?.addEventListener('openpage', handleOpenPageEvent);

    return () => {
      element?.removeEventListener('openpage', handleOpenPageEvent);
    };
  }, [buttonRef, setSelectedPageId, popover]);

  const value = React.useMemo(
    () => ({
      pageConfigs: config,
      selectedPageId,
      setSelectedPageId,
      resetSelectedPageId,
      closeMenu,
      itemsCountByPageId,
      setPageIdItemsCount,
    }),
    [
      config,
      selectedPageId,
      setSelectedPageId,
      resetSelectedPageId,
      closeMenu,
      itemsCountByPageId,
      setPageIdItemsCount,
    ],
  );

  React.useEffect(() => {
    if (!popover.isOpen) {
      setSelectedPageId(null);
    }
  }, [popover, setSelectedPageId]);

  // the totalItemsCount can affect the width of the button;
  // updating popper when the totalItemsCount changes makes sure
  // that the popup stays aligned with the edge of the button
  useWatchValue(
    totalItemsCount,
    () => popover.update?.(),
  );

  return (
    <FilterNavContext.Provider value={value}>
      <Button
        id={id}
        ref={mergeRefs([popover.setReferenceElement, buttonRef])}
        onClick={() => {
          popover.toggle();
          popover.update?.();
        }}
        iconLeft="filter"
        small
        variant="secondary-outline"
      >
        {t('filter')}
        {hasItems && <Counter color="primary" count={totalItemsCount} ml={1} />}
      </Button>
      <Popover
        {...popover}
        onClickOutside={closePopover}
        sx={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: '4px',
          marginBottom: 0,
          zIndex: 151,
        }}
      >
        <Flex
          flexDirection="row"
          sx={{
            padding: '8px 0px',
            width: `${menuWidth}px`,
            maxHeight: maxMenuHeight,
            color: theme.colors.text,
            backgroundColor: theme.colors.white,
            overflow: 'scroll',
          }}
        >
          {/* First page - navigation page */}
          <FilterDropdownNav>
            {Object.values(config).map(({ pageId, title, icon, isIconRegular }) => (
              <FilterDropdownNavItem
                key={pageId}
                title={title}
                icon={icon}
                isIconRegular={isIconRegular}
                onClick={() => setSelectedPageId(pageId)}
                onKeyDown={() => setSelectedPageId(pageId)}
                count={itemsCountByPageId[pageId]}
              />
            ))}
          </FilterDropdownNav>
          {/* Component's chidren are the individual filter pages */}
          {children}
        </Flex>
      </Popover>
    </FilterNavContext.Provider>
  );
};

export const FilterDropdownNav = ({ children }) => {
  const filterNavContext = React.useContext(FilterNavContext);

  assertDefined(filterNavContext, 'filterNavContext');

  const { selectedPageId } = filterNavContext;

  const isSelected = !selectedPageId;

  return (
    <SlidingBox isSelected={isSelected} slideDirection="left">
      {children}
    </SlidingBox>
  );
};

export const FilterDropdownNavItem = ({
  icon,
  isIconRegular,
  title,
  navigateBack = false,
  count,
  additionalContent,
  ...props
}: FlexProps & {
  icon: IconValue;
  isIconRegular?: boolean;
  title: string;
  navigateBack?: boolean;
  count?: number;
  additionalContent?: React.ReactNode;
}) => {
  const theme = useTheme();

  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      py={2}
      px="12px"
      sx={{
        height: '39px',
        gap: '10px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: navigateBack
            ? undefined
            : transparentize(0.95, theme.colors.primary),
        },
      }}
      role="presentation"
      {...props}
    >
      {navigateBack && <Icon icon="chevron-left" />}
      <Icon icon={icon} regular={isIconRegular} />
      <Box flex="1">
        <Text fontSize={2} fontWeight={500}>
          {title}
        </Text>
      </Box>
      <Counter color="primary" count={count} />
      {additionalContent}
      {!navigateBack && <Icon icon="chevron-right" />}
    </Flex>
  );
};

type FilterDropdownPageProps<T extends Record<string, unknown>> = FilterPageProps<T> & {
  pageId: string;
  children: React.ReactElement | React.ReactElement[];
};

export const FilterDropdownPage = <T extends Record<string, unknown>>({
  pageId,
  onChange,
  selectedItems,
  children,
}: FilterDropdownPageProps<T>) => {
  const { t } = useTranslation('general');
  const filterNavContext = React.useContext(FilterNavContext);

  assertDefined(filterNavContext, 'filterNavContext');

  const {
    pageConfigs,
    resetSelectedPageId,
    selectedPageId,
    setSelectedPageId,
    itemsCountByPageId,
    closeMenu,
    setPageIdItemsCount,
  } = filterNavContext;

  const isSelected = selectedPageId === pageId;

  const [isAnimating, setIsAnimating] = React.useState(false);
  React.useEffect(() => {
    if (isSelected) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 350);
    }
  }, [isSelected, setIsAnimating]);

  React.useEffect(() => {
    setPageIdItemsCount(pageId, selectedItems?.length ?? 0);
  }, [pageId, selectedItems?.length, setPageIdItemsCount]);

  const { icon, isIconRegular, title } = React.useMemo(() => {
    if (!pageConfigs[pageId]) {
      throw new Error(`No config found for pageId ${pageId}`);
    }

    const config = pageConfigs[pageId];

    return {
      icon: config.icon,
      isIconRegular: config.isIconRegular,
      title: config.title,
    };
  }, [pageConfigs, pageId]);

  const clearFilter = React.useCallback(() => {
    onChange([]);
    setSelectedPageId(null);
  }, [onChange, setSelectedPageId]);

  const hasItems = itemsCountByPageId[pageId] > 0;

  return (
    <SlidingBox
      isSelected={isSelected}
      slideDirection="right"
      flexDirection="column"
    >
      <FilterDropdownNavItem
        title={title}
        icon={icon}
        isIconRegular={isIconRegular}
        navigateBack
        onClick={() => resetSelectedPageId()}
        count={itemsCountByPageId[pageId]}
      />
      {/* Conditionally re-render the children to trigger height calculations */}
      {isSelected && (
        <Box
          sx={{
            pointerEvents: 'none',
            ...(!isAnimating && { pointerEvents: 'initial' }),
          }}
        >
          {children}
        </Box>
      )}
      <HorizontalDivider my={2} />
      <Flex
        flexDirection="row-reverse"
        py={2}
        px="12px"
        justifyContent="space-between"
      >
        <Button onClick={closeMenu} variant="primary" small>
          {t('done')}
        </Button>
        {hasItems && (
          <Button
            onClick={clearFilter}
            variant="secondary-outline"
            small
            iconLeft="xmark"
          >
            {t('general.clear', { ns: 'translation' })}
          </Button>
        )}
      </Flex>
    </SlidingBox>
  );
};
