export enum NotificationDomain {
  RFQ_RECEIVED = 'rfqReceived',
  RFQ_SENT = 'rfqSent',
  CONTRACT_RECEIVED = 'contractReceived',
  CONTRACT_SENT = 'contractSent',
  QUESTIONNAIRE_RECEIVED = 'questionnaireReceived',
  QUESTIONNAIRE_SENT = 'questionnaireSent',
  COMPANY_SETTINGS = 'companySettings',
  TEAM_MANAGEMENT = 'teamManagement',
  APP_ADMIN = 'appAdmin',
  SUPPLIER_MANAGEMENT = 'supplierManagement',
  USER_REPORT = 'userReport',
  /** @deprecated */
  PQQ_RECEIVED = 'pqqReceived',
  /** @deprecated */
  PQQ_SENT = 'pqqSent',
  /** @deprecated */
  CLIENT_COMPANIES = 'clientCompanies',
}

export enum NotificationAction {
  RFQ_CREATED = 'rfqCreated',
  /** @deprecated */
  RFQ_UPDATED = 'rfqUpdated',
  /** @deprecated */
  NEW_MESSAGE_RFQ = 'newMessageRfq',
  NEW_MEMBER = 'newMember',
  REMOVED_MEMBER = 'removedMember',
  RFQ_MEMBER_ROLES_UPDATED = 'rfqMemberRolesUpdated',
  /** @deprecated */
  NEW_RECIPIENT = 'newRecipient',
  RFQ_AWARDED = 'rfqAwarded',
  RFQ_CLOSED = 'rfqClosed',
  RFQ_LOST = 'rfqLost',
  BIDS_REJECTED = 'bidsRejected',
  BIDS_REINSTATED = 'bidsReinstated',
  EXCHANGE_REPLY_SENT = 'exchangeReplySent',
  EXCHANGE_REPLIES_SENT = 'exchangeRepliesSent',
  /** @deprecated */
  INTENTION_TO_BID = 'intentionToBid',
  RFQ_COMMENT_ADDED = 'rfqCommentAdded',
  /** @deprecated */
  QUICK_REPLY = 'quickReply',
  MEMBERSHIP_REQUEST_DECISION = 'requestDecision',
  REQUEST_BULLETIN_ADDED = 'requestBulletinAdded',
  REQUEST_BULLETIN_UPDATED = 'requestBulletinUpdated',
  REQUEST_BULLETINS_ADDED = 'requestBulletinsAdded',
  REQUESTED_TO_JOIN = 'requestedToJoin',
  USER_PERMISSION_CHANGED = 'userPermissionChanged',
  USER_REMOVED_FROM_TEAM = 'userRemovedFromTeam',
  USER_ADDED_TO_TEAM = 'userAddedToTeam',
  NEW_USER_SIGNED_UP = 'newUserSignedUp',
  USER_CREATED_NEW_COMPANY = 'userCreatedNewCompany',
  USER_CLAIMED_COMPANY = 'userClaimedCompany',
  USER_REQUESTED_TO_JOIN_COMPANY = 'userRequestedToJoinCompany',
  COMPANY_ADMIN_ACCEPTED_REQUEST_TO_JOIN = 'companyAdminAcceptedRequestToJoin',
  /** @deprecated */
  USER_UPLOADED_SUPPLIER_LIST_FILE = 'userUploadedSupplierListFile',
  /** @deprecated */
  BULLETIN_QUESTION_POSTED = 'bulletinQuestionPosted',
  /** @deprecated */
  BULLETIN_SIMPLE_CLARIFICATION = 'bulletinSimpleClarificationPosted',
  /** @deprecated */
  BULLETIN_ANSWER_TO_QUESTION_POSTED = 'bulletinAnswerToQuestionPosted',
  /** @deprecated */
  BULLETIN_CLARIFICATION_TO_QUESTION_POSTED = 'bulletinClarificationToQuestionPosted',
  SUPPLIER_INVITED_CREATES_ACCOUNT = 'supplierInvitedCreatesAccount',
  /** @deprecated */
  PQQ_ISSUED = 'pqqIssued',
  APPROVAL_REQUESTED = 'approvalRequested',
  APPROVAL_CANCELLED = 'approvalCancelled',
  APPROVAL_DECIDED = 'approvalDecided',
  /** @deprecated */
  PQQ_ACCESS_GRANTED = 'pqqAccessGranted',
  UPCOMING_AUCTION = 'upcomingAuction',
  AUCTION_RESCHEDULED = 'auctionRescheduled',
  AUCTION_REVISED = 'auctionRevised',
  AUCTION_STARTED = 'auctionStarted',
  EVALUATION_REMINDER = 'evaluationReminder',

  CONTRACT_SENT = 'notificationContractSent',
  CONTRACT_REVISED = 'notificationContractRevised',
  CONTRACT_TERMINATED = 'notificationContractTerminated',
  CONTRACT_FAILED = 'notificationContractFailed',
  CONTRACT_AMENDED = 'notificationContractAmended',
  CONTRACT_EXCHANGE_REPLY_SENT = 'notificationContractExchangeReplySent',
  CONTRACT_PAGE_APPROVALS = 'notificationContractPageApprovals',
  CONTRACT_TEAM_MEMBER_ADDED = 'notificationContractTeamMemberAdded',
  CONTRACT_TEAM_MEMBER_REMOVED = 'notificationContractTeamMemberRemoved',
  CONTRACT_TEAM_MEMBER_ROLES_UPDATED = 'notificationContractTeamMemberRolesUpdated',
  CONTRACT_E_SIGNATURE_APPROVAL_REQUIRED = 'notificationContractESignatureApprovalRequired',
  CONTRACT_E_SIGNATURE_REQUIRED = 'notificationContractESignatureRequired',
  CONTRACT_E_SIGNATURE_COMPLETE = 'notificationContractESignatureComplete',
  CONTRACT_E_SIGNATURE_REMINDER = 'notificationContractESignatureReminder',
  CONTRACT_E_SIGNATURE_REJECTED = 'notificationContractESignatureRejected',

  QUESTIONNAIRES_SEND_COMPLETED = 'notificationQuestionnairesSendCompleted',
  QUESTIONNAIRE_RECEIVED = 'notificationQuestionnaireReceived',
  QUESTIONNAIRE_ACCEPTED = 'notificationQuestionnaireAccepted',
  QUESTIONNAIRE_DECLINED = 'notificationQuestionnaireDeclined',
  QUESTIONNAIRE_SUBMITTED = 'notificationQuestionnaireSubmitted',
  QUESTIONNAIRE_UPDATED = 'notificationQuestionnaireUpdated',
  QUESTIONNAIRE_APPROVED = 'notificationQuestionnaireApproved',
  QUESTIONNAIRE_REJECTED = 'notificationQuestionnaireRejected',
  QUESTIONNAIRE_MARKED_AS_OBSOLETE = 'notificationQuestionnaireMarkedAsObsolete',
  QUESTIONNAIRE_REOPENED = 'notificationQuestionnaireReopened',
  QUESTIONNAIRE_RESUBMISSION_REQUESTED = 'notificationQuestionnaireResubmissionRequested',
  QUESTIONNAIRE_TEAM_MEMBER_ADDED = 'notificationQuestionnaireTeamMemberAdded',
  QUESTIONNAIRE_TEAM_MEMBER_ROLES_UPDATED = 'notificationQuestionnaireTeamMemberRolesUpdated',
  QUESTIONNAIRE_TEAM_MEMBER_REMOVED = 'notificationQuestionnaireTeamMemberRemoved',
  QUESTIONNAIRE_DOCUMENT_EXPIRED = 'notificationQuestionnaireDocumentExpired',
  QUESTIONNAIRE_UPCOMING_DOCUMENT_EXPIRY = 'notificationQuestionnaireUpcomingDocumentExpiry',
  QUESTIONNAIRE_REVISED = 'notificationQuestionnaireRevised',
  QUESTIONNAIRE_NAME_UPDATED = 'notificationQuestionnaireNameUpdated',
  QUESTIONNAIRE_RENEWAL_REQUIRED = 'notificationQuestionnaireRenewalRequired',
  QUESTIONNAIRE_UPCOMING_RENEWAL = 'notificationQuestionnaireUpcomingRenewal',
  QUESTIONNAIRE_MORE_INFO_REQUIRED = 'notificationQuestionnaireMoreInfoRequired',
  QUESTIONNAIRE_EXPIRED = 'notificationQuestionnaireExpired',
  QUESTIONNAIRES_REVISION_COMPLETED = 'notificationQuestionnairesRevisionCompleted',

  USER_REPORT_COMPLETED = 'notificationUserReportCompleted',
  USER_REPORT_FAILED = 'notificationUserReportFailed',

  /** @deprecated */
  SMOKE_TEST = 'smokeTest', // Deprecated
  /** @deprecated */
  BID_SENT = 'bidSent',
  /** @deprecated */
  CLIENT_COMPANY_REQUEST_SENT = 'buyerRequestedToAddClient',
  /** @deprecated */
  CLIENT_COMPANY_CLIENT_REPLIED_TO_REQUEST = 'clientRepliedToRequest',
}
