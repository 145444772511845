import { CombinedBidStatus, PageStatus, StageApprovalStatus, ApprovalResponseStatus, RfqStatus } from './types';

export interface StatusIconConfig {
  value: string; // fontAwesome 5 used in react component
  color?: string; // color name defined in @deepstream/ui/src/theme.ts
  isRegular?: boolean;
  isSharp?: boolean;
}

export interface StatusConfig<TStatus extends CombinedBidStatus | PageStatus | StageApprovalStatus | ApprovalResponseStatus> {
  status: TStatus;
  badgeClass?: string;
  isDeprecated?: boolean;
  icon?: StatusIconConfig;
}

export interface StatusesConfig<
  TStatus extends CombinedBidStatus | PageStatus | StageApprovalStatus | ApprovalResponseStatus,
> {
  [key: string]: StatusConfig<TStatus>;
}

export const pageStatusesConfig: StatusesConfig<PageStatus> = {
  [PageStatus.COMPLETE]: {
    // 'Complete'
    status: PageStatus.COMPLETE,
    icon: {
      color: 'success',
      value: 'check-circle',
    },
  },
  [PageStatus.WAITING]: {
    // 'Incomplete'
    status: PageStatus.WAITING,
    icon: {
      color: 'subtext',
      value: 'circle-dashed',
    },
  },
  [PageStatus.REVIEW]: {
    // 'Action Required', not user-specific
    status: PageStatus.REVIEW,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
  [PageStatus.EMPTY]: {
    // undefined
    status: PageStatus.EMPTY,
  },
  [PageStatus.ACTION_REQUIRED]: {
    // 'Action Required', user-specific: current user can respond
    status: PageStatus.REVIEW,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
  [PageStatus.WAITING_FOR_TEAM]: {
    // 'Waiting for team', user-specific: current user cannot respond
    status: PageStatus.REVIEW,
    icon: {
      value: 'spinner',
      color: 'subtext',
    },
  },
};

export const combinedBidStatusesConfig: StatusesConfig<CombinedBidStatus> = {
  [CombinedBidStatus.WITHDRAWN]: {
    // 'Withdrawn'
    status: CombinedBidStatus.WITHDRAWN,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [CombinedBidStatus.NO_RESPONSE]: {
    // 'No response'
    status: CombinedBidStatus.NO_RESPONSE,
    badgeClass: 'badge-warning',
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [CombinedBidStatus.NO_INTEND_TO_BID]: {
    // 'Declined to bid'
    status: CombinedBidStatus.NO_INTEND_TO_BID,
    badgeClass: 'badge-secondary',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
  [CombinedBidStatus.ACTIVE]: {
    // 'Active'
    status: CombinedBidStatus.ACTIVE,
    badgeClass: 'badge-primary',
    icon: {
      value: 'circle',
      color: 'success',
    },
  },
  [CombinedBidStatus.AWARDED]: {
    // 'Awarded'
    status: CombinedBidStatus.AWARDED,
    badgeClass: 'badge-success',
    icon: {
      color: 'warning',
      value: 'trophy',
    },
  },
  [CombinedBidStatus.UNSUCCESSFUL]: {
    // 'Unsuccessful'
    status: CombinedBidStatus.UNSUCCESSFUL,
    badgeClass: 'badge-danger',
    icon: {
      color: 'subtext',
      value: 'times',
    },
  },
};

// override the icons and labels for supplier view
export const combinedSupplierBidStatuses: StatusesConfig<CombinedBidStatus> = {
  ...combinedBidStatusesConfig,
  [CombinedBidStatus.NO_RESPONSE]: {
    // 'No response'
    status: CombinedBidStatus.NO_RESPONSE,
    icon: {
      color: 'danger',
      value: 'exclamation-circle',
    },
  },
};

export const stageApprovalStatuses: StatusesConfig<StageApprovalStatus> = {
  [StageApprovalStatus.PENDING]: {
    status: StageApprovalStatus.PENDING,
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [StageApprovalStatus.COMPLETE]: {
    status: StageApprovalStatus.COMPLETE,
    icon: {
      color: 'primary',
      isRegular: true,
      value: 'check-circle',
    },
  },
  [StageApprovalStatus.CANCELLED]: {
    status: StageApprovalStatus.CANCELLED,
    icon: {
      color: 'subtext',
      value: 'xmark',
      isSharp: true,
    },
  },
};

export const approvalResponseStatus: StatusesConfig<ApprovalResponseStatus> = {
  [ApprovalResponseStatus.PENDING]: {
    status: ApprovalResponseStatus.PENDING,
    icon: {
      color: 'secondary',
      value: 'circle',
      isRegular: true,
    },
  },
  [ApprovalResponseStatus.APPROVED]: {
    status: ApprovalResponseStatus.APPROVED,
    icon: {
      color: 'success',
      isRegular: true,
      value: 'check-circle',
    },
  },
  [ApprovalResponseStatus.REJECTED]: {
    status: ApprovalResponseStatus.REJECTED,
    icon: {
      color: 'danger',
      value: 'circle-xmark',
      isRegular: true,
    },
  },
};

export const requestStatusesConfig = {
  [RfqStatus.AWARDED]: {
    status: RfqStatus.AWARDED,
    icon: {
      value: 'trophy',
      color: 'warning',
    },
  },
  [RfqStatus.LIVE]: {
    status: RfqStatus.LIVE,
    icon: {
      value: 'circle',
      color: 'success',
    },
  },
  [RfqStatus.CLOSED]: {
    status: RfqStatus.CLOSED,
    icon: {
      value: 'times',
      color: 'subtext',
    },
  },
  [RfqStatus.DRAFT]: {
    status: RfqStatus.DRAFT,
    icon: {
      value: 'pencil',
      color: 'subtext',
    },
  },
  [RfqStatus.DELETED]: {
    status: RfqStatus.DELETED,
    icon: {
      value: 'trash',
      color: 'subtext',
    },
  },
  [RfqStatus.DEADLINES_PASSED]: {
    status: RfqStatus.DEADLINES_PASSED,
    icon: {
      value: 'clock-o',
      color: 'danger',
    },
  },
} as const;

export const LIVE_SENT_REQUESTS_STATUSES = [
  RfqStatus.LIVE,
  RfqStatus.DEADLINES_PASSED,
  RfqStatus.AWARDED,
  RfqStatus.CLOSED,
];
