import { useMemo } from 'react';
import { some } from 'lodash';
import { CompanyMinimized, getCombinedBidStatus } from '@deepstream/common/rfq-utils';
import { PropertyList } from '@deepstream/ui/PropertyList';
import * as rfx from '@deepstream/ui/rfx';
import { getCombinedBidStatusLabels } from '@deepstream/common/rfq-utils/statusLabels';
import { useTranslation } from 'react-i18next';
import { RfxBid } from '@deepstream/ui/types';

const getCurrentStage = (bid: RfxBid) => {
  if (!bid || !bid.stageId) return '';

  return bid.enteredStageIds.findIndex(id => id === bid.stageId) + 1;
};

export const RequestCompanyPropertyList = ({ company }: {
  company: CompanyMinimized;
}) => {
  const { t } = useTranslation();
  const structure = rfx.useStructure();

  const {
    combinedBidStatusToLabel,
  } = useMemo(() => ({
    combinedBidStatusToLabel: getCombinedBidStatusLabels(t),
  }), [t]);

  const requestCompanyProperties = useMemo(
    () => {
      const getCombinedBidStatusLabel = (bid: RfxBid) => {
        return combinedBidStatusToLabel[getCombinedBidStatus(bid)];
      };

      const isSender = some(structure.senders, { _id: company._id });

      if (isSender) {
        return [
          { name: 'Role', value: 'Sender' },
          { name: 'Collaborator role', value: company.role },
        ];
      } else {
        const bid = structure.bidById[company._id];

        return [
          { name: 'Role', value: 'Recipient' },
          { name: 'Bid status', value: getCombinedBidStatusLabel(bid) },
          { name: 'Stage', value: getCurrentStage(bid) },
        ];
      }
    },
    [company, structure, combinedBidStatusToLabel],
  );

  return (
    <PropertyList properties={requestCompanyProperties} />
  );
};
