import { AxiosRequestConfig } from 'axios';
import { v5 as uuidv5 } from 'uuid';
import { forEach, findIndex, groupBy, get, fromPairs, map, sortBy, identity, size, omit } from 'lodash';
import uniqolor from 'uniqolor';
import { AnyScope, Company, StageType } from '@deepstream/common/rfq-utils';
import { lighten } from 'polished';
import { TFunction } from 'react-i18next';
import { StructureAuctionStage, StructureGeneralStage, StructureStage } from './types';

export const selectFilesForUpload = ({ multiple = false } = {}): Promise<FileList> =>
  new Promise((resolve) => {
    const input = document.createElement('input');
    input.id = 'file-upload-input';
    input.type = 'file';
    input.style.display = 'none';
    input.multiple = multiple;

    input.addEventListener('change', (event: any) => {
      const files = event.target.files as FileList;
      resolve(files);
    });

    input.click();
  });

export const uploadFile = async (
  file: any,
  uploadFunction: any,
  companyId: string,
  purpose: string,
  onProgress: AxiosRequestConfig['onUploadProgress'],
  toaster: any,
  defaultErrorMessage: string,
) => {
  try {
    const uploaded = await uploadFunction({ companyId, purpose, file, onProgress });
    return uploaded;
  } catch (error: any) {
    if (error.response.data && error.response.data.startsWith('Error: ')) {
      toaster.error(error.response.data.split('Error: ')[1]);
    } else {
      toaster.error(defaultErrorMessage);
    }
    return null;
  }
};

/**
 * Builds a URLSearchParams object. It also handles array values.
 */
export const buildQueryParams = (data: Record<string, string | string[]>) => {
  const params = new URLSearchParams();

  forEach(
    data,
    (value, key) => {
      if (Array.isArray(value)) {
        forEach(
          value,
          item => params.append(key, item),
        );
      } else {
        params.append(key, value);
      }
    },
  );

  return params;
};

export const getPreviousItem = <T extends Record<string, unknown>>(items: T[], selector): T | null => {
  const index = findIndex(items, selector);

  return index > 0 ? items[index - 1] : null;
};

export const getNextItem = <T extends Record<string, unknown>>(items: T[], selector): T | null => {
  const index = findIndex(items, selector);

  return index < items.length - 1 ? items[index + 1] : null;
};

export const createDeprecatedProductTagTree = (tags: any[]) => {
  const childrenByParentId = groupBy(tags, 'parent');

  const level1Tags = tags.filter(tag => tag.level === 1);

  return level1Tags.map(level1Tag => {
    return {
      ...level1Tag,
      children: (childrenByParentId[level1Tag._id] || []).map(level2Tag => {
        return {
          ...level2Tag,
          children: (childrenByParentId[level2Tag._id] || []).map(level3Tag => {
            return {
              ...level3Tag,
              children: [],
            };
          }),
        };
      }),
    };
  });
};

export const websiteRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,63}(:[0-9]{1,5})?(\/.*)?$/;
export const phoneNumberRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

// TODO remove `!('type' in stage) ||` once type is a required prop in GeneralStage
export const isGeneralStage = <Scope extends AnyScope>(stage: StructureStage<Scope>):
  stage is StructureGeneralStage<Scope> =>
    !('type' in stage) || stage.type === StageType.GENERAL;

export const isAuctionStage = <Scope extends AnyScope>(stage: StructureStage<Scope>):
  stage is StructureAuctionStage<Scope> =>
    stage.type === StageType.AUCTION;

// Since we're re-using react-table column objects we create a fake
// react-table "row" that will be passed through as a prop to the Cell
// components
//
// This does not have parity with react-table rows -- it's only functional
// enough to support our current Cell components.
export const createTableRow = ({
  index,
  data,
  columns,
}: {
  /** The row index. */
  index: number;
  /** The row data. */
  data: any;
  /** The column definitions. */
  columns: {
    id?: string;
    accessor: string | ((data: any, index?: number) => unknown);
  }[];
}) => {
  const getValue = (accessor : string | ((data: any, index?: number) => unknown)) =>
    accessor
      ? typeof accessor === 'string' ? get(data, accessor) : accessor(data, index)
      : null;

  return {
    index,
    original: data,
    values: fromPairs(map(
      columns,
      ({ id, accessor }) => [id ?? accessor, getValue(accessor)],
    )),
  };
};

export const generateColor = (seed: string) =>
  uniqolor(seed, { saturation: [0, 100], lightness: [60, 70] }).color;

export const lighten30 = lighten(0.3);

export const sortByName = (recipients: Company[]) =>
  sortBy(recipients, recipient => recipient.company.name.toLowerCase());

export const emailStringToArray = (value = '') => value
  .replace(/\s+/g, '')
  .split(',')
  .filter(identity);

const toTitleCase = (text: string) =>
  text.replace(
    /\w\S*/g,
    word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase(),
  );

const stripFileExtension = (filename) =>
  filename.replace(/\.[^/.]+$/, '');

export const getDocumentExchangeDescriptionFromFile = (file: File) => {
  const filename = stripFileExtension(file.name);

  // If file name is all UPPERCASE, transform it to Title Case
  return filename === filename.toUpperCase()
    ? toTitleCase(filename)
    : filename;
};

/**
 * Sets the value of a DOM input element rendered by React, notifying React about the
 * change.
 */
 export const triggerInputHack = (
  inputElement: HTMLInputElement & { _valueTracker?: { setValue: (value: string) => void } },
  value: string,
) => {
  const lastValue = inputElement.value;

  inputElement.value = value;

  // eslint-disable-next-line no-underscore-dangle
  inputElement._valueTracker?.setValue(lastValue);

  inputElement.dispatchEvent(new Event('input', { bubbles: true }));
};

export const sanitizePublishableEntity = <T extends object> (entity: T) =>
  omit(entity, ['isLive', 'liveVersion', 'creatorId']);

// This is just a random v4 uuid to provide a namespace for `getUuidV5`,
// see https://www.npmjs.com/package/uuid
const UUID_V_5_NAMESPACE = '2e3363cb-1e6b-4f8e-82be-55fb1305a9da';

export const getUuidV5 = (str: string) => uuidv5(str, UUID_V_5_NAMESPACE);

export const boolToLocaleString = ({
  value,
  locale,
  t,
  withUppercase,
}: {
  value: boolean;
  locale: string;
  t: TFunction;
  withUppercase?: boolean;
}) => {
  const translation = value
    ? t('true', { ns: 'general' })
    : t('false', { ns: 'general' });
  return withUppercase ? translation.toLocaleUpperCase(locale) : translation;
};
