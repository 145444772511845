import { useState } from 'react';
import { useMove } from 'react-aria';
import styled from 'styled-components';

const minColumnWidth = 70;

const ColumnResizeHandleWrapper = styled.div`
  top: 0;
  width: 16px;
  cursor: col-resize;
  touch-action: none;
  height: var(--frozen-header-height);
  position: absolute;

  & > div:first-child {
    opacity: 0;
  }

  &[data-is-moving] > div:first-child,
  &:hover > div:first-child {
    opacity: 1;
  }
`;

const ColumnResizeHandle = styled.div`
  background-color: ${props => props.theme.colors.primary};
  position: relative;
  width: 4px;
  height: 30px;
  top: 9px;
  left: 6px;
  border-radius: 2px;
`;

const ColumnResizeFullHeightBar = styled.div`
  background-color: ${props => props.theme.colors.primary};
  opacity: 0.2;
  position: relative;
  width: 4px;
  top: -30px;
  left: 6px;
  border-radius: 2px;
`;

export const ColumnResizer = ({
  zIndex,
  column,
  scrollAreaHeight,
  hasActiveColumnResize,
  setColumnResizeActiveIndex,
  onColumnResize,
}: {
  zIndex: number;
  column: {
    index: number;
    width: number;
    left: number;
    original: { _id: string };
  };
  scrollAreaHeight: number;
  hasActiveColumnResize: boolean;
  setColumnResizeActiveIndex: (index: number | null) => void;
  onColumnResize: (column: { id: string; width: number }) => void;
}) => {
  const [left, setLeft] = useState<number>(column.left + column.width - 8);

  const clamp = (left: number) => Math.max(column.left - 8 + minColumnWidth, left);

  const { moveProps } = useMove({
    onMoveStart() {
      setColumnResizeActiveIndex(column.index);
    },
    onMove(e) {
      setLeft(left => {
        return e.pointerType === 'keyboard'
          ? clamp(left) + e.deltaX
          : left + e.deltaX;
      });
    },
    onMoveEnd() {
      setLeft(left => {
        return clamp(left);
      });
      setColumnResizeActiveIndex(null);
      onColumnResize({
        id: column.original._id,
        // it should be sufficient to store whole numbers so we round
        width: Math.ceil(clamp(left) - column.left + 8),
      });
    },
  });

  return (
    <ColumnResizeHandleWrapper
      {...moveProps}
      role="separator"
      aria-orientation="horizontal"
      data-is-moving={hasActiveColumnResize || undefined}
      style={{
        zIndex,
        left: clamp(left),
      }}
    >
      <ColumnResizeHandle />
      {hasActiveColumnResize && (
        <ColumnResizeFullHeightBar
          style={{
            height: scrollAreaHeight,
          }}
        />
      )}
    </ColumnResizeHandleWrapper>
  );
};
