import { useTranslation } from 'react-i18next';
import { SxStyleProp } from 'rebass/styled-components';
import { IconText } from './IconText';

export const InternalOnly = ({
  fontSize,
  sx,
}: {
  fontSize?: number | string;
  sx?: SxStyleProp | undefined;
}) => {
  const { t } = useTranslation('general');

  return (
    <IconText
      icon="eye-slash"
      color="subtext"
      text={t('internalOnly')}
      fontSize={fontSize}
      sx={sx}
    />
  );
};
