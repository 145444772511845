import * as React from 'react';
import { isEmpty, isNil, isNumber, last, noop, omitBy, xor } from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer';
import clsx from 'clsx';

import { assertUnreachable } from '@deepstream/utils/assertUnreachable';
import { IS_SSR } from '@deepstream/ui-utils/isSsr';
import { getScrollbarSize } from '@deepstream/ui-utils/getScrollbarSize';
import { PatchedVariableSizeGrid } from './PatchedVariableSizeGrid';
import {
  ESTIMATED_DATA_CELL_HEIGHT,
  BORDER_ADJUSTMENT,
  DEFAULT_FROZEN_HEADER_HEIGHT,
  DEFAULT_FROZEN_FOOTER_HEIGHT,
  DEFAULT_NAVIGABLE_RANGE,
} from './constants';
import {
  CellIndices,
  ColumnData,
  FrozenHeaderCellProps,
  DataCellProps,
  ElementSize,
  TExpandableRowDataBase,
  TOriginalColumnDataBase,
  TOriginalSubRowDataBase,
  checkBounds,
  getGridCellId,
  NavigationTarget,
  GridDataAndCommands,
  getGridContainerId,
  EditedCell,
  SelectedRange,
  getSelectedRange,
  useSplitColumnData,
  NavigableRange,
  getMinNavigableStartIndices,
  SelectableRange,
  getMaxSelectableEndIndices,
  getMaxNavigableEndIndices,
} from './utils';
import { GridInnerElementType } from './GridInnerElementType';
import {
  getLastRowNextPage,
  getFirstRowPreviousPage,
  getScrollLeftForCellVisibility,
  getScrollTopForCellVisibility,
} from './scroll';
import { useGridData } from './useGridData';
import { RenderDataCellDefault } from './RenderDataCellDefault';
import { useHandleGridKeyDown } from './useHandleGridKeyDown';
import { FrozenPanelsContext, FrozenPanelsContextValue } from './FrozenPanels';
import { RenderFrozenFooterCell } from './RenderFrozenFooterCell';
import { SsrMockGrid } from './SsrMockGrid';

const OVERSCAN_COUNT = 1;

const getItemKey = ({ columnIndex, data, rowIndex }) =>
  `${data.rows[rowIndex]?.original._id}-${columnIndex}`;

export const getVisibleWidth = (
  containerSize: ElementSize,
  bodyPaddingLeft: number,
  frozenLeftColumnWidth: number,
) =>
  containerSize.width -
  frozenLeftColumnWidth -
  bodyPaddingLeft -
  BORDER_ADJUSTMENT -
  getScrollbarSize();

export const getVisibleHeight = (
  containerSize: ElementSize,
  focusedCellBottomOffset: number,
  hasFooter: boolean,
  frozenHeaderHeight: number,
  frozenFooterHeight: number,
) =>
  containerSize.height -
  frozenHeaderHeight -
  (hasFooter ? frozenFooterHeight : 0) -
  focusedCellBottomOffset -
  BORDER_ADJUSTMENT -
  getScrollbarSize();

const createFrozenFooter =
  <TOriginalColumnData extends TOriginalColumnDataBase>(data: {
    columns: ColumnData<TOriginalColumnData>[];
    FrozenFooterCell: React.FunctionComponent<FrozenHeaderCellProps<any>>;
    frozenLeftColumnWidth: number;
    frozenLeftColumnCount: number;
  }) =>
    () => {
      const lastColumn = last(data.columns);

      return lastColumn ? (
        <>
          <div className="frozen-footer-wrapper">
            {data.columns.map((column, index) => column && index >= data.frozenLeftColumnCount ? (
              <RenderFrozenFooterCell
                key={column.original._id}
                data={data}
                index={index}
                offsetX={data.frozenLeftColumnWidth}
              />
            ) : (
              null
            ))}
            <div
              className="right-footer-spacer"
              style={{
                position: 'absolute',
                top: 'calc(-1 * (var(--frozen-footer-height) + 1px))',
                left: `${lastColumn.left + lastColumn.width}px`,
                height: 'calc(var(--frozen-footer-height) + 1px)',
                right: 0,
              }}
            />
          </div>
          <div className="bottom-left-cell-wrapper">
            <div className="bottom-left-cell" style={{ width: `${data.frozenLeftColumnWidth}px` }} />
          </div>
        </>
      ) : (
        null
      );
    };

export interface ComparisonGridProps<
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
> {
  /**
   * The column data.
   */
  columnData: TOriginalColumnData[];
  /**
   * The row data. An array of expandable top-level rows with sub rows
   * defined in the `subRows` array.
   */
  rowData: TOriginalRowData[];
  /**
   * The height of a single sub row.
   */
  subRowHeight: number;
  /**
   * The IDs of the top-level expandable rows that are currently collapsed.
   */
  collapsedRowIds?: string[];
  /**
   * State-update callback to set the `collapsedRowIds` array.
   */
  setCollapsedRowIds?: React.Dispatch<React.SetStateAction<string[]>>;
  /**
   * Handler called each time when the user hits SPACE or ENTER on a
   * non-expandable data cell.
   */
  onDataCellKeyboardAction?: (
    data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => void;
  /**
   * When `true`, active cells cannot exceed the cell boundaries.
   * Otherwise, active cells can grow up to 20px on the bottom when
   * the content exceeds the cell boundaries.
   */
  staticRowHeights?: boolean;
  /**
   * The component to render in a data cell of the grid.
   */
  DataCell: React.FunctionComponent<
    DataCellProps<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>
  >;
  /**
   * The component to render in a cell of the frozen header of the grid.
   */
  FrozenHeaderCell: React.FunctionComponent<
    FrozenHeaderCellProps<TOriginalColumnData>
  >;
  /**
   * The component to render in a cell of the frozen footer of the grid.
   */
  FrozenFooterCell?: React.FunctionComponent<
    FrozenHeaderCellProps<TOriginalColumnData>
  >;
  /**
   * The component to render in a cell of the frozen left column of the grid.
   */
  FirstColumnCell: React.FunctionComponent<
    DataCellProps<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>
  >;
  /**
   * The component to render a content cell (including cells in the frozen left column).
   */
  RenderDataCell?: (props: {
    data: GridDataAndCommands<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData> & {
      DataCell: React.FunctionComponent<DataCellProps<any, any, any>>;
      FirstColumnCell: React.FunctionComponent<DataCellProps<any, any, any>>;
      bodyPaddingLeft: number;
      frozenLeftColumnCount: number;
    };
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
  }) => React.ReactElement | null;
  /**
   * Left padding of the grid body content.
   */
  bodyPaddingLeft?: number;
  /**
   * Right padding of the grid body content.
   */
  bodyPaddingRight?: number;
  /**
   * Bottom padding of the grid body content.
   */
  bodyPaddingBottom?: number;
  /**
   * The default width of a data cell / column in pixels, used
   * where a width is not set on an item in `columnData`.
   */
  defaultColumnWidth?: number;
  /**
   * The height of the frozen header in pixels.
   */
  frozenHeaderHeight?: number;
  /**
   * The height of the footer in pixels.
   */
  frozenFooterHeight?: number;
  /**
   * Custom start and end row / column indices of the cells that
   * the user can activate by mouse or keyboard navigation.
   */
  navigableRange?: NavigableRange;
  /**
   * Called whenever the active cell has changed.
   */
  onActiveCellChange?: () => void;
  /**
   * Called whenever the active row has changed.
   */
  onActiveRowChange?: (rowIndex: number) => void;
  /**
   * The prefix to prepend to each grid cell ID.
   * Can be omitted when there's only one grid on a page, otherwise
   * required to avoid duplicate IDs.
   */
  idPrefix?: string;
  /**
   * Called when one of the scroll offsets of the grid container changes.
   */
  onScroll?: () => void;
  /**
   * Row and column ID of the currently edited cell.
   */
  editedCell?: EditedCell | null;
  /**
   * Additional content to render in the frozen header of the grid.
   */
  AdditionalHeaderContent?: React.FC<{ height: number; width: number }> | null;
  /**
   * Additional content to render in the frozen left column of the grid.
   */
  FooterContent?: React.FC<{ height: number; width: number; idPrefix: string }> | null;
  /**
   * The minimum distance in pixels that a cell should have to the bottom of the view area
   * when focused.
   * When cells are expandable, this value should be adjusted to make sure that the full content
   * of the focused cell is shown, even if expanded.
   */
  focusedCellBottomOffset?: number;
  /**
   * The minimum and maximum column indices that a user can select.
   */
  selectableRange?: SelectableRange;
  /**
   * The reference to the grid element.
   */
  gridRef?: React.MutableRefObject<any>;

  canScrollHorizontally?: boolean;
  /**
   * The IDs of the columns that are pinned to the left.
   */
  frozenLeftColumnIds?: string[];
  onRowClick?: (row: TOriginalSubRowData | TOriginalRowData) => void;
  /**
   * When true, column and row headers will render as 'selected' when
   * they contain an active or selected cell.
   */
  highlightSelectionInHeaders?: boolean;
  /**
   * Called when the user has resized a column via the column resize
   * handle. Column resize handles are hidden when `onColumnResize`
   * is not defined.
   */
  onColumnResize?: (column: { id: string; width: number }) => void;
}

/**
 * Virtualized grid component with frozen header, frozen footer (optional),
 * frozen left column, expandable rows and controlled sub row height.
 *
 * The size of the grid is determined by the length of the `columnData`
 * and `rowData` props. The content of the data grid cells does not
 * get passed to this component. It can be derived lazily in the provided
 * `DataCell` component, based on the passed column and row data
 * properties.
 */
export const ComparisonGridBase = <
  TOriginalColumnData extends TOriginalColumnDataBase,
  TOriginalSubRowData extends TOriginalSubRowDataBase,
  TOriginalRowData extends TExpandableRowDataBase<TOriginalSubRowData>
>({
  columnData: columnDataProp,
  rowData,
  subRowHeight,
  collapsedRowIds = [],
  setCollapsedRowIds = noop,
  onDataCellKeyboardAction = noop,
  staticRowHeights,
  DataCell,
  FrozenHeaderCell,
  FrozenFooterCell,
  FirstColumnCell,
  RenderDataCell = RenderDataCellDefault,
  bodyPaddingLeft = 20,
  bodyPaddingRight = 20,
  bodyPaddingBottom = 20,
  defaultColumnWidth = 250,
  frozenFooterHeight = DEFAULT_FROZEN_FOOTER_HEIGHT,
  frozenHeaderHeight = DEFAULT_FROZEN_HEADER_HEIGHT,
  navigableRange = DEFAULT_NAVIGABLE_RANGE,
  onActiveCellChange,
  onActiveRowChange,
  idPrefix = 'grid',
  onScroll = noop,
  editedCell = null,
  AdditionalHeaderContent,
  FooterContent,
  focusedCellBottomOffset = bodyPaddingRight,
  selectableRange,
  gridRef: propsGridRef,
  frozenLeftColumnIds,
  onRowClick,
  highlightSelectionInHeaders,
  onColumnResize,
}: ComparisonGridProps<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData>) => {
  const containerRef = React.useRef<any>();
  const localGridRef = React.useRef<any>();
  const gridRef = propsGridRef || localGridRef;

  const [containerSize, setContainerSize] =
    React.useState<ElementSize | null>(null);

  const {
    columnData,
    frozenLeftColumnData,
    frozenLeftColumnWidth,
  } = useSplitColumnData(columnDataProp, frozenLeftColumnIds, defaultColumnWidth);

  const data = useGridData<
    TOriginalColumnData,
    TOriginalSubRowData,
    TOriginalRowData
  >(
    columnData,
    rowData,
    collapsedRowIds,
    subRowHeight,
    defaultColumnWidth,
  );

  const toggleCollapsedRowState = React.useCallback(
    (rowId: string, rowIndex: number) => {
      setCollapsedRowIds((collapsedRowIds) => xor(collapsedRowIds, [rowId]));

      if (gridRef.current) {
        gridRef.current.resetAfterRowIndex(rowIndex);
      }
    },
    [gridRef, setCollapsedRowIds],
  );

  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterRowIndex(1);
    }
  }, [gridRef, subRowHeight]);

  const [activeCellIndices, setActiveCellIndices] =
    React.useState<CellIndices>(getMinNavigableStartIndices(navigableRange));

  const [hoverCellIndices, setHoverCellIndices] =
    React.useState<CellIndices | null>(null);

  const [selectedRange, setSelectedRange] =
    React.useState<SelectedRange | null>(null);

  React.useEffect(() => {
    setSelectedRange(previousSelectedRange => {
      if (!selectableRange || !previousSelectedRange) {
        return null;
      }

      const { start, end, reference } = previousSelectedRange;

      const maxSelectableEndIndices = getMaxSelectableEndIndices(
        data.rows.length,
        data.columns.length,
        navigableRange,
        selectableRange,
      );

      const newSelectedRange = {
        start: {
          columnIndex: Math.max(selectableRange.startColumnIndex, Math.min(start.columnIndex, maxSelectableEndIndices.columnIndex - 1)),
          rowIndex: Math.max(navigableRange.startRowIndex, Math.min(start.rowIndex, maxSelectableEndIndices.rowIndex - 1)),
        },
        end: {
          columnIndex: Math.min(end.columnIndex, maxSelectableEndIndices.columnIndex),
          rowIndex: Math.min(end.rowIndex, maxSelectableEndIndices.rowIndex),
        },
        reference: {
          columnIndex: Math.max(selectableRange.startColumnIndex, Math.min(reference.columnIndex, maxSelectableEndIndices.columnIndex - 1)),
          rowIndex: Math.max(navigableRange.startRowIndex, Math.min(reference.rowIndex, maxSelectableEndIndices.rowIndex - 1)),
        },
      };

      return (
        newSelectedRange.start.rowIndex < newSelectedRange.end.rowIndex &&
        newSelectedRange.start.columnIndex < newSelectedRange.end.columnIndex
      )
        ? newSelectedRange
        : null;
    });

    setActiveCellIndices(previousActiveCellIndices => {
      if (!previousActiveCellIndices) {
        return previousActiveCellIndices;
      }

      const { columnIndex, rowIndex } = previousActiveCellIndices;

      const maxNavigationEndIndices = getMaxNavigableEndIndices(data.rows.length, data.columns.length, navigableRange);

      const newActiveCellIndices = {
        columnIndex: Math.max(navigableRange.startColumnIndex, Math.min(columnIndex, maxNavigationEndIndices.columnIndex - 1)),
        rowIndex: Math.max(navigableRange.startRowIndex, Math.min(rowIndex, maxNavigationEndIndices.rowIndex - 1)),
      };

      return newActiveCellIndices;
    });
  }, [
    data.columns.length,
    data.rows.length,
    navigableRange,
    selectableRange,
  ]);

  const handleActiveCellChange = React.useCallback((
    cellIndices,
    event,
    keepSelectedRange?: boolean | null,
    bottomRightCellIndices?: CellIndices | null,
  ) => {
    setActiveCellIndices((previousCellIndices) => {
      const newCellIndices = {
        ...previousCellIndices,
        ...cellIndices,
      };
      onActiveRowChange?.(newCellIndices.rowIndex);

      if (selectableRange) {
        if (bottomRightCellIndices) {
          setSelectedRange({
            start: newCellIndices,
            end: bottomRightCellIndices,
            reference: newCellIndices,
          });
        } else if (event?.shiftKey) {
          const selectedRange = getSelectedRange(previousCellIndices, newCellIndices);

          if (
            !selectedRange ||
            (
              selectedRange.start.columnIndex >= selectableRange.startColumnIndex &&
              (!selectableRange.endColumnIndex || selectedRange.end.columnIndex <= selectableRange.endColumnIndex)
            )
          ) {
            setSelectedRange(previousSelectedRange => ({
              ...selectedRange!,
              reference: previousSelectedRange?.reference || previousCellIndices,
            }));
          }

          // when selecting a new range, don't update the active cell
          return previousCellIndices;
        } else if (
          !keepSelectedRange &&
          // when activating a cell that's not selectable (for example, in the action column),
          // or when the active cell hasn't changed, don't reset the selected range
          (
            newCellIndices.columnIndex >= selectableRange.startColumnIndex &&
            (!selectableRange.endColumnIndex || newCellIndices.columnIndex <= selectableRange.endColumnIndex)
          ) &&
          (newCellIndices.rowIndex !== previousCellIndices.rowIndex || newCellIndices.columnIndex !== previousCellIndices.columnIndex)
        ) {
          setSelectedRange(null);
        }
      }

      return newCellIndices;
    });
  }, [setActiveCellIndices, onActiveRowChange, setSelectedRange, selectableRange]);

  const selectAll = React.useCallback(() => {
    if (selectableRange && data.columns.length > 0 && data.rows.length > 0) {
      setSelectedRange(previousSelectedRange => {
        const start = {
          rowIndex: navigableRange.startRowIndex,
          columnIndex: selectableRange.startColumnIndex,
        };
        const reference = previousSelectedRange?.reference || start;
        const end = getMaxSelectableEndIndices(data.rows.length, data.columns.length, navigableRange, selectableRange);

        return { start, end, reference };
      });
    }
  }, [data, navigableRange, selectableRange]);

  const activateCellAndEnsureVisibility = React.useCallback(
    (
      cellIndices: Partial<CellIndices>,
      target?: NavigationTarget | null,
      event?: React.MouseEvent<unknown, MouseEvent> | React.KeyboardEvent<unknown> | null,
      keepSelectedRange?: boolean | null,
      bottomRightCellIndices?: CellIndices | null,
    ) => {
      if (!gridRef.current || !containerSize) {
        return;
      }

      if (!isNil(target)) {
        let getRowOnPage: any;

        switch (target) {
          case NavigationTarget.LAST_ROW_NEXT_PAGE:
            getRowOnPage = getLastRowNextPage;
            break;
          case NavigationTarget.FIRST_ROW_PREVIOUS_PAGE:
            getRowOnPage = getFirstRowPreviousPage;
            break;
          default:
            assertUnreachable(target);
        }

        const row = getRowOnPage({
          currentScrollTop: gridRef.current.state.scrollTop,
          visibleHeight: getVisibleHeight(
            containerSize,
            focusedCellBottomOffset,
            Boolean(FrozenFooterCell),
            frozenHeaderHeight,
            frozenFooterHeight,
          ),
          rows: data.rows,
        });

        if (!row) {
          return;
        }

        const { scrollTop, rowIndex } = row;

        handleActiveCellChange({ ...cellIndices, rowIndex }, event, keepSelectedRange);

        gridRef.current.scrollTo({ scrollTop });

        onActiveCellChange?.();

        return;
      }

      if (!cellIndices || !checkBounds(cellIndices, navigableRange, data)) {
        return;
      }

      handleActiveCellChange(cellIndices, event, keepSelectedRange, bottomRightCellIndices);

      const targetLeft = !isNumber(cellIndices.columnIndex) ? (
        null
      ) : cellIndices.columnIndex < frozenLeftColumnData.length ? (
        0
      ) : (
        data.columns[cellIndices.columnIndex].left - frozenLeftColumnWidth
      );
      const targetWidth = !isNumber(cellIndices.columnIndex) ? (
        null
      ) : cellIndices.columnIndex < frozenLeftColumnData.length ? (
        0
      ) : (
        data.columns[cellIndices.columnIndex].width
      );

      const scrollLeft = isNumber(targetLeft) && isNumber(targetWidth)
        ? getScrollLeftForCellVisibility({
          currentScrollLeft: gridRef.current.state.scrollLeft,
          visibleWidth: getVisibleWidth(containerSize, bodyPaddingLeft, frozenLeftColumnWidth),
          targetLeft,
          targetWidth,
        })
        : null;

      const targetTop = isNumber(cellIndices.rowIndex) && data.rows[cellIndices.rowIndex]
        ? data.rows[cellIndices.rowIndex!]!.top
        : null;
      const targetHeight = isNumber(cellIndices.rowIndex) && data.rows[cellIndices.rowIndex]
        ? data.rows[cellIndices.rowIndex!]!.height
        : null;

      const scrollTop = isNumber(targetTop) && isNumber(targetHeight)
        ? getScrollTopForCellVisibility({
          currentScrollTop: gridRef.current.state.scrollTop,
          visibleHeight: getVisibleHeight(
            containerSize,
            focusedCellBottomOffset,
            Boolean(FrozenFooterCell),
            frozenHeaderHeight,
            frozenFooterHeight,
          ),
          targetTop,
          targetHeight,
        })
        : null;

      const newScrollPosition = omitBy({ scrollLeft, scrollTop }, isNil);

      if (!isEmpty(newScrollPosition)) {
        gridRef.current.scrollTo(newScrollPosition);
      }

      onActiveCellChange?.();
    },
    [
      gridRef,
      containerSize,
      navigableRange,
      data,
      bodyPaddingLeft,
      frozenLeftColumnWidth,
      FrozenFooterCell,
      frozenHeaderHeight,
      frozenFooterHeight,
      onActiveCellChange,
      handleActiveCellChange,
      focusedCellBottomOffset,
      frozenLeftColumnData,
    ],
  );

  const dataAndCommands = React.useMemo(
    () => ({
      ...data,
      highlightSelectionInHeaders,
      activeCellIndices,
      hoverCellIndices,
      setHoverCellIndices,
      selectedRange,
      activateCellAndEnsureVisibility,
      toggleCollapsedRowState,
      staticRowHeights,
      idPrefix,
      editedCell,
      onRowClick,
    }),
    [
      data,
      highlightSelectionInHeaders,
      activeCellIndices,
      hoverCellIndices,
      setHoverCellIndices,
      selectedRange,
      activateCellAndEnsureVisibility,
      toggleCollapsedRowState,
      staticRowHeights,
      idPrefix,
      editedCell,
      onRowClick,
    ],
  );

  const dataCellItemData = React.useMemo(
    () => ({
      ...dataAndCommands,
      DataCell,
      FirstColumnCell,
      bodyPaddingLeft,
      frozenLeftColumnWidth,
      frozenLeftColumnCount: frozenLeftColumnData.length,
    }),
    [dataAndCommands, DataCell, FirstColumnCell, bodyPaddingLeft, frozenLeftColumnWidth, frozenLeftColumnData.length],
  );

  const [hasHorizontalScroll, setHasHorizontalScroll] = React.useState(false);
  const [hasVerticalScroll, setHasVerticalScroll] = React.useState(false);

  const handleDataGridScroll = React.useCallback(
    (event) => {
      setHasHorizontalScroll(event.scrollLeft > 0);
      setHasVerticalScroll(event.scrollTop > 0);
      onScroll();
    },
    [setHasHorizontalScroll, setHasVerticalScroll, onScroll],
  );

  const handleGridKeyDown = useHandleGridKeyDown(
    dataAndCommands,
    onDataCellKeyboardAction,
    selectAll,
    navigableRange,
    selectableRange,
  );

  const rowsSize = data.rows.length;
  const columnsSize = data.columns.length;
  const activeDescendant = React.useMemo(
    () =>
      activeCellIndices ? getGridCellId(idPrefix, activeCellIndices) : undefined,
    [idPrefix, activeCellIndices],
  );

  const panelsContext: FrozenPanelsContextValue<TOriginalColumnData, TOriginalSubRowData, TOriginalRowData> = React.useMemo(
    () => ({
      data,
      FrozenFooter: FrozenFooterCell
        ? createFrozenFooter({
          columns: data.columns,
          FrozenFooterCell,
          frozenLeftColumnWidth,
          frozenLeftColumnCount: frozenLeftColumnData.length,
        })
        : null,
      bodyPaddingRight,
      bodyPaddingBottom,
      AdditionalHeaderContent,
      FooterContent,
      frozenLeftColumnWidth,
      idPrefix,
      onColumnResize,
    }),
    [
      data,
      FrozenFooterCell,
      bodyPaddingRight,
      bodyPaddingBottom,
      AdditionalHeaderContent,
      FooterContent,
      frozenLeftColumnWidth,
      frozenLeftColumnData.length,
      idPrefix,
      onColumnResize,
    ],
  );

  const [containerTabIndex, setContainerTabIndex] = React.useState(0);

  React.useEffect(() => {
    const activeDescendantElement = activeDescendant
      ? document.getElementById(activeDescendant)
      : null;

    if (activeDescendantElement) {
      const inputs = activeDescendantElement.getElementsByTagName('input');

      if (inputs[0]) {
        inputs[0].focus();
        setContainerTabIndex(-1);
      } else {
        setContainerTabIndex(0);
        containerRef.current?.focus();
      }
    }
  }, [activeDescendant]);

  return (
    <FrozenPanelsContext.Provider value={panelsContext}>
      {IS_SSR ? (
        <div // eslint-disable-line
          id={getGridContainerId(idPrefix)}
          ref={containerRef}
          role="treegrid"
          tabIndex={containerTabIndex}
          onKeyDown={handleGridKeyDown}
          aria-rowcount={rowsSize}
          aria-colcount={columnsSize}
          aria-activedescendant={activeDescendant}
          style={{ width: '100%', height: '100%' }}
        >
          <SsrMockGrid
            data={data}
            RenderDataCell={RenderDataCell}
            FrozenHeaderCell={FrozenHeaderCell}
            dataCellItemData={dataCellItemData}
            frozenHeaderHeight={frozenHeaderHeight}
          />
        </div>
      ) : (
        <AutoSizer onResize={setContainerSize}>
          {({ height, width }) => (
            // eslint-disable-next-line jsx-a11y/aria-activedescendant-has-tabindex
            <div
              id={getGridContainerId(idPrefix)}
              ref={containerRef}
              className={clsx({
                'horizontal-scroll': hasHorizontalScroll,
                'vertical-scroll': hasVerticalScroll,
              })}
              role="treegrid"
              tabIndex={containerTabIndex}
              onKeyDown={handleGridKeyDown}
              aria-rowcount={rowsSize}
              aria-colcount={columnsSize}
              aria-activedescendant={activeDescendant}
              style={{ width, height }}
            >
              <PatchedVariableSizeGrid
                innerElementType={GridInnerElementType}
                itemData={dataCellItemData}
                itemKey={getItemKey}
                height={height - BORDER_ADJUSTMENT}
                width={width - BORDER_ADJUSTMENT}
                columnCount={columnsSize}
                columnWidth={(index) => data.columns[index]?.width ?? defaultColumnWidth}
                estimatedColumnWidth={defaultColumnWidth}
                rowCount={rowsSize}
                rowHeight={(index) => data.rows[index]?.height ?? frozenHeaderHeight}
                estimatedRowHeight={ESTIMATED_DATA_CELL_HEIGHT}
                overscanColumnCount={OVERSCAN_COUNT}
                overscanRowCount={OVERSCAN_COUNT}
                onScroll={handleDataGridScroll}
                ref={gridRef}
                // @ts-ignore TODO support FrozenHeaderCell in PatchedVariableSizeGrid props type
                FrozenHeaderCell={FrozenHeaderCell}
              >
                {RenderDataCell}
              </PatchedVariableSizeGrid>
            </div>
          )}
        </AutoSizer>
      )}
    </FrozenPanelsContext.Provider>
  );
};

export const ComparisonGrid = React.memo(
  ComparisonGridBase,
) as typeof ComparisonGridBase;
