import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { sum, values } from 'lodash';
import { transparentize } from 'polished';
import { ContractStatus, contractStatusConfig } from '@deepstream/common/contract';
import { Icon } from '@deepstream/ui-kit/elements/icon/Icon';
import { Truncate } from '@deepstream/ui-kit/elements/text/Truncate1';
import { OverflowTooltip, Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import { Panel } from '@deepstream/ui-kit/elements/Panel';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useContractsReportingData } from './useContractsReporting';
import { Bold } from '../../Bold';
import { CurrencyAmount } from '../../ui/Currency';

const IconContainer = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContractStatusStatPanel = ({
  type,
  status,
}: {
  type: 'volume' | 'value';
  status?: ContractStatus;
}) => {
  const { t } = useTranslation(['reporting', 'contracts']);
  const { data } = useContractsReportingData();
  const theme = useTheme();
  const { numContractsByStatus, contractValueByStatus } = data;

  const isTotal = !status;
  const numContracts = isTotal
    ? sum(values(numContractsByStatus)) || 0
    : numContractsByStatus[status] || 0;
  const contractsValue = isTotal
    ? sum(values(contractValueByStatus)) || 0
    : contractValueByStatus[status] || 0;
  const lightSuccessColor = transparentize(0.85, theme.colors.success);

  const iconContainerBackgroundColor = useMemo(
    () => {
      if (isTotal) {
        return 'lightGray';
      }

      switch (status) {
        case ContractStatus.ACTIVE:
          return lightSuccessColor;
        case ContractStatus.AGREED:
          return 'transparent';
        case ContractStatus.NEGOTIATION:
          return transparentize(0.85, theme.colors.warning);
        default:
      }
    },
    [isTotal, status, lightSuccessColor, theme.colors.warning],
  );

  return (
    <Panel height="190px" width="280px" p="20px">
      <Stack gap={3}>
        <IconContainer
          backgroundColor={iconContainerBackgroundColor}
          sx={{ border: status === ContractStatus.AGREED ? `1px solid ${lightSuccessColor}` : undefined }}
        >
          <Icon
            icon={isTotal ? 'globe-americas' : contractStatusConfig[status].icon.value}
            color={isTotal ? 'subtext' : contractStatusConfig[status].icon.color}
            fontSize={3}
          />
        </IconContainer>
        <Box color="subtext" fontSize={1} sx={{ textTransform: 'uppercase' }}>
          {isTotal ? (
            `${t('contractVolume.total')}*`
          ) : (
            t(`status.${status}`, { ns: 'contracts' })
          )}
        </Box>
        <Box fontSize={7}>
          <Bold>
            {type === 'volume' ? (
              numContracts
            ) : (
              <OverflowTooltip content={<CurrencyAmount value={contractsValue} />}>
                <Truncate>
                  <CurrencyAmount value={contractsValue} />
                </Truncate>
              </OverflowTooltip>
            )}
          </Bold>
        </Box>
      </Stack>
    </Panel>
  );
};
