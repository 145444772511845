import { useMemo } from 'react';
import { findIndex } from 'lodash';
import { renderStageName } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import * as rfx from '../rfx';

/**
 * When there are 2 or more stages, returns the numbered name of the stage
 * matching `stageId`.
 * If there are less than 2 stages or if a stage matching `stageId` couldn't
 * be found, returns null.
 */
export const useStageName = (stageId: string | undefined, allowSingleStage?: boolean) => {
  const { stages } = rfx.useStructure();
  const { t } = useTranslation();

  return useMemo(() => {
    if (!allowSingleStage && stages.length < 2) {
      return null;
    }

    const stageIndex = findIndex(stages, stage => stage._id === stageId);

    if (!stageId || stageIndex === -1) {
      return null;
    }

    const stage = stages?.[stageIndex];
    const name = renderStageName(stage, t);

    return name
      ? `${stageIndex + 1} – ${name}`
      : stageIndex + 1;
  }, [allowSingleStage, stageId, stages, t]);
};
