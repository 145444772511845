import { useLayoutEffect, useRef, useState, MutableRefObject } from 'react';

const getDistanceFromWindow = (element: HTMLElement | undefined, edge: Edge) => {
  if (element) {
    const rect = element.getBoundingClientRect();

    switch (edge) {
      case 'left': return rect.left;
      case 'right': return document.body.clientWidth - rect.right;
      case 'top': return rect.top;
      case 'bottom': return document.body.clientHeight - rect.bottom;
      default: throw new Error('Invalid edge');
    }
  }

  return 0;
};

type Edge = 'top' | 'right' | 'bottom' | 'left';

type UseDistanceFromWindow = (
  params: { edge: Edge; when: boolean }
) => [
  number,
  MutableRefObject<undefined | HTMLElement>,
];

// Track an element's distance from the right edge of the window
export const useDistanceFromWindow: UseDistanceFromWindow = ({
  edge,
  when: shouldTrack,
}) => {
  const [distance, setDistance] = useState(0);
  const elementRef = useRef();

  useLayoutEffect(
    () => {
      const onResize = () => {
        setDistance(getDistanceFromWindow(elementRef.current, edge));
      };

      if (shouldTrack) {
        window.addEventListener('resize', onResize);
        onResize();
      }

      return () => {
        window.removeEventListener('resize', onResize);
      };
    },
    [edge, shouldTrack],
  );

  return [distance, elementRef];
};
