import { ActionType } from './rfq-utils/types';

export interface ActionConfig {
  type?: ActionType;
  canObsolete?: boolean;
  description?: string;
  attachment?: 'required' | 'optional';
  icon?: string;
  iconBackgroundColor?: Color;
  textColor?: Color;
}

interface ActionsConfig {
  [key: string]: ActionConfig;
}

export enum Color {
  PRIMARY = '#3498db',
  DANGER = '#C86464',
  WARN = '#F39C12',
  SUCCESS = '#18BC9C',
  LIGHT_GRAY = '#bebebe',
  DARK_GRAY = '#333333',
}

export const actionsConfig: ActionsConfig = {
  [ActionType.NONE]: {
    // Commenting is a special case, so even though it sits next to the other
    // action buttons in the exchange modal, it's not considered a proper action
    canObsolete: false,
    description: '',
  },
  [ActionType.UNLOCK]: {
    canObsolete: false,
    icon: 'unlock-alt',
    iconBackgroundColor: Color.DARK_GRAY,
  },
  [ActionType.INITIATE]: {
    canObsolete: false,
  },
  [ActionType.ACCEPT]: {
    canObsolete: true,
    icon: 'check',
    iconBackgroundColor: Color.SUCCESS,
    textColor: Color.SUCCESS,
  },
  [ActionType.REJECT]: {
    canObsolete: true,
    icon: 'times',
    iconBackgroundColor: Color.DANGER,
    textColor: Color.DANGER,
  },
  [ActionType.DEVIATE]: {
    canObsolete: true,
    icon: 'deviate',
    iconBackgroundColor: Color.PRIMARY,
    textColor: Color.PRIMARY,
  },
  [ActionType.SUBMIT]: {
    canObsolete: true,
    icon: 'file',
    iconBackgroundColor: Color.SUCCESS,
    textColor: Color.SUCCESS,
  },
  [ActionType.COUNTERSIGN]: {
    canObsolete: true,
    icon: 'file',
    iconBackgroundColor: Color.SUCCESS,
    textColor: Color.SUCCESS,
  },
  [ActionType.REVISE]: {
    canObsolete: false,
  },
  [ActionType.OBSOLETE_ACTION]: {
    canObsolete: false,
    description: 'marked the previous action obsolete',
    icon: 'ban',
    iconBackgroundColor: Color.LIGHT_GRAY,
  },
  [ActionType.OBSOLETE_EXCHANGE]: {
    canObsolete: false,
    icon: 'ban',
    iconBackgroundColor: Color.LIGHT_GRAY,
  },
  [ActionType.CLOSE]: {
    canObsolete: true,
    icon: 'times',
    iconBackgroundColor: Color.LIGHT_GRAY,
  },
  [ActionType.RESOLVE]: {
    canObsolete: true,
    description: 'resolved the exchange',
    icon: 'check',
    iconBackgroundColor: Color.SUCCESS,
    textColor: Color.SUCCESS,
  },
  [ActionType.REFER_TO_BULLETIN]: {
    canObsolete: true,
    description: 'referred to the bulletin',
    icon: 'list-ul',
    iconBackgroundColor: Color.LIGHT_GRAY,
  },
  [ActionType.MARK_AS_AGREED]: {
    canObsolete: true,
    description: 'marked latest response as agreed',
    icon: 'flag',
    iconBackgroundColor: Color.LIGHT_GRAY,
  },
};
