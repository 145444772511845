import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { AllRequestsGridColumnId, AllRequestsGridDisplayConfig } from './allRequestsGridTypes';
import { allRequestsGridAvailableColumnIds, viewConfigById } from './allRequestsGridViews';

const filterIds: AllRequestsGridColumnId[] = allRequestsGridAvailableColumnIds;
export const useAllRequestsGridFilters = (
  gridDisplayConfig: AllRequestsGridDisplayConfig,
  requests: any[],
  // TODO figure out types
  columnConfigById: Record<AllRequestsGridColumnId, any | null>,
) => {
  const gridFiltersBase = useMemo(() => {
    const gridFilters = filterIds.flatMap((filterId) => {
      const gridFilterConfig = columnConfigById[filterId];

      if (!gridFilterConfig || !gridFilterConfig.getFilterItems) {
        return [];
      }

      const selectableItems = gridFilterConfig.getFilterItems(requests);

      return {
        columnId: filterId,
        pageId: filterId,

        config: gridFilterConfig,
        items: selectableItems,
      };
    });

    return gridFilters.filter((gridFilter) => !isEmpty(gridFilter.items));
  }, [columnConfigById, requests]);

  return useMemo(() => {
    const selectedViewId = gridDisplayConfig.view.selectedItems[0]!.value;

    const { excludedFilterIds } = viewConfigById[selectedViewId];

    return gridFiltersBase
      .filter(({ columnId }) => !excludedFilterIds.includes(columnId))
      .map((gridFilter) => ({
        ...gridFilter,
        filters: gridDisplayConfig.filters,
      }));
  }, [
    gridDisplayConfig.view.selectedItems,
    gridDisplayConfig.filters,
    gridFiltersBase,
  ]);
};
