import { useMemo } from 'react';
import { BasicTableStyles } from '@deepstream/ui/TableStyles';
import { Table } from '@deepstream/ui/Table';
import { CompanyMinimizedNameAndLogoCell } from '@deepstream/ui/CompanyMinimizedNameAndLogoCell';
import { nestCells } from '@deepstream/ui/nestCells';
import { TruncateCell } from '@deepstream/ui/TruncateCell';
import { ValueOrDashCell } from '@deepstream/ui/ValueOrDashCell';
import { useDeviceSize } from '@deepstream/ui/ui/useDeviceSize';
import * as rfx from '@deepstream/ui/rfx';
import { FilterSelect, filterMultipleValues } from '@deepstream/ui/FilterSelect';
import { withProps } from '@deepstream/ui-utils/withProps';
import { Company, getCombinedBidStatus } from '@deepstream/common/rfq-utils';
import { useTranslation } from 'react-i18next';
import { getCombinedBidStatusLabels } from '@deepstream/common/rfq-utils/statusLabels';

export const RequestRecipientsTable = ({
  onRowClick,
}: {
  onRowClick: (recipient: any) => void;
}) => {
  const { t } = useTranslation();
  const { recipients, bidById, stages } = rfx.useStructure();
  const { isSmall, isExtraSmall, isExtraLarge } = useDeviceSize();

  const {
    combinedBidStatusToLabel,
  } = useMemo(() => ({
    combinedBidStatusToLabel: getCombinedBidStatusLabels(t),
  }), [t]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'company.name',
        Cell: CompanyMinimizedNameAndLogoCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
      },
      {
        id: 'status',
        Header: 'Bid status',
        accessor: ({ _id }: Company) => {
          const bid = bidById[_id];

          if (!bid) {
            return;
          }

          return combinedBidStatusToLabel[getCombinedBidStatus(bid)];
        },
        Cell: nestCells(TruncateCell, ValueOrDashCell),
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        width: isExtraLarge ? 240 : 140,
        sortType: 'caseInsensitive',
      },
      {
        id: 'stage',
        Header: 'Stage',
        accessor: ({ _id }: Company) => {
          const bid = bidById[_id];

          return bid && bid.stageId
            ? stages.findIndex(stage => stage._id === bid.stageId) + 1
            : undefined;
        },
        Cell: ValueOrDashCell,
        Filter: withProps(FilterSelect, { itemToString: value => value }),
        filter: filterMultipleValues,
        sortType: 'caseInsensitive',
        width: isExtraLarge ? 240 : 100,
      },
      {
        id: 'empty',
        Header: undefined,
        accessor: () => null,
        width: 50,
      },
    ],
    [bidById, isExtraLarge, stages, combinedBidStatusToLabel],
  );

  return (
    <BasicTableStyles>
      <Table
        hasStaticHeight={false}
        isPaginated
        columns={columns}
        data={recipients}
        onRowClick={onRowClick}
        isSortable
        noFilteredDataPlaceholder="No recipients match chosen filters"
        hiddenColumns={isSmall || isExtraSmall ? ['status', 'stage'] : []}
      />
    </BasicTableStyles>
  );
};
