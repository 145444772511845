import { Icon, IconProps } from '@deepstream/ui-kit/elements/icon/Icon';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { Box } from 'rebass';

export const renderStatusIconText = (
  item: {
    label: string;
    icon: {
      value: IconProps['icon'];
      color: string;
      isRegular?: boolean;
    }
  } | null,
) => (
  item
    ? (
      <IconText
        icon={item.icon.value}
        iconColor={item.icon.color}
        text={item.label}
        isIconRegular={item.icon.isRegular}
      />
    )
    : ''
);

export const renderLabel = (item: { label: string } | null) =>
  item ? item.label : '';

export const renderLabelWithIcon = (item: { label: string; iconProps: IconProps } | null) => {
  return item ? (
    <Box as="span">
      <Icon fixedWidth mr={1} {...item.iconProps} />
      {item.label}
    </Box>
  ) : (
    ''
  );
};

export const renderName = (item: { name: string } | null) =>
  item ? item.name : '';

export const renderNameOrEmail = (item: { name?: string; email?: string } | null) => {
  if (!item) return '';

  return item.name || item.email || '';
};
