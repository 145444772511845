import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
  useLayoutEffect,
  ReactNode,
} from 'react';
import { Checkbox as CheckboxItem } from 'ariakit/checkbox';
import {
  ComboboxItem,
  ComboboxList, useComboboxState,
} from 'ariakit/combobox';
import {
  useSelectState,
} from 'ariakit/select';
import { isEmpty, keyBy, map, noop, omit } from 'lodash';
import { transparentize } from 'polished';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Checkbox, CheckboxProps } from '@deepstream/ui-kit/elements/input/Checkbox';
import { FilterProps } from '../../filtering';
import { EMPTY_ARRAY } from '../FilterSelectCombobox';
import { SearchInputCombobox } from '../SearchInputCombobox';
import { HorizontalDivider } from '../HorizontalDivider';

const MAX_VISIBLE_RESULTS = 5;

export const StyledComboboxItem = styled(ComboboxItem)`
  padding: 7px 12px;

  outline: none;
  display: flex;
  cursor: pointer;

  scroll-margin-top: 0.5px;

  align-items: center;

  &[data-active-item] {
    background-color: ${(props) =>
    transparentize(0.9, props.theme.colors.primary)};
  }

  &[aria-disabled='true'] {
    cursor: default;
    opacity: 0.5;
  }
`;

export type MultiSelectDropdownProps<T> = {
  items?: T[];
  selected?: T[];
  getId: (item: T) => string;
  Item: ({ item }: { item: T }) => JSX.Element | null;
  filterItems?: (value: string) => T[] | Promise<T[]>;
  filterPlaceholder?: string;
  emptyFilteredItemsMessage?: string;
  onChange?: (selectedItems: T[]) => void;
  renderPreItemContent?: (item: T, index: number) => ReactNode;
  CheckboxComponent?: (props: Pick<CheckboxProps, 'checked' | 'onChange' | 'aria-hidden'>) => React.ReactNode;
};

const MultiSelectDropdown = <T,>({
  items = EMPTY_ARRAY,
  selected,
  filterItems,
  filterPlaceholder,
  emptyFilteredItemsMessage,
  getId,
  Item,
  onChange,
  renderPreItemContent,
  CheckboxComponent = Checkbox,
}: MultiSelectDropdownProps<T>) => {
  const { t } = useTranslation('translation');

  const withSearchBox = Boolean(filterItems);
  if (withSearchBox && (!filterPlaceholder)) {
    throw new Error('`filterPlaceholder` is required when `filterItems` is provided');
  }

  const ids = useMemo(() => items.map(getId), [items, getId]);
  const itemById = useMemo(() => keyBy(items, getId), [items, getId]);

  const [filteredItems, setFilteredItems] = useState<T[]>(items);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const comboboxState = useComboboxState({
    list: ids,
    gutter: 4,
    open: true,
  });
  const { value: searchText } = comboboxState;

  const selectState = useSelectState({
    ...omit(comboboxState, ['value', 'setValue']),
    defaultValue: [],
    // @ts-expect-error ts(2322) FIXME: Type 'string[]' is not assignable to type 'never[]'.
    value: map(selected, getId),
    // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
    setValue: (value: string[]) => onChange(map(value, (id) => itemById[id])),
  });

  const [isSearchLoading, setIsSearchLoading] = useState<boolean>();
  const searchItems = useCallback(async (searchText: string) => {
    if (!searchText) {
      setFilteredItems(items);
    } else {
      setIsSearchLoading(true);
      // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'.
      const newFilteredItems = await filterItems(searchText);
      setFilteredItems(newFilteredItems);
      setIsSearchLoading(false);
    }
  }, [items, filterItems, setFilteredItems]);

  const searchResultsRefs = useRef<React.RefObject<HTMLDivElement>[]>(
    [...Array(MAX_VISIBLE_RESULTS).keys()].map(() => createRef<HTMLDivElement>()),
  );
  const [maxResultsHeight, setMaxResultsHeight] = useState<number>(0);
  useLayoutEffect(() => {
    let newHeight = 0;
    searchResultsRefs.current.forEach((ref) => {
      // Use getBoundingClientRect() instead clientHeight to make sure we get
      // the exact height, including the decimal part
      const clientHeight = ref.current?.getBoundingClientRect().height;
      if (clientHeight && clientHeight > 0) {
        newHeight += clientHeight;
      }
    });
    if (newHeight > 0 && newHeight > maxResultsHeight) {
      // Round up to avoid having a scrollbar
      setMaxResultsHeight(Math.ceil(newHeight));
    }
  }, [
    maxResultsHeight,
  ]);

  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
  const handleScroll = useCallback(
    (event) => {
      setHasVerticalScroll(event.target.scrollTop > 0);
    },
    [setHasVerticalScroll],
  );

  return (
    <>
      {withSearchBox && (
        <Box sx={{ padding: '8px 12px' }}>
          <SearchInputCombobox
            comboboxState={comboboxState}
            // @ts-expect-error ts(2322) FIXME: Type 'string | undefined' is not assignable to type 'string'.
            textPlaceholder={filterPlaceholder}
            // @ts-expect-error ts(2322) FIXME: Type 'boolean | undefined' is not assignable to type 'boolean'.
            isLoading={isSearchLoading}
            onChange={searchItems}
            onSubmit={searchItems}
            showClearButton="always"
          />
        </Box>
      )}
      {/* Always keep space for the scroll horizontal divider to prevent flickering */}
      <Box sx={{ minHeight: '1px' }}>
        {hasVerticalScroll && <HorizontalDivider />}
      </Box>
      {isEmpty(filteredItems) && withSearchBox && !searchText ? (
        null
      ) : (
        <ComboboxList
          onScroll={handleScroll}
          state={comboboxState}
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: `${maxResultsHeight}px`,
            // Keep the same height when the items are filtered
            ...(filteredItems.length < items.length && {
              height: `${maxResultsHeight}px`,
            }),
          }}
        >
          {isEmpty(filteredItems) ? (
            <Text color="subtext" pt="18px" pb="24px" px="10px">
              {emptyFilteredItemsMessage || t('requests.searching.noResults')}
            </Text>
          ) : (
            filteredItems.map((item, i) => {
              const id = getId(item);

              return (
                <Box
                  key={id + i}
                  {...(i < MAX_VISIBLE_RESULTS && {
                    ref: searchResultsRefs.current[i],
                  })}
                >
                  {renderPreItemContent?.(item, i)}
                  <StyledComboboxItem focusOnHover>
                    {(itemProps: any) => (
                      <CheckboxItem
                        {...itemProps}
                        as="div"
                        state={selectState}
                        value={id}
                      >
                        <Flex
                          width="100%"
                          alignItems="center"
                          style={{
                            lineHeight: 'inherit',
                            textAlign: 'left',
                            gap: '8px',
                          }}
                        >
                          <CheckboxComponent
                            // @ts-expect-error ts(2345) FIXME: Argument of type 'string' is not assignable to parameter of type 'never'.
                            checked={selectState.value?.includes(id)}
                            /* Passing an empty onChange function to prevent a console error */
                            onChange={noop}
                            aria-hidden="true"
                          />
                          <Item item={item} />
                        </Flex>
                      </CheckboxItem>
                    )}
                  </StyledComboboxItem>
                </Box>
              );
            })
          )}
        </ComboboxList>
      )}
    </>
  );
};

export type MultiSelectDropdownPageProps<T extends object> = Pick<
  FilterProps<T>,
  'items' | 'selectedItems' | 'onChange' | 'renderItem' | 'idProp'
> & Pick<MultiSelectDropdownProps<T>, 'filterItems' | 'filterPlaceholder' | 'renderPreItemContent' | 'CheckboxComponent'>;

export const MultiSelectDropdownPage = <T extends object = Record<string, unknown>>({
  items,
  selectedItems,
  onChange,
  filterItems,
  filterPlaceholder,
  renderItem,
  idProp,
  renderPreItemContent,
  CheckboxComponent,
}: MultiSelectDropdownPageProps<T>) => {
  return (
    <MultiSelectDropdown<T>
      items={items}
      selected={selectedItems}
      filterItems={filterItems}
      filterPlaceholder={filterPlaceholder}
      Item={(props) => (
        <>
          {/*
           // @ts-expect-error ts(2722) FIXME: Cannot invoke an object which is possibly 'undefined'. */}
          {renderItem(props.item)}
        </>
      )}
      // @ts-expect-error ts(2536) FIXME: Type 'keyof T | undefined' cannot be used to index type 'T'.
      getId={(item: T) => item[idProp] as string}
      onChange={onChange}
      renderPreItemContent={renderPreItemContent}
      CheckboxComponent={CheckboxComponent}
    />
  );
};
