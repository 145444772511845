export enum CompanyReportingTab {
  BUDGET = 'budget',
  VALUE = 'value',
  SAVINGS = 'savings',
  TEAM_PRODUCTIVITY = 'teamProductivity',
  SUPPLIER_ENGAGEMENT = 'supplierEngagement',
  AUCTIONS = 'auctions',
  CONTRACTS = 'contracts',
  ALL_REQUEST_DATA = 'allRequestData',
}

export const tabsWithoutAuctions = [
  CompanyReportingTab.BUDGET,
  CompanyReportingTab.VALUE,
  CompanyReportingTab.SAVINGS,
  CompanyReportingTab.ALL_REQUEST_DATA,
  CompanyReportingTab.TEAM_PRODUCTIVITY,
  CompanyReportingTab.SUPPLIER_ENGAGEMENT,
  CompanyReportingTab.CONTRACTS,
];

export const tabsWithAuctions = [
  CompanyReportingTab.BUDGET,
  CompanyReportingTab.VALUE,
  CompanyReportingTab.SAVINGS,
  CompanyReportingTab.ALL_REQUEST_DATA,
  CompanyReportingTab.TEAM_PRODUCTIVITY,
  CompanyReportingTab.SUPPLIER_ENGAGEMENT,
  CompanyReportingTab.AUCTIONS,
  CompanyReportingTab.CONTRACTS,
];
